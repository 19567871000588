﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.columns__col > .picture {
    margin-bottom: 0;
}

.picture {
    position: relative;
    margin: 0 0 0;
    padding: 0;
    @include element-margin;

    &__image {
        overflow: hidden;
        margin-bottom: 1rem;

        &--no-legend {
            margin-bottom: 0;
        }

        img {
            //we have to nest "img" here, because the experience editor does not add the correct css-class when new images are inserted....
            max-width: 100%;
            height: auto;
            display: block;
            margin: 0 auto;
        }
    }

    &__caption {
        font-size: 1.6rem;
        align-self: center;

        @include for-tablet-landscape-up {
            font-size: 2rem;
        }

        &--small {
            font-size: 1.3rem;

            @include for-tablet-landscape-up {
                font-size: 1.6rem;
            }
        }
    }

    &--caption-bottom-centered {
        .picture__caption {
            text-align: center;
        }
    }

    @include for-desktop-up {
        &--caption-left {
            display: grid;
            grid-gap: 3.2rem;
            //grid-template: "caption image" / 1fr 1fr;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
            grid-template-areas: "caption image";

            .picture__image {
                grid-area: image;
                margin-bottom: 0;
            }

            .picture__caption {
                grid-area: caption;
            }
        }

        &--caption-right {
            display: grid;
            grid-gap: 3.2rem;
            //grid-template: "image caption" / 1fr 1fr;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
            grid-template-areas: "image caption";

            .picture__image {
                grid-area: image;
                margin-bottom: 0;
            }

            .picture__caption {
                grid-area: caption;
            }
        }
    }
}
