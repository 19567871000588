﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.marktnews-teaser {
    height: 100%;
    background-color: $grey-230;
    padding: 1.5rem;
    cursor: pointer;
    background: $grey-230 none /*image is set in cshtml for lazy loading*/ no-repeat center center;
    background-size: cover;

    &:hover &__link {
        color: $migrol-red;
    }

    @include for-tablet-landscape-up {
        padding: 3rem;
    }

    &__visual {
        width: 12rem;
        height: 12rem;

        @include for-tablet-landscape-up {
            width: 16rem;
            height: 16rem;
        }
    }

    &__image {
        &--risingstrongly {
            transform: rotate(-45deg);
            color: $migrol-red;
        }

        &--risingslightly {
            transform: rotate(-10deg);
            color: $migrol-red;
        }

        &--stable {
            color: $grey-127;
        }

        &--decliningslightly {
            transform: rotate(10deg);
            color: $migrol-green;
        }

        &--decliningstrongly {
            transform: rotate(45deg);
            color: $migrol-green;
        }
    }

    &__content {
        padding: 1rem;
        background-color: rgba(255,255,255,0.9);

        @include for-tablet-landscape-up {
            padding: 2rem;
        }
    }

    &__title-1 {
        font-size: 2rem;
        @include font-bold;
        color: $migrol-red;
        margin-bottom: 1.2rem;
    }

    &__title-2 {
        @include font-normal;
    }

    &__lead {
        font-size: 1.6rem;
        line-height: 2.2rem;
        margin-bottom: 1.2rem;

        @include for-tablet-landscape-up {
            font-size: 1.8rem;
            line-height: 2.4rem;
        }
    }

    &__link {
        font-size: 1.6rem;
        @include font-bold;
        text-decoration: none;
        color: #000;

        &:hover {
            color: $migrol-red;
        }
    }

    &__arrow {
        margin-left: .2rem;
        position: relative;
        top: 1px;
    }
}

.contentblock--gray {
    .marktnews-teaser {
        background-color: #fff;
    }
}

.col--small {
    .marktnews-teaser {
        &__visual {
            width: 12rem;
            height: 12rem;
            margin-bottom: 1.5rem;
        }
    }
}

.col--medium {
    .marktnews-teaser {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__visual {
            flex: none;
            width: 12rem;
            height: 12rem;
            margin-bottom: 1.5rem;
        }

        &__content {
            flex: none;
        }
    }
}

.col--large, .col--x-large {
    .marktnews-teaser {
        display: flex;
        align-items: flex-start;

        &__visual {
            flex: none;
            width: 16rem;
            height: 16rem;
            margin-right: 5rem;
        }

        &__content {
            flex: 1;
        }

        &__lead, &__title-1 {
            max-width: 53rem;
        }
    }
}
