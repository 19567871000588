﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";


.searchbox {
    @include element-margin;

    &__form {
        position: relative;
        display: flex;
        align-items: stretch;
        background-color: $grey-241;
    }

    .autocomplete-suggestions {
        left: 0;
        right: 0;
        width: auto !important;
        background-color: $grey-241;
        font-size: 1.8rem;
        overflow-y: auto;
        top: 3.7rem;

        @include for-tablet-landscape-up {
            top: 4.1rem;
        }
    }

    .autocomplete-suggestion {
        cursor: pointer;
        border-top: 1px solid $grey-205;
        padding: 1rem;

        &.autocomplete-selected {
            background-color: $grey-205;
        }
    }

    .autocomplete-title {
        @include font-bold;
        color: $migrol-red;
        margin-bottom: 0.5rem;
    }

    .autocomplete-highlight {
        font-size: 1.6rem;

        strong {
            @include font-bold;
        }
    }

    .autocomplete-intro {
        @include font-bold;
        border-top: 1px solid $grey-183;
        padding: 1rem;
    }

    &__input {
        @include font-normal;
        border: none;
        background-color: transparent;
        outline: none;
        flex: 1;
        font-size: 1.6rem;
        line-height: 2.4rem;
        padding: 1rem;

        @include for-tablet-landscape-up {
            font-size: 2rem;
            line-height: 3rem;
        }

        &::placeholder {
            color: $grey-183;
        }
    }

    &__submit {
        @include button-reset;
        width: 5.5rem;
        text-align: center;
        font-size: 1.6rem;

        @include for-tablet-landscape-up {
            font-size: 2.0rem;
        }
    }
}
