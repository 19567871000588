﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.pneu-offer {
    hyphens: manual;

    a {
        color: #000;
    }
    /*#region Progress Bar*/
    $progessbarCircleSizeLarge: 4rem;
    $progessbarCircleSizeSmall: 4rem;
    $progessbarCircleSizeCurrent: 5rem;

    &__progressbar {
        counter-reset: step;
        display: flex;
        padding-top: 1.8rem;
        padding-bottom: 1.2rem;

        @include for-tablet-landscape-up {
            padding-top: 2.3rem;
            padding-bottom: 1.3rem;
        }
        //each li is a step
        li {
            list-style-type: none;
            flex: 1;
            float: left;
            font-size: 1.6rem;
            position: relative;
            text-align: center;
            text-transform: uppercase;
            color: #000;
            background-color: transparent;
            //step circle
            &:before {
                position: relative;
                width: $progessbarCircleSizeLarge;
                height: $progessbarCircleSizeLarge;
                content: counter(step);
                counter-increment: step;
                line-height: (0.933 * $progessbarCircleSizeLarge);
                border: 1px solid #000;
                display: block;
                text-align: center;
                margin: 0 auto 1rem auto;
                border-radius: 50%;
                background-color: $grey-230;
                z-index: 2;
                @include font-bold;
                font-size: 2rem;

                @include for-tablet-landscape-up {
                    width: $progessbarCircleSizeSmall;
                    height: $progessbarCircleSizeSmall;
                    line-height: (0.933 * $progessbarCircleSizeSmall);
                }
            }
            //step line
            &:after {
                display: none;
                width: 100%;
                height: 2px;
                content: '';
                position: absolute;
                background-color: $grey-153;
                top: ($progessbarCircleSizeLarge / 2);
                left: -50%;
                z-index: 1;

                @include for-tablet-landscape-up {
                    top: ($progessbarCircleSizeSmall / 2);
                }
            }
            //first li does not have a line
            &:first-child:after {
                content: none;
            }

            &.active {
                color: #000;

                &:before {
                    color: #fff;
                    background-color: #000;
                    border-color: #000;
                }

                &:after {
                    display: block;
                }
            }

            &.current {
                color: $migrol-red;

                &:before {
                    color: #fff;
                    background-color: $migrol-red;
                    border-color: $migrol-red;
                    width: $progessbarCircleSizeCurrent;
                    height: $progessbarCircleSizeCurrent;
                    line-height: (0.933 * $progessbarCircleSizeCurrent);
                    margin-top: -0.5rem;
                    font-size: 2.7rem;
                }

                &:after {
                    display: block;
                }

                .shop__progressbar_steptext {
                    margin-top: 0.5rem;
                    @include font-bold;
                }
            }

            &.clickable {
                cursor: pointer;
            }
        }

        &_steptext {
            display: none;

            @include for-tablet-landscape-up {
                display: block;
                margin-top: 1.4rem;
                line-height: 3rem;
            }
        }
    }
    /*#endregion*/

    &__fieldgroup {
        border: none;
        padding-top: 4.5rem;
        padding-bottom: 4.5rem;

        @include for-tablet-landscape-up {
            padding-left: 10%;
            padding-right: 10%;
        }

        @include for-desktop-up {
            padding-left: 16.6667%;
            padding-right: 16.6667%;
        }

        &--with-separator {
            border-bottom: 1px solid $grey-183;
        }

        &--no-padding-bottom {
            padding-bottom: 0;
        }

        &--no-padding-top {
            padding-top: 0;
        }

        &--buttons {
            border-top: 1px solid $grey-183;
        }

        &-title {
            @include font-normal;
            color: $migrol-red;
            font-size: 2.4rem;
            margin-bottom: 2.7rem;

            &--big {
                font-size: 3rem;
            }
        }
    }

    &__nav-buttons {
        display: flex;
        justify-content: space-between;
    }
    /*#region Step 1*/
    &__promobanner {
        img {
            width: 100%;
            height: auto;
            max-width: 30rem;
        }
    }

    &__filter-row {
        margin-bottom: 2rem;

        .migrol-form__input-label{
            line-height: 2.3rem;
        }

        @include for-tablet-portrait-up {
            display: flex;
            flex-wrap: wrap;
        }

        @include for-phone-only {
            .migrol-form__input-wrapper {
                margin-left: 0;
                margin-bottom: 2rem;
            }
        }

        &--3 {
            column-gap: 1rem;

            .pneu-offer__filter-col {
                flex: 2;

                @include for-phone-only {
                    margin-bottom: 2rem;
                }
            }

            .pneu-offer__filter-col:nth-child(2) {
                flex: 3;
            }

            .migrol-form__checkbox {
                margin-top: 0.5rem;
            }
        }
    }

    &__filter-dimension {
        font-size: 2.4rem;
        padding: 0 1rem 0.8rem;
        align-self: end;

        @include for-phone-only {
            display: none;
        }
    }

    &__btn-additional-filters {
        @include button-reset;
        text-decoration: underline;

        @include for-tablet-portrait-up {
            margin-top: 3rem;
            margin-left: 2rem;
        }

        @include for-tablet-portrait-down {
            margin-top: 1rem;
            margin-left: 0;
        }
    }
    /*&__btn-dimension-help {
        @include button-reset;
        display: flex;
        align-items: center;

        @include for-tablet-portrait-up {
            margin-top: 3rem;
            margin-left: 2rem;
        }

        @include for-tablet-portrait-down {
            flex-basis: 100%;
            margin-top: 1rem;
            margin-left: 0;
        }

        svg {
            flex: none;
            margin-right: 0.5em;
        }
    }*/

    &__btn-pick-tyre {
        height: 4rem;
        padding: 0 3rem;
    }
    /*&__dimension-help {
        img {
            margin-bottom: 1em;
        }
    }*/

    &__sku-list {
        padding-bottom: 4rem;
        margin-bottom: 4rem;
        border-bottom: solid 1px $grey-183;

        &-title {
            @include font-bold;
            font-size: 1.8rem;
            padding-top: 4rem;
        }

        &-item {
            position: relative;
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: auto;
            grid-template-areas: "name" "icons" "SW" "price" "picker";
            padding: 4rem 0 0;
            margin-top: 4rem;
            background: linear-gradient(180deg, rgba(240,240,240,1) 0%, rgba(255,255,255,1) 24px, rgba(255,255,255,1) 100%);
            grid-column-gap: 2rem;
            grid-row-gap: 2rem;
            align-items: flex-start;

            @include for-tablet-portrait-up {
                grid-template-columns: 15rem auto 17rem;
                grid-template-areas: "brand  name price" "icons icons picker" "SW SW picker";
            }

            &--aktion {
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 0;
                    height: 0;
                    border-top: 35px solid $migrol-red;
                    border-left: 35px solid transparent;
                }

                &:after {
                    content: '%';
                    position: absolute;
                    top: 1px;
                    right: 2px;
                    font-size: 1.8rem;
                    line-height: 1.9rem;
                    color: #fff;
                }
            }

            &--selected {
                outline-style: solid;
                outline-color: $migrol-green-dark;
                outline-width: 2px;
                outline-offset: 5px;
            }
        }

        &-brand {
            grid-area: brand;
            display: none;

            @include for-tablet-portrait-up {
                display: block;
            }
        }

        &-name {
            grid-area: name;
        }

        &-icons {
            grid-area: icons;

            svg {
                height: 3rem;

                &:first-child::after {
                    content: 'hello';
                    display: block;
                }

                & + svg {
                    margin-left: 3rem;
                }

                @include for-tablet-landscape-up {
                    height: 4rem;
                }
            }
        }

        &-SW {
            grid-area: SW;

            @include for-tablet-landscape-up {
                display: flex;
            }

            div {
                flex: 1;
            }
        }

        &-price {
            grid-area: price;
        }

        &-picker {
            grid-area: picker;
            display: flex;
            column-gap: 1rem;
            align-items: flex-end;

            @include for-tablet-portrait-up {
                flex-direction: column;
                row-gap: 1rem;
                align-items: stretch;
            }
        }

        &-item-brandname {
            @include for-tablet-portrait-up {
                display: none;
            }
        }

        &-item-title {
            @include font-bold;
            font-size: 2.4rem;
        }

        &-item-description {
            font-size: 1.6rem;
        }

        &-item-price {
            @include font-bold;
            font-size: 3rem;
            margin-bottom: 0.5rem;
        }

        &-item-discount-price {
            @include font-bold;
            font-size: 3rem;
            margin-bottom: 0.5rem;
            color: $migrol-red;
        }

        &-item-instead-of-price {
            font-size: 0.8em;
            font-size: 1.2rem;
            margin-bottom: 0.5rem;
        }

        &-item-mwst {
            font-size: 1.2rem;
        }
    }
    /*#endregion*/
    /*#region Step 2*/
    &__services-rimtype {
        margin-bottom: 3rem;
        max-width: 20rem;
    }

    &__services-list {
        text-align: left;

        th {
            @include font-bold;
        }

        th + th, td + td {
            padding-left: 4rem;
        }

        th, td {
            padding-bottom: 2rem;

            &:nth-child(2), &:nth-child(3) {
                display: none;

                @include for-tablet-portrait-up {
                    display: table-cell;
                }
            }
        }

        strong {
            @include font-bold;
        }
    }

    &__service-price-col {
        text-align: right;
    }

    &__service-amount-col {
        text-align: center;
    }
    /*#endregion*/
    /*#region Step 3*/
    &__address-fields {
        max-width: 45rem;
    }
    /*#endregion*/
    /*#region Step 4*/
    &__summary-intro {
        margin-bottom: 4rem;

        p {
            margin-bottom: 1em;
        }
    }

    &__summary {
        margin: 3rem 0;
        display: grid;
        grid-column-gap: 4rem;
        grid-row-gap: 6rem;
        grid-template-columns: 1fr;
        grid-template-rows: auto;

        @include for-tablet-portrait-up {
            grid-template-columns: 1fr 1fr;
        }
    }

    &__summary-block {
    }

    &__summary-table {
        width: 100%;

        th {
            text-align: left;
        }

        td {
            text-align: left;
        }
    }

    &__summary-bg {
        margin-top: 1rem;
        padding: 3rem 0 0;
        line-height: 1.5;
        background: linear-gradient(180deg, rgba(240,240,240,1) 0%, rgba(255,255,255,1) 24px, rgba(255,255,255,1) 100%);
    }

    &__promos {
        margin-bottom: 4rem;
    }

    &__promo {
        & + & {
            margin-top: 2rem;
        }
    }
    /*#endregion*/
}
