﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.blog-comments {
    &__title {
        color: $migrol-red;
        @include font-bold;
        font-size: 2.0rem;
        margin-bottom: 0.5em;

        @include for-tablet-landscape-up {
            font-size: 2.4rem;
        }
    }

    &__no-comments {
    }

    &__comment-container {
        margin-bottom: 5rem;
        font-size: 1.6rem;
    }

    &__comment {
        border-bottom: 1px solid $grey-183;
        margin-bottom: 2rem;
        
        &--level-1 {
            padding: 2rem 0;
        }

        &--level-2 {
            padding: 2rem 0 2rem 10%;
        }

        & .blog-comments__cancel-button {
            //cancel button is shown when replying to a comment
            display: inline-block;
        }

        &:target {
            animation-name: targetedCommentAni;
            animation-duration: 0.75s;
            animation-iteration-count: 5;
        }

        @keyframes targetedCommentAni {
            0% {
                color: #000;
            }

            50% {
                color: $migrol-red;
            }

            100% {
                color: #000;
            }
        }

        a, a:hover, a:visited {
            color: #000;
            text-decoration: underline;
        }
    }

    &__cancel-button {
        //cancel button is hidden by default
        display: none;
    }

    &__title-row {
        display: flex;
        margin-bottom: 1.25rem;
    }

    &__by-migrol {
        height: 10px;
        display: inline-block;
        margin-right: 0.5rem;
    }

    &__author {
        flex: 1;
        @include font-bold;
    }

    &__date {
        flex: none;
    }

    &__text {
        margin-bottom: 1.25rem;
        line-height: 1.3;
    }

    &__reply {
        @include button-reset;
        @include font-bold;
    }

    &__form-row {
        margin-bottom: 2rem;
    }

    &__recaptcha-info {
        font-size: 1rem;
        color: $grey-127;

        a {
            color: $grey-127;
        }
    }

    &__success-message {
        margin: 2rem 0;
        font-size: 1.6rem;
        padding: 2rem;
        background-color: #fff;
        border: 0.2rem solid $migrol-green;
        color: $migrol-green;
    }

    &__error-message {
        margin: 2rem 0;
        font-size: 1.6rem;
        padding: 2rem;
        background-color: #fff;
        border: 0.2rem solid $migrol-red;
        color: $migrol-red;
    }

    &__closed-message {
        margin: 2rem 0;
        font-size: 1.6rem;
        padding: 2rem;
        background-color: #fff;
        border: 0.2rem solid $grey-127;
        color: $grey-127;
    }
}
