﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

//---------------------------------------------------------------------------------------------------
// This file is included in the Sitecore RTE
//---------------------------------------------------------------------------------------------------

.migrol-rt-content {
    @include element-margin;
    @include font-normal;
    font-size: 1.6rem;
    line-height: 2.2rem;
    position: relative;

    &--emphasis {
        background-color: #fff;
        padding: $content-padding-mobile;

        @include for-tablet-landscape-up {
            padding: $content-padding-desktop;
        }
    }

    @include for-tablet-landscape-up {
        &:not(&--small) {
            font-size: 2rem;
            line-height: 3rem;
        }
    }

    &--small {
        font-size: 1.6rem;
        line-height: 2.2rem;
    }

    @import "_migrol-rt-content-elements";
    @import "_migrol-rt-content-tables";
}

//override background-color for emphasis content when inside a gray contentblock (then the BG must be white)
.contentblock--gray .migrol-rt-content--emphasis {
    background-color: #fff;
}

//make tables scroll horizontally (when not in RTE)
.migrol-body {
    .neutralTable, .redTable, .grayTable {
        display: block;
        overflow-x: auto;

        tbody {
            display: table;
            width: 100%;
        }
    }
}

.columns__col .migrol-rt-content {
    margin-bottom: 0; //in columns, we don't want margin at the bottom
}
