﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.buy-energy {
    background-color: $grey-230;

    &__title {
        padding: 2.8rem 2.5rem 1.5rem 2.5rem;
        @include font-bold;
        font-size: 2.8rem;
        color: $migrol-red;

        @include for-tablet-landscape-up {
            font-size: 3.0rem;
        }
    }

    &__tabs {
        border-collapse: collapse;
        display: flex;
    }

    &__tab {
        @include button-reset;
        border-top: solid 1px $grey-183;
        border-right: solid 1px $grey-183;
        border-bottom: solid 1px $grey-183;
        background-color: $grey-205;
        text-decoration: none;
        @include font-bold;
        font-size: 1.6rem;
        color: #000;
        padding: 0 2rem;
        line-height: 3.3rem;
        text-align: center;
        flex: 1;

        &:first-child {
            border-left: none;
        }

        &:last-of-type {
            border-right: none;
        }
    }

    &__tab--active {
        background-color: transparent;
        border-bottom-color: transparent;
        color: $migrol-red;
    }

    &__tab-content {
        display: none;
        padding: 2.6rem 3.6rem 3.0rem 3.6rem;
    }

    &__icon {
        display: none;
    }

    &__tab-content-title {
        font-size: 2rem;
        line-height: 2.9rem;

        @include for-tablet-landscape-up {
            font-size: 2.4rem;
        }
    }

    &__tab-content-copy {
        font-size: 1.6rem;
        line-height: 2.2rem;
        margin: 0.6rem 0 2.1rem;
        overflow: hidden; //prevents the text from wrapping around the floated icon
    }

    &__tab-content--active {
        display: block;
    }

    &__intro {
        @include clearfix;
    }

    &__inputs {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: 1fr;

        @include for-desktop-up {
            grid-template-columns: 1fr 165px;
        }
    }

    &__zipcity {
        background-color: #fff;
        font-size: 1.4rem;
    }

    &__amount {
        font-size: 1.4rem;
    }

    &__submit {
        @include button-reset;
        @include font-bold;
        text-align: center;
        font-size: 1.6rem;
        border: none;
        color: #fff;
        background-color: $migrol-green;
        display: block;
        width: 100%;
        height: 4rem;
        padding: 0.5rem 1rem;
        cursor: pointer;
        margin-top: 1.5rem;

        &:hover {
            background-color: $migrol-green-dark;
        }
    }
}

.contentblock--gray .buy-energy {
    background-color: #fff;
}

.columns__col .buy-energy {
    min-height: 100%; //make 100% high, when in column
}

.col--small .buy-energy {
}

.col--medium .buy-energy {
}

.col--large, .col--x-large {
    .buy-energy {
        &__tab {
            flex: none;
        }

        &__tab-filler {
            flex: 1;
            border-bottom: solid 1px $grey-183;
            border-left: solid 1px $grey-183;
        }

        &__tab-content {
            max-width: 55rem;
        }

        &__icon {
            display: block;
            float: left;
            margin-right: 2rem;
            border-radius: 50%;
            border: solid 1px #000;
        }
    }
}

//special styles, when buy-energy is used inside a .hero
@include for-tablet-landscape-up {
    .hero {
        .buy-energy {
            background-color: rgba(255, 255, 255, 0.9);
            width: 50rem;

            &__title {
                font-size: 3.8rem;
            }

            &__tab {
                display: inline-block;
                background-color: $grey-230;
                padding: 0 3rem;
                border-left: none;
                flex: none;

                &:last-of-type {
                    border-right: solid 1px $grey-183;
                }
            }

            &__tab-filler {
                flex: 1;
                border-bottom: solid 1px $grey-183;
            }

            &__tab--active {
                background-color: transparent;
                border-bottom-color: transparent;
            }

            &__icon {
                display: block;
                float: left;
                margin-right: 2rem;
                border-radius: 50%;
                border: solid 1px #000;
            }

            &__inputs {
                @include for-tablet-landscape-up {
                    grid-template-columns: 1fr 165px;
                }
            }
        }
    }
}
