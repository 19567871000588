﻿@charset 'utf-8';

/////////////////////////////////////////////
// VARIABLES
/////////////////////////////////////////////

//breakpoints
//see https://medium.freecodecamp.org/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
$tablet-portrait-min-width: 600px;
$tablet-landscape-min-width: 900px;
$desktop-min-width: 1260px;
//$big-desktop-min-width: 1800px;

//global content setup
$content-padding-mobile: 2.6rem;
$content-padding-desktop: 3rem;

//global element setup
$element-margin-bottom-mobile: 3.75rem;
$element-margin-bottom-desktop: 3.75rem;

//colors
$migrol-red: rgb(164,9,30);
$migrol-red-dark: rgb(148,6,25);

$migrol-green: rgb(78,108,48);
$migrol-green-dark: rgb(68,94,42);

$migrol-yellow: rgb(247,216,0);

$grey-127: rgb(127,127,127);
$grey-153: rgb(153,153,153);
$grey-183: rgb(183,183,183);
$grey-205: rgb(205,205,205);
$grey-230: rgb(230,230,230);
$grey-241: rgb(241,241,241);

/////////////////////////////////////////////
// MIXINS
/////////////////////////////////////////////
//Breakpoints
@mixin for-phone-only {
    @media (max-width: ($tablet-portrait-min-width - 1px)) {
        @content;
    }
}

@mixin for-tablet-portrait-down {
    @media (max-width: ($tablet-landscape-min-width - 1px)) {
        @content;
    }
}

@mixin for-tablet-portrait-up {
    @media (min-width: $tablet-portrait-min-width) {
        @content;
    }
}

@mixin for-tablet-landscape-up {
    @media (min-width: $tablet-landscape-min-width) {
        @content;
    }
}

@mixin for-desktop-up {
    @media (min-width: $desktop-min-width) {
        @content;
    }
}

@mixin font-normal {
    font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
}

@mixin font-bold {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
}

//Style resets
@mixin button-reset {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    text-align: inherit;
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;
    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;
    /* Remove excess padding and border in Firefox 4+ */
    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }
}

@mixin form-field-basics {
    border: 1px solid $grey-183;
    padding: 0 1rem;
    color: #000;

    &::placeholder {
        color: $grey-183;
    }

    &:hover {
        border-color: #000;
    }

    &.error {
        border-color: $migrol-red;
    }
}

//content margins
@mixin element-margin {
    margin-bottom: $element-margin-bottom-mobile;

    @include for-tablet-landscape-up {
        margin-bottom: $element-margin-bottom-desktop;
    }
}

//utilities
@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}