﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.footer-external-links {
    padding: 6rem 0;
    border-top: solid 1px $grey-127;

    @include for-tablet-landscape-up {
        display: flex;
        justify-content: space-between;
        border-top: none;
    }

    &__col {
        @include for-tablet-landscape-up {
            flex: none;
            max-width: 25%;
        }
    }

    &__title {
        @include font-bold;
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    &__content + &__title {
        margin-top: 3rem;
    }

    &__content {
        font-size: 1.4rem;
        margin-bottom: 3rem;
        max-width: 60rem;
        @include clearfix;

        @include for-tablet-landscape-up {
            margin-bottom: 0;
            max-width: none;
        }
    }

    &__link {
        text-decoration: none;
        color: #000;
    }
}
