﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

$columns-grid-gap: 1.2rem;

@mixin createCols( $numCols ) {
    grid-template-columns: repeat($numCols, calc((100% - #{($numCols - 1) * $columns-grid-gap}) / #{$numCols}));
}

.columns {
    @include element-margin;

    @include for-tablet-landscape-up {
        margin-left: 0;
        margin-right: 0;
    }


    & + .columns {
        //columns following other columns have margin top
        margin-top: (-1 * $element-margin-bottom-mobile + 1.2rem);
    }

    &--one-col {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto;
        grid-gap: $columns-grid-gap;
    }

    &--two-col-1-1 {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
        grid-gap: $columns-grid-gap;

        @include for-tablet-landscape-up {
            @include createCols(2);
            grid-template-rows: auto;
        }
    }

    &--two-col-2-1 {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
        grid-gap: $columns-grid-gap;

        @include for-tablet-landscape-up {
            display: grid;
            grid-gap: $columns-grid-gap;
            grid-template-areas: "left left right";
            grid-template-rows: auto;
            @include createCols(3);

            .columns__col:first-child {
                grid-area: left;
            }

            .columns__col:last-child {
                grid-area: right;
            }
        }
    }

    &--two-col-1-2 {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
        grid-gap: $columns-grid-gap;

        @include for-tablet-landscape-up {
            display: grid;
            grid-gap: $columns-grid-gap;
            grid-template-areas: "left right right";
            grid-template-rows: auto;
            @include createCols(3);

            .columns__col:first-child {
                grid-area: left;
            }

            .columns__col:last-child {
                grid-area: right;
            }
        }
    }

    &--two-col-3-1 {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
        grid-gap: $columns-grid-gap;

        @include for-tablet-landscape-up {
            display: grid;
            grid-gap: $columns-grid-gap;
            grid-template-areas: "left left left right";
            grid-template-rows: auto;
            @include createCols(4);

            .columns__col:first-child {
                grid-area: left;
            }

            .columns__col:last-child {
                grid-area: right;
            }
        }
    }

    &--two-col-1-3 {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
        grid-gap: $columns-grid-gap;

        @include for-tablet-landscape-up {
            display: grid;
            grid-gap: $columns-grid-gap;
            grid-template-areas: "left right right right";
            grid-template-rows: auto;
            @include createCols(4);

            .columns__col:first-child {
                grid-area: left;
            }

            .columns__col:last-child {
                grid-area: right;
            }
        }
    }

    &--three-col {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto;
        grid-gap: $columns-grid-gap;

        @include for-tablet-landscape-up {
            @include createCols(3);
            grid-template-rows: auto;
        }
    }

    &--four-col {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto auto;
        grid-gap: $columns-grid-gap;

        @include for-tablet-landscape-up {
            @include createCols(2);
            grid-template-rows: auto auto;
        }

        @include for-desktop-up {
            @include createCols(4);
            grid-template-rows: auto;
        }
    }
}