﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

$accordion-transition-duration: 0.5s;

.accordion {
    list-style: none;
    margin: 0;
    padding: 0;
    @include element-margin;

    &__item {
        padding: 0;
        margin: 0;
        border-top: solid 1px $grey-127;
        position: relative;
    }

    &__label {
        color: $migrol-red;
        @include font-bold;
        font-size: 1.6rem;
        cursor: pointer;
        display: block;
        padding: 1rem 4rem 1rem 2rem;
        user-select: none;
        position: relative;

        @include for-tablet-landscape-up {
            font-size: 2rem;
        }

        .svg-inline--fa {
            position: absolute;
            right: 2rem;
            top: 50%;
            margin-top: -7px;
            font-size: 1.4rem;
            transform: rotate(0deg);
            float: right;
            transition: transform, opacity $accordion-transition-duration;
            opacity: 1;
        }
    }

    &__input {
        visibility: hidden;
        position: absolute;
        top: -10rem;
        left: 0;
        outline: none;
        pointer-events: none;
    }

    &__content-container {
        max-height: 0;
        transition: max-height $accordion-transition-duration cubic-bezier(0.075, 1.060, 0.180, 0.965), background-color $accordion-transition-duration linear;
        overflow: hidden;
    }

    &__content {
        position: relative;
        padding: 2rem;

        .migrol-rt-content {
            margin-bottom: 0;
        }
    }

    &__image {
        display: block;
        max-width: 100%;
        height: auto;
    }

    &__columns {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
        grid-gap: 3rem;

        @include for-tablet-landscape-up {
            display: grid;
            grid-gap: 3rem;
            grid-template-areas: "left right right";
            grid-template-rows: auto;
            grid-template-columns: repeat(3, calc((100% - 6rem) / 3));

            & > :first-child {
                grid-area: left;
            }

            & > :last-child {
                grid-area: right;
            }
        }
    }
    //open state (a bit hacky...)
    &__input:checked + &__label {
        background-color: $grey-230;
        transition: background-color $accordion-transition-duration;

        .svg-inline--fa {
            transform: rotate(180deg);
            transition: transform, opacity $accordion-transition-duration;
        }
    }
    //in single-mode (radiobuttons), when the item is open, hide the arrow instead of rotating it and do not use the pointer cursor
    &__input.radio:checked + &__label {
        cursor: auto;

        .svg-inline--fa {
            transform: rotate(0deg);
            opacity: 0;
        }
    }

    &__input:checked ~ &__content-container {
        max-height: 2000px;
        background-color: $grey-230;
        transition: max-height $accordion-transition-duration cubic-bezier(0.885, 0.000, 1.000, 0.065), background-color $accordion-transition-duration linear;
    }
}
