﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.forms-feature {
    hyphens: manual;

    a {
        color: #000;
    }

    &__form-wrapper {

        @include for-tablet-landscape-up {
            padding-left: 10%;
            padding-right: 10%;
        }

        @include for-desktop-up {
            padding-left: 16.6667%;
            padding-right: 16.6667%;
        }
    }

    &__field-block {
        padding-top: 5rem;
        padding-bottom: 5rem;
        border-bottom: 1px solid #000;

        @include for-tablet-landscape-up {
            padding-left: 8%;
            padding-right: 8%;
        }

        @include for-desktop-up {
            padding-left: 12.5%;
            padding-right: 12.5%;
        }

        &:first-child {
            padding-top: 0;
        }

        &-title {
            @include font-normal;
            color: $migrol-red;
            font-size: 2.4rem;
            margin-bottom: 2.7rem;
        }

        &-description {
            margin-bottom: 2.7rem;
        }

        &--no-separator {
            border-bottom: none;
        }
    }

    &__field-row {
        display: flex;

        & + & {
            margin-top: 2rem;
        }
    }

    &__contact-areas {
        display: flex;
        flex-direction: column;

        @include for-tablet-portrait-up {
            flex-direction: row;
        }
    }

    &__contact-area {
        flex: 1;
        cursor: pointer;
        text-align: center;
        background-color: $grey-230;
        padding: 2rem;
        border: 1px solid $grey-153;
        display: flex;
        justify-content: center;
        align-items: center;

        @include font-bold;
        font-size: 1.8rem;
        line-height: 2rem;
        color: #000;

        &--selected, &:hover {
            background-color: $grey-205;
        }

        & + & {
            border-top: 0;

            @include for-tablet-portrait-up {
                border-top: 1px solid $grey-153;
                border-left: 0;
            }
        }

        &-desc {
            margin-top: 1rem;
            text-align: center;
        }
    }
    /*#region Errors*/
    &__error-message-block {
        padding: 2rem;
        border: 2px solid $migrol-red;
        color: $migrol-red;
        background-color: #fff;
        margin-bottom: 3rem;

        @include for-tablet-landscape-up {
            width: 50%;
            margin-left: auto;
            margin-right: auto;
        }

        li {
            list-style: none;
        }
    }
    /*#endregion*/

    &__myclimate-checkbox {
        display: flex;
        align-items: center;

        &-description {
            margin-left: 2rem;
        }
    }

    &__checkbox-desc {
        display: block;
    }
}

/*special paddings, when the form is inside a .columns layout*/
.columns .forms-feature__field-block {
    padding-left: 0;
    padding-right: 0;
}
