﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.wl-teaser {
    position: relative;
    background-color: #666;
    background-size: cover;
    background-position: center;
    padding: $content-padding-mobile;
    height: 100%;

    &__title-1 {
        font-size: 2.2rem;
        @include font-bold;
        color: #FFF;
    }

    &__title-2 {
        display: block;
        @include font-normal;
    }

    &__lead {
        margin-top: 1.5rem;
        color: #fff;
        font-size: 1.6rem;
        line-height: 2.4rem;
    }

    &__items {
        position: relative;
        margin: 3rem 0;
    }

    &__item {
        text-align: center;
        width: 20%;
        display: inline-block;
        visibility: hidden;
    }

    &__icon {
        position: relative;
        border: 2px solid #fff;
        border-radius: 50%;
        width: 50%;
        height: 0;
        padding-top: 50%;
        margin: 0 auto;
        color: #fff;
    }

    &__image {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -45%;
        margin-left: -45%;
        max-width: 90%;
        height: auto;
    }

    &__step-text {
        color: #fff;
        font-size: 1.6rem;
        margin-top: 2rem;
    }

    &__call-to-action {
        text-align: center;
    }

    &__call-to-action-link {
        @include font-bold;
        font-size: 1.4rem;
        border: 1px solid #fff;
        display: inline-block;

        &:hover {
            background-color: rgba(255,255,255,0.2);
        }

        a, .scTextWrapper {
            display: inline-block;
            padding: 1rem 2rem;
            text-decoration: none;
            color: #fff;
        }
    }

    &__button {
        position: absolute;
        color: #fff;
        font-weight: bold;
        text-align: center;
        font-size: 24px;
        background: none;
        border: none;
        z-index: 2;
        outline: none;
        height: 70%;

        &--previous {
            left: 0;
            top: 0;
        }

        &--next {
            right: 0;
            top: 0;
        }

        &.slick-disabled {
            display: none !important;
        }
    }

    &__pager {
        list-style: none;
        display: block;
        text-align: center;
        padding: 0;
        margin: 2rem 0 0 0;

        li {
            position: relative;
            display: inline-block;
            height: 30px;
            width: 20px;
            margin: 0 5px;
            padding: 0;
            cursor: pointer;

            button {
                border: 0;
                background: #ccc;
                display: block;
                height: 10px;
                width: 10px;
                border-radius: 50%;
                outline: none;
                line-height: 0px;
                font-size: 0px;
                color: transparent;
                padding: 5px;
                cursor: pointer;

                @media(hover:hover) {
                    &:hover, &:focus {
                        outline: none;
                        background-color: #999 !important;
                    }
                }
            }

            &.slick-active button {
                background-color: #999 !important;
            }
        }
    }
}

.slick-initialized .wl-teaser__item {
    visibility: visible;
}

//special style for when not in a grid
.contentblock__content > .wl-teaser {
    @include element-margin;
    margin-left: -$content-padding-mobile;
    margin-right: -$content-padding-mobile;

    @include for-tablet-landscape-up {
        margin-left: 0;
        margin-right: 0;
    }
}
//styles for x-large
.contentblock__content > .wl-teaser, .col--x-large > .wl-teaser {
    @include for-tablet-landscape-up {
        padding: 6rem 10rem;

        .wl-teaser__title-1 {
            font-size: 3.8rem;
            line-height: normal;
        }

        .wl-teaser__lead {
            font-size: 2.4rem;
            line-height: normal;
            max-width: 60rem;
        }

        .wl-teaser__items {
            margin: 6rem 0;
        }

        .wl-teaser__icon {
            width: 66%;
            padding-top: 66%;
        }

        .wl-teaser__call-to-action {
            margin-top: 6rem;
        }
    }
}
//styles for large
.col--large .wl-teaser {
    //padding: 6rem 10rem;
    .wl-teaser__title-1 {
        font-size: 2.8rem;
        line-height: normal;
    }

    .wl-teaser__lead {
        font-size: 2.0rem;
        line-height: normal;
        max-width: 60rem;
    }

    .wl-teaser__items {
        margin: 6rem 0;
    }

    .wl-teaser__icon {
        width: 66%;
        padding-top: 66%;
    }
}
//styles for medium
.col--medium .wl-teaser {
}
//styles for small
.col--small .wl-teaser {
}
