﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

$meta-nav-height: 3.6rem;

.meta-nav {
    display: none;
}

@include for-tablet-landscape-up {
    .meta-nav {
        background-color: #000;
        display: block;


        &__content {
            margin: 0 auto;
            padding: 0 $content-padding-desktop;

            @include for-desktop-up {
                max-width: $desktop-min-width;
            }

            height: $meta-nav-height;
            text-align: right;
        }

        &__item {
            margin-left: 3rem;
            position: relative;
            height: $meta-nav-height;
            display: inline-block;

            &--migrolcard {
                padding-top: 0.7rem;

                img {
                    display: block;
                }
            }

            &--mlogin {
                overflow: hidden;
                font-size: 1.2rem;
                line-height: 3.6rem;
            }

            &:first-child {
                margin-left: 0;
            }
        }

        &__item:hover &__subitems {
            display: block;
        }

        &__link {
            display: table-cell;
            vertical-align: middle;
            color: #fff;
            text-decoration: none;
            font-size: 1.2rem;
            cursor: pointer;
            height: $meta-nav-height;
        }

        &__subitems {
            position: absolute;
            top: 3.6rem;
            left: -50%;
            z-index: 1002;
            background-color: #fff;
            display: none;
        }

        &__subitems-inner {
            padding: 0.5rem 1.5rem 0.1rem;
            background-color: #000;
            margin-top: 5px;
            position: relative;
            white-space: nowrap;

            &::before {
                content: '';
                position: absolute;
                top: -5px;
                left: 50%;
                margin-left: -5px;
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 5px solid black;
            }
        }

        &__link-migros-username {
            color: #fff;
            text-decoration: none;
        }
    }
}
