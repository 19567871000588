﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";


@include for-tablet-portrait-down {
    .search-nav {
        &__close-desktop {
            display: none;
        }

        &__toggle {
            @include button-reset;
            width: 5rem;
            height: 5rem;
            text-align: center;
            font-size: 2.2rem;
            display: table-cell;
            color: #000;
            text-decoration: none;
            vertical-align: middle;
            margin-right: 1.1rem;

            .hamburger-box {
                display: none;
            }

            .fa-search {
                display: inline-block;
            }

            &--active {
                .hamburger-box {
                    display: inline-block;
                    font-size: 1rem;
                }

                .fa-search {
                    display: none;
                }
            }
        }

        &__form {
            position: absolute;
            top: 4.6rem;
            left: 0;
            right: 0;
            align-items: stretch;
            background-color: #fff;
            display: none;
            border-bottom: 2px solid $grey-241;

            &--active {
                display: flex;
            }

            .autocomplete-suggestions {
                display: none !important;
            }
        }

        &__input {
            @include font-normal;
            font-size: 1.9rem;
            border: solid 1px $grey-183;
            background-color: transparent;
            outline: none;
            flex: 1;
            margin: 1.2rem 1.1rem;
            padding: 1rem;

            &::placeholder {
                color: $grey-183;
            }
        }

        &__triangle {
            position: absolute;
            top: -2px;
            right: 2.2rem;
        }

        &__submit {
            @include button-reset;
            position: absolute;
            font-size: 1.9rem;
            right: 2.4rem;
            top: 2.2rem;
            color: $grey-183;
        }
    }
}

@include for-tablet-landscape-up {
    .search-nav {
        margin-left: 3.5rem;

        &__toggle {
            @include button-reset;
            padding-top: 0.9rem;
            width: 5rem;
            height: 5rem;
            text-align: center;
            font-size: 2.2rem;
            display: table-cell;
            color: #000;
            text-decoration: none;
            vertical-align: middle;

            .hamburger-box {
                display: none;
            }
        }

        &__form {
            position: absolute;
            top: 1.5rem;
            bottom: 1.5rem;
            left: 30rem;
            right: $content-padding-desktop;
            align-items: stretch;
            background-color: $grey-241;
            display: none;

            &--active {
                display: flex;
            }

            .autocomplete-suggestions {
                top: 5.5rem;
                left: 0;
                right: 0;
                width: auto !important;
                background-color: $grey-241;
                font-size: 1.8rem;
                overflow-y: auto;
            }

            .autocomplete-suggestion {
                cursor: pointer;
                border-top: 1px solid $grey-205;
                padding: 1rem 5.5rem;

                &.autocomplete-selected {
                    background-color: $grey-205;
                }
            }

            .autocomplete-title {
                @include font-bold;
                color: $migrol-red;
                margin-bottom: 0.5rem;
            }

            .autocomplete-highlight {
                font-size: 1.6rem;

                strong {
                    @include font-bold;
                }
            }

            .autocomplete-intro {
                @include font-bold;
                border-top: 1px solid $grey-183;
                padding: 0.5rem 5.5rem;
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
        }

        &__input {
            @include font-normal;
            font-size: 2.6rem;
            border: none;
            background-color: transparent;
            outline: none;
            flex: 1;

            &::placeholder {
                color: $grey-183;
            }
        }

        &__triangle {
            display: none;
        }

        &__submit, &__close-desktop {
            @include button-reset;
            width: 5.5rem;
            font-size: 2rem;
            text-align: center;
        }

        &--visible {
            .main-nav {
                display: none;
            }
        }
    }
}
