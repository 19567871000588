﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.contact-person {
    @include element-margin;
    text-align: center;

    @include for-tablet-landscape-up {
        text-align: left;
    }

    &__layout {

        @include for-tablet-landscape-up {
            display: flex;
        }
    }

    &__image {
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 50%;
        height: 0;
        padding-top: 50%;
        margin: 0 auto;

        @include for-tablet-portrait-up {
            width: 16.7rem;
            height: 16.7rem;
            padding-top: 0;
        }

        @include for-tablet-landscape-up {
            flex: none;
            width: 16.7rem;
            height: 16.7rem;
            padding-top: 0;
            margin: 0 2rem 0 0;
        }
    }

    &__details {
        flex: none;

        @include for-tablet-landscape-up {
            flex: 1;
        }
    }

    &__name {
        @include font-bold;
        font-size: 2rem;
        margin-top: 2.4rem;

        @include for-tablet-landscape-up {
            font-size: 2.4rem;
        }
    }

    &__address {
        font-size: 1.6rem;
        margin-top: 0.5rem;
        line-height: 1.38;

        @include for-tablet-landscape-up {
            font-size: 1.8rem;
        }

        a {
            text-decoration: none;
            color: #000;
        }
    }

    &__call-to-action {
        display: block;
        background-color: $migrol-green;
        color: #fff;
        text-align: center;
        margin-top: 3rem;
        @include font-bold;
        font-size: 1.6rem;
        
        @include for-tablet-portrait-up {
            display: inline-block;
        }

        &:hover {
            background-color: $migrol-green-dark;
        }

        a, .scTextWrapper {
            display: block;
            color: #fff;
            text-decoration: none;
            padding: 1.1rem 6rem;

            /*@include for-tablet-portrait-up {
                padding: 0.5rem 8rem;
            }*/
        }
    }
}
