﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

$main-nav-width: 80vw;
$main-nav-top-offset: 6rem;
$main-nav-transition-duration: 0.5s;
$main-nav-bg: $grey-241;
$main-nav-item-height: 5rem;
$main-nav-item-padding: 0 4rem 0 2rem;
$main-nav-back-button-width: 4rem;

$main-nav-line-color: $grey-205;
$main-nav-back-button-bg: #d8d8d8;

@include for-tablet-portrait-down {
    .main-overlay--force {
        display: block !important;
    }

    .main-nav {
        display: none;
        position: fixed;
        top: $main-nav-top-offset;
        left: 0;
        bottom: 0;
        right: 0;
        overflow-y: auto;
        transform: translateX(-100vw);
        transition: transform $main-nav-transition-duration;
        font-size: 1.4rem;
        @include font-bold;
    }

    .main-nav--active {
        transform: translateX(0);
        transition: transform $main-nav-transition-duration;
    }
    //breadcrumbs container for mobile (hidden on desktop)
    .main-nav__breadcrumbs {
        position: fixed;
        overflow-x: auto;
        left: (-1 * $main-nav-width);
        width: $main-nav-width;
        height: $main-nav-item-height;
        transition: left $main-nav-transition-duration;
        z-index: 1001;
        background-color: #fff;
        border-bottom: solid 1px $main-nav-line-color;
        padding: 0 2rem;
        white-space: nowrap;
        font-size: 1.2rem;
        @include font-normal;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .svg-inline--fa {
            color: $migrol-red;
            font-size: 0.9em;
        }
    }

    .main-nav__breadcrumbs--active {
        left: 0;
    }

    .main-nav__breadcrumbs-item {
        @include button-reset;
        color: #000;
    }

    .main-nav__breadcrumbs-item--current {
        @include font-bold;
        margin-right: 2rem;
    }
    //groups a set of subpage-links
    .main-nav__ul {
        margin: 0;
        padding: $main-nav-item-height 0 0 $main-nav-back-button-width;
        position: absolute;
        left: 150vw;
        top: 0;
        min-height: calc(100vh - #{$main-nav-top-offset});
        width: $main-nav-width;
        background-color: $main-nav-bg;
        transition: left $main-nav-transition-duration;
        z-index: 1001;
        overflow: hidden;
    }
    //level 1 is a bit different on mobile
    .main-nav__ul--level-1 {
        padding: 0 0 50px 0;
        top: 0;
        left: 0;
        width: $main-nav-width;
        z-index: 1000;
        overflow-x: hidden;
        min-height: 100%;

        & > .main-nav__li--title {
            margin-left: 0;
        }
    }

    .main-nav__ul--active {
        left: 0;
    }
    //container for a nav-item
    .main-nav__li {
        list-style-type: none;
        background-color: #FFF;
        border-bottom: solid 2px $main-nav-bg;
        height: $main-nav-item-height;
    }

    .main-nav__li--title {
        margin-left: (-1 * $main-nav-back-button-width);
    }

    .main-nav__li--with-padding {
        padding: $main-nav-item-padding;
        display: flex;
        width: $main-nav-width;
        align-items: center;
    }
    //meta-links are only shown on mobile
    .main-nav__li--meta {
        background-color: $main-nav-bg;
        border-bottom: solid 2px #FFF;
    }
    //link of a navi item
    .main-nav__link {
        @include button-reset;
        display: table-cell;
        height: $main-nav-item-height;
        width: $main-nav-width;
        vertical-align: middle;
        color: #000;
        text-decoration: none;
        font-weight: bold;
        padding: $main-nav-item-padding;
        position: relative;

        .svg-inline--fa {
            display: none;
        }
    }

    .main-nav__link--level-2 {
        width: calc(#{$main-nav-width} - #{$main-nav-back-button-width});
    }

    .main-nav__link--level-3 {
        width: calc(#{$main-nav-width} - #{$main-nav-back-button-width});
    }

    .main-nav__link--drilldown {
        .svg-inline--fa {
            display: block;
            color: $migrol-red;
            position: absolute;
            right: 2rem;
            top: 2.5rem;
            margin-top: -0.7rem;
        }
    }

    .main-nav__link--level-1--active, .main-nav__link--level-2--active, .main-nav__link--level-3--active {
        color: $migrol-red;
    }
    //title for the currently shown subnavi (name of the parent item, only for mobile)
    .main-nav__link--title {
        color: $migrol-red;
    }
    //only used on desktop
    .main-nav__link--desktop {
        display: none;
    }
    //back-button, only for mobile
    .main-nav__back-button {
        @include button-reset;
        position: absolute;
        top: (2 * $main-nav-item-height);
        left: (-1 * $main-nav-back-button-width);
        bottom: 0;
        width: $main-nav-back-button-width;
        background-color: $main-nav-back-button-bg;
        transition: left $main-nav-transition-duration;
        z-index: 1001;
        font-size: 2rem;
        text-align: center;

        .svg-inline--fa {
            color: $migrol-red;
            position: absolute;
            top: 50%;
            margin-top: -1rem;
            left: 50%;
            margin-left: -0.625rem;
        }
    }

    .main-nav__back-button--active {
        left: 0;
    }

    .main-nav__link-migros-username {
        color: #000;
        text-decoration: none;
    }
}

@include for-tablet-landscape-up {
    .main-nav {
        .hamburger {
            display: none;
        }
    }
    //hide breadcrumbs
    .main-nav__breadcrumbs {
        display: none;
    }
    //groups a set of subpage-links
    .main-nav__ul {
        margin: 0;
        padding: 0;
    }
    //level 1 is a bit different
    .main-nav__ul--level-1 {
        display: flex;
        justify-content: flex-end;
        height: auto !important; //this overrides the fixed height set by javascript for the mobile navigation
    }

    .main-nav__ul--level-2 {
        background-color: #fff;
        position: absolute;
        left: ($content-padding-desktop - 2rem);
        right: ($content-padding-desktop - 2rem);
        padding: 2.5rem 2rem 2rem 2rem;
        columns: 2;
        column-gap: 3rem;
        column-rule: solid 1px $grey-241;
        transform: translateY(-1000px);
        transition: transform 0.25s;
        z-index: -1;

        @include for-desktop-up {
            columns: 3;
        }
    }
    //container for a nav-item
    .main-nav__li {
        list-style-type: none;
    }

    .main-nav__li--level-1 {
        &::before {
            content: '';
            display: block;
            float: left;
            height: 8.6rem;
            margin-bottom: -1px;
            width: 1.5rem;

            @include for-desktop-up {
                width: 3.5rem;
            }
        }
        //warning, this not really how it should be, but for the sake of css navigation opening, we do it anyway
        &.is-open {

            .main-nav__link--level-1 {
                color: $migrol-red;
                border-bottom: solid 2px $migrol-red;
            }

            .main-nav__ul--level-2 {
                transform: translateY(0);
                transition: transform 0.25s;
            }
        }

        &:nth-child(2) {
            .main-nav__link--level-1 {
                margin-left: 0;
            }

            @include for-desktop-up {
                &:hover::before {
                    width: 20rem;
                }
            }
        }
    }

    .main-nav__li--level-2 {
        page-break-inside: avoid;
        break-inside: avoid;
        margin-bottom: 4rem;
    }
    //hide li with unused modifiers
    .main-nav__li--meta, .main-nav__li--title {
        display: none;
    }
    //link of a navi item
    .main-nav__link {
        text-decoration: none;
        color: #000;
        white-space: nowrap;
    }

    .main-nav__link--level-1 {
        @include font-bold;
        font-size: 2rem;
        display: inline-block;
        height: 8.5rem;
        padding: 3.6rem 1rem 0 2.7rem;
        position: relative;
        top: 1px;
        line-height: 1.6rem;

        &--active {
            color: $migrol-red;
            //border-bottom: solid 2px $migrol-red;
        }

        .svg-inline--fa {
            font-size: 0.8em;
            position: absolute;
            left: 0.5rem;
            color: $migrol-red;
            font-size: 0.9em;
            transform: rotate(90deg);
        }
    }

    .main-nav__link--level-2 {
        font-size: 2rem;
        line-height: 4rem;
        @include font-bold;

        &--active {
            color: $migrol-red;
        }

        .svg-inline--fa {
            margin-left: 0.5rem;
            color: $migrol-red;
        }
    }
    //this is specifically only on anchors, not on non-linked titles
    a.main-nav__link--level-2:hover {
        color: $migrol-red;
    }

    .main-nav__link--level-3 {
        font-size: 1.8rem;
        line-height: 3rem;

        &--active {
            color: $migrol-red;
        }

        &:hover {
            color: $migrol-red;
        }
    }
    //hide links with unused modifiers
    .main-nav__link--drilldown, .main-nav__back-button {
        display: none;
    }
}

.in-experience-editor {
    //disable navigation transitions in exp editor
    .main-nav__ul--level-2 {
        transition: none !important;
    }
}
