﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.blog-post-teaser {
    height: 100%;
    min-height: 30rem;
    position: relative;
    overflow: hidden;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;

    &:hover &__link {
        color: $migrol-red;
    }

    @include for-tablet-landscape-up {
        min-height: 40rem;
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center center;
        transform: scale(1.0, 1.0);
        transition: transform 0.5s ease-out;
        z-index: -1;
    }

    &:hover &__background {
        transition: transform 0.5s ease-out;
        transform: scale(1.03, 1.03);
    }

    &__content {
        display: flex;
        flex-direction: column;
        min-height: 14rem;
        max-width: 55.4rem;
        margin: 1.5rem;
        padding: 1rem;
        background-color: rgba(255,255,255,0.9);

        @include for-tablet-landscape-up {
            margin: 2rem;
            padding: 2rem;
        }
    }

    &__title-1 {
        color: $migrol-red;
        @include font-bold;
        font-size: 2rem;
        margin-bottom: 0.5rem;

        @include for-tablet-landscape-up {
            font-size: 2.2rem;
        }
    }

    &__title-2 {
        @include font-normal;
        display: block;

        &::first-letter {
            //first letter of title-2 must be uppercase (special requirement migrol)
            text-transform: uppercase;
        }
    }

    &__copy {
        color: #000;
        font-size: 1.6rem;
        line-height: 2.2rem;
        margin-bottom: 1rem;

        @include for-tablet-landscape-up {
            font-size: 1.8rem;
        }
    }

    &__link {
        color: #000;
        @include font-bold;
        font-size: 1.6rem;
        margin-top: auto; //this puts the item at the bottom
        text-decoration: none;
    }

    &__arrow {
        margin-left: .2rem;
        position: relative;
        top: 1px;
    }

    &__categories {
        color: $migrol-red;
        @include font-bold;
        font-size: 1.1rem;
        margin-bottom: 0.5rem;

        @include for-tablet-landscape-up {
            font-size: 1.2rem;
        }
    }

    &__date-author {
        color: #000;
        font-size: 1rem;
        margin-bottom: 1rem;

        @include for-tablet-landscape-up {
            font-size: 1.2rem;
        }
    }

    &--overview {
        margin-bottom: 3rem;

        .blog-post-teaser {
            &__title-1 {
                font-size: 2.8rem;
                margin-bottom: 0.7rem;

                @include for-tablet-landscape-up {
                    font-size: 3.0rem;
                }
            }

            &__categories {
                font-size: 1.2rem;
                margin-bottom: 0.8rem;

                @include for-tablet-landscape-up {
                    font-size: 1.6rem;
                }
            }

            @include for-tablet-portrait-down {
                &__background {
                    position: relative;
                    top: auto;
                    left: auto;
                    right: auto;
                    bottom: auto;
                    background-size: cover;
                    background-position: center center;
                    transform: none !important;
                    transition: none !important;
                    z-index: auto;
                    height: 30rem;
                }
            }
        }
    }
}