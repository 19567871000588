﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.teaser {
    height: 100%;
    min-height: 30rem;
    position: relative;
    overflow: hidden;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;

    @include for-tablet-landscape-up {
        min-height: 40rem;
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center center;
        transform: scale(1.0, 1.0);
        transition: transform 0.5s ease-out;
        z-index: -1;
    }

    &:hover &__background {
        transition: transform 0.5s ease-out;
        transform: scale(1.03, 1.03);
    }

    &:hover &__link {
        color: $migrol-red;
    }

    &__tooltip {
        @include button-reset;
        display: block;
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        font-size: 2rem;
        z-index: 2;

        @include for-tablet-landscape-up {
            top: 2rem;
            right: 2rem;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        min-height: 14rem;
        max-width: 55.4rem;
        margin: 1.5rem;

        @include for-tablet-landscape-up {
            margin: 2rem;
        }
    }

    &__title-1 {
        @include font-bold;
        font-size: 2rem;
        margin-bottom: 0.5rem;

        @include for-tablet-landscape-up {
            font-size: 2.2rem;
        }
    }

    &__title-2 {
        @include font-normal;
        display: block;
    }

    &__copy {
        font-size: 1.6rem;
        line-height: 2.2rem;
        margin-bottom: 1rem;

        @include for-tablet-landscape-up {
            font-size: 1.8rem;
        }
    }

    &__link {
        @include font-bold;
        font-size: 1.6rem;
        margin-top: auto; //this puts the item at the bottom
        text-decoration: none;
    }

    &__arrow {
        margin-left: .2rem;
        position: relative;
        top: 1px;
    }
    //////////////////////
    //Teaser Variations
    //////////////////////
    &--with-image {
        flex-direction: column-reverse;
        justify-content: flex-start;

        .teaser__image-container {
            max-width: 55.4rem;
            margin: 1.5rem 1.5rem 0 1.5rem;
            flex: none;

            @include for-tablet-landscape-up {
                margin: 2rem 2rem 0 2rem;
            }
        }

        .teaser__image {
            width: 100%; //needs to be set so the initial calculation does not fail
            height: auto;
            display: inline-block;
        }
    }

    &--box {
        padding-top: 15rem;

        @include for-tablet-landscape-up {
            padding-top: 0;
        }

        .teaser__content {
            padding: 1rem;
            background-color: rgba(255,255,255,0.9);

            @include for-tablet-landscape-up {
                padding: 2rem;
            }
        }

        .teaser__title-1 {
            color: $migrol-red;
        }

        .teaser__copy, .teaser__link {
            color: #000;
        }
    }

    &--box-small {
        min-height: auto;

        .teaser__content {
            padding: 1rem;
            background-color: rgba(255,255,255,0.9);
            max-width: none;
            min-height: auto;

            @include for-tablet-landscape-up {
                padding: 2rem;
            }
        }

        .teaser__title-1 {
            color: $migrol-red;
        }

        .teaser__copy, .teaser__link {
            display: none;
        }
    }

    &--no-box-white {
        .teaser__content {
            justify-content: flex-end;

            @include for-tablet-landscape-up {
                padding: 2rem;
            }
        }

        .teaser__tooltip {
            color: #fff;
        }

        .teaser__title-1, .teaser__copy, .teaser__link {
            color: #fff;
            margin-top: 0;
        }

        &:hover .teaser__link {
            color: #fff;
            text-decoration: underline;
        }
    }

    &--no-box-black {
        .teaser__content {
            justify-content: flex-end;

            @include for-tablet-landscape-up {
                padding: 2rem;
            }
        }

        .teaser__title-1, .teaser__copy, .teaser__link {
            color: #000;
            margin-top: 0;
        }

        &:hover .teaser__link {
            color: #000;
            text-decoration: underline;
        }
    }

    &--no-box-gray {
        min-height: 0;
        justify-content: flex-start;
        background-color: $grey-230;

        .teaser__background {
            position: relative;
            background-size: cover;
            background-position: center center;
            transform: none;
            transition: none;
            z-index: auto;
            overflow: hidden;
            height: 0;
        }

        &:hover .teaser__background {
            transition: none;
            transform: none;
        }

        .teaser__title-1 {
            color: $migrol-red;
        }

        .teaser__copy, .teaser__link {
            color: #000;
        }
    }

    &--product {
        min-height: 0;
        justify-content: flex-start;

        .teaser__background {
            position: relative;
            background-size: cover;
            background-position: center center;
            transform: none;
            transition: none;
            z-index: auto;
            border: 2px solid $grey-230;
            overflow: hidden;
            height: 0;
        }

        &:hover .teaser__background {
            transition: none;
            transform: none;
        }

        .teaser__title-1 {
            color: $migrol-red;
        }

        .teaser__copy, .teaser__link {
            color: #000;
        }
    }

    &--no-link {
        cursor: default;
    }
    //////////////////////
    //END Teaser Variations
    //////////////////////
}

//special case: teaser--box combined with teaser--with-image must not have padding top
.teaser--box.teaser--with-image {
    padding-top: 0;
}


/*teaser styles for all the column widths*/
.col--small {
    .teaser {
        &__image {
            width: 100%;
        }
    }
}

.col--medium {
    .teaser {
        &__image-container {
            max-width: none;
            text-align: center;
        }

        &__image {
            width: 75%;
        }
    }
}

.col--large, .col--x-large {
    .teaser {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;

        &--no-box-gray {
            flex-direction: column;
            align-items: initial;

            .teaser__background {
                background-size: contain;
                background-repeat: no-repeat;
                padding-top: 25% !important;
            }
        }

        &__image-container {
            margin-bottom: 1.5rem;
            text-align: right;
            flex: 1;

            @include for-tablet-landscape-up {
                margin-bottom: 2rem;
            }
        }

        &__content {
            flex: 1;
        }

        &__image {
            width: 100%;
        }
    }
}

.col--x-large {
    .teaser {
        &__image {
            width: 75%;
        }
    }
}
