﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.boxes {
    @include element-margin;
    column-gap: 1.2rem;
    grid-template-rows: auto auto auto auto;
    grid-auto-flow: column;

    &--with-recommendation {
        grid-template-rows: auto auto auto auto auto; //extra row at the top
    }

    @include for-tablet-landscape-up {
        .boxes__col {
            display: contents;
        }

        &--2-col {
            display: grid;
            grid-template-columns: 1fr 1fr;

            .boxes__col {
                display: contents;
            }
        }

        &--3-col {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
        }

        &--4-col {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto auto auto auto auto auto auto auto; //2 * 4 rows

            @include for-desktop-up {
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-template-rows: auto auto auto auto; //1 * 4 rows
            }

            .boxes__col:nth-child(even) .boxes__row:first-child {
                margin-top: 1.2rem; //add some margin in for the first row of the 2nd box in a column

                @include for-desktop-up {
                    margin-top: unset;
                }
            }

            &.boxes--with-recommendation {
                grid-template-rows: auto auto auto auto auto auto auto auto auto auto; //extra row at the top (2 * 5 rows)

                @include for-desktop-up {
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    grid-template-rows: auto auto auto auto auto; //1 * 5 rows
                }
            }
        }
    }

    &__col {
        display: flex;
        flex-direction: column;
        margin-top: 1.2rem;

        &--recommended {
            .boxes__row {
                border-left-color: $migrol-green;
                border-right-color: $migrol-green;
            }

            .boxes__row--num-4 {
                border-bottom-color: $migrol-green;
            }
        }
    }

    &__row {
        $recommendation-border-width: 5px;

        background-color: $grey-230;
        padding-left: 2rem;
        padding-right: 2rem;
        border-left: solid $recommendation-border-width transparent;
        border-right: solid $recommendation-border-width transparent;

        &--num-1 {
            padding-top: 2rem;
        }

        &--num-4 {
            border-bottom: solid $recommendation-border-width transparent;
            padding-bottom: 2rem;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-end;

            &:not(:empty) {
                padding-top: 2rem;
            }

            .migrolchrome__placeholder-wrapper {
                width: 100%;
            }
        }

        &--recommended {
            text-align: center;
            background-color: $migrol-green;
            color: #fff;
            @include font-bold;
            font-size: 2rem;
            padding-top: 0.5rem;
            padding-bottom: 1rem;
        }

        &--no-bg {
            background-color: transparent;
        }

        .migrol-rt-content {
            margin-bottom: 0;
            display: contents;

            p + ol, p + ul {
                margin-top: unset !important;
            }

            ol, ul {
                margin-bottom: 0 !important;
            }
        }

        p {
            display: contents;
        }

        h1, h2, h3, h4, h5, h6 {
            margin-top: 0;
        }

        a.call-to-action-button {
            position: static;
            transform: none;
            left: auto;
            width: 100%;
            padding: .5rem 1rem;
        }
    }
}

.contentblock--gray {
    .boxes__row {
        background-color: #fff;

        &--recommended {
            background-color: $migrol-green;
        }

        &--no-bg {
            background-color: transparent;
        }
    }
}

.contentblock__content--narrow {
    .boxes--3-col {
        display: block;

        .boxes__col {
            display: block;
        }

        @include for-desktop-up {
            display: grid;

            .boxes__col {
                display: contents;
            }
        }
    }

    .boxes--4-col {
        display: block;

        .boxes__col {
            display: block;
        }

        @include for-desktop-up {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto auto auto auto auto auto auto auto; //2 * 4 rows

            .boxes__col {
                display: contents;
            }

            .boxes__col:nth-child(even) .boxes__row:first-child {
                margin-top: 1.2rem; //add some margin in for the first row of the 2nd box in a column
            }

            &.boxes--with-recommendation {
                grid-template-rows: auto auto auto auto auto auto auto auto auto auto; //extra row at the top (2 * 5 rows)
            }
        }
    }
}
