﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.hero__image--buy-energy-header {
    background-image: url('/assets/MigrolContent/img/header-buy-energy-home.jpg');
}

.contentblock--buy-energy {
    background-color: $grey-230;
    padding: 3.75rem 0;    

    &-single-product {
        padding: 0 !important;

        .buy-energy-header {
            padding: 0 !important;

            &__products {
                display: block;
            }

            &__product-details {
                padding: 2.8rem 3.4rem;
            }
        }
    }
    &-single-product-promo {
        background-color: $migrol-red;

        .buy-energy-header {
            background-color: $migrol-red;
        }
    }

    @include for-tablet-landscape-up {
        padding: 6rem 0 7.5rem;
    }

    @include for-desktop-up {
        background-color: transparent;
        padding: 0;
    }
}

.layout__page-start .contentblock--buy-energy {
    margin-bottom: 0;
}

.buy-energy-header {
    background-color: $grey-230;

    @include for-desktop-up {
        padding: 3.5rem 10rem 5rem 10rem;
        margin-top: -15.7rem;
    }

    &--in-content {
        margin-top: 0;
    }

    &__title {
        display: block;
        @include font-bold;
        font-size: 2.8rem;
        color: $migrol-red;
        margin-bottom: 3.3rem;

        @include for-desktop-up {
            font-size: 3.6rem;
        }
    }

    &__products {
        display: grid;
        grid-row-gap: 1.9rem;
        grid-column-gap: 1.2rem;
        grid-auto-columns: minmax(0, 1fr);
    }

    &__product-tab {
        height: 12.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $grey-183;
        cursor: pointer;
        background-color: #fff;

        &:hover {
            border-color: $migrol-red;
            color: $migrol-red;
        }

        &--active {
            border-color: $migrol-red;
            border-width: 2px;
            color: $migrol-red;
        }

        &__logo {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &__product {
                position: relative;

                &__icon {
                    height: 5.8rem;
                    width: 5.8rem;
                }

                &__promo-icon {
                    position: absolute;
                    height: 32px;
                    top: -1.5rem;
                    right: -3.3rem;
                    color: $migrol-red;
                }
            }
        }

        &__name {
            display: block;
            @include font-bold;
            font-size: 1.6rem;
            margin-top: 0.6rem;
            text-align: center;
        }
    }

    &__product-details {
        grid-column: 1/2;
        color: #000;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 1.6rem;
        line-height: 2.2rem;
        margin-top: 1rem;
        margin-bottom: 4rem;

        &--pos1 {
            grid-row: 2/3;
        }

        &--pos2 {
            grid-row: 3/4;
        }

        &--pos3 {
            grid-row: 4/5;
        }

        &--pos4 {
            grid-row: 5/6;
        }

        &--pos5 {
            grid-row: 6/7;
        }        

        @include for-tablet-landscape-up {
            flex-direction: row;
            margin-bottom: 1.8rem;
        }

        @include for-desktop-up {
            grid-row: 2/3;
            margin-top: 2.4rem;
            margin-bottom: 0;

            &--size1 {
                grid-column: 1/2;
            }

            &--size2 {
                grid-column: 1/3;
            }

            &--size3 {
                grid-column: 1/4;
            }

            &--size4 {
                grid-column: 1/5;
            }

            &--size5 {
                grid-column: 1/6;
            }
        }

        &--single-product {
            margin-top: 0;
        }

        &--promo {
            padding: 2.8rem 3.4rem;
            background-color: $migrol-red;
            color: #fff;
            margin-top: -1.9rem;
            margin-bottom: 0;

            @include for-tablet-landscape-up {
                margin-top: 0;
                margin-bottom: 1.8rem;
            }

            @include for-desktop-up {
                margin-bottom: 0;
            }
        }

        &__order {

            @include for-tablet-landscape-up {
                width: 52%;
            }
        }

        &__title {
            @include font-bold;
            font-size: 2.4rem;
            margin-bottom: 0.6rem;
            line-height: 3.2rem;

            &__promo-icon {
                height: 3.2rem;
                width: 3.2rem;
                display: inline-block;
                position: absolute;
                margin-left: 0.8rem;
            }
        }

        &__desc {
            margin-bottom: 3.5rem;
        }

        &__teaser {
            margin-top: 3rem;

            @include for-tablet-landscape-up {
                margin-top: 0;
                width: 37%;
            }

            &__content {
                &.migrol-rt-content {
                    font-size: 1.6rem;
                    line-height: 2.2rem;
                    margin-bottom: 0;
                }

                a, a:hover {
                    color: #fff !important;
                }
            }
        }
    }

    &__form {

        &__zipcity {
            background-color: #fff;
            font-size: 1.4rem;
            margin-bottom: 1.2rem;
            color: #000;
        }

        &__amount {
            font-size: 1.4rem;
            margin-bottom: 1.2rem;

            @include for-tablet-landscape-up {
                margin-bottom: 0;
                width: 48%;
            }
        }

        &__submit {
            @include button-reset;
            @include font-bold;
            text-align: center;
            font-size: 1.6rem;
            border: none;
            color: #fff;
            background-color: $migrol-green;
            display: block;
            height: 4rem;
            padding: 0.5rem 1rem;
            cursor: pointer;
            width: 100%;

            @include for-tablet-landscape-up {
                width: 48%;
            }

            &:hover {
                background-color: $migrol-green-dark;
            }
        }

        &__side-by-side {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include for-tablet-landscape-up {
                flex-direction: row;
            }
        }
    }

    &__link-button {
        @include button-reset;
        @include font-bold;
        font-size: 1.6rem;
        color: #fff;
        text-decoration: none;
        background-color: $migrol-green;
        display: block;
        padding: 1rem 2.5rem;
        text-align: center;

        &:hover {
            background-color: $migrol-green-dark;
        }

        @include for-tablet-landscape-up {
            display: inline-block;
        }
    }
}
