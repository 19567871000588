﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.tickdown {

    hyphens: manual;

    a {
        color: #000;
    }

    &__timer-block {
        margin-bottom: 4.5rem;

        @include for-tablet-landscape-up {
            display: flex;
            justify-content: space-between;
        }
    }

    &__timer-time {
        width: 18rem;
        padding: 1rem;
        background-color: $migrol-red;
        color: #fff;
        margin-top: 0.4rem;
    }

    &__columns {
        @include for-tablet-landscape-up {
            display: flex;
            margin-bottom: 0;
        }

        &--center-vertical {
            @include for-tablet-landscape-up {
                align-items: center;
            }
        }
    }

    &__column {
        margin-bottom: 2.5rem;

        &:last-of-type {
            margin-bottom: 0;
        }

        @include for-tablet-landscape-up {
            margin-bottom: 0;
            margin-right: 3.5rem;            
            flex: 1;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    &__spacer-bottom {
        margin-bottom: 2.5rem;
    }

    &__message-box {
        background-color: $grey-230;
        padding: 3rem 1.5rem 2.5rem 1.5rem;
        margin-bottom: 3rem;

        &-title {
            font-size: 1.8rem;
            color: $migrol-red;
            margin-bottom: 1.5rem;
        }
    }

    &__loading {
        display: flex;
        justify-content: center;

        &-icon {
            font-size: 2rem;
            display: inline-block;
            animation: tickdown-loading 800ms linear infinite;
        }
    }

    /*#region Running */

    &__price-table-wrapper {
        border: 2px solid $migrol-green;
        background-color: $grey-230;
        margin-bottom: 2.5rem;
    }
    &__price-table-productinfo {
        padding: 2.8rem 1.5rem 1.8rem;        

        @include for-tablet-landscape-up {
            padding: 3rem 3.8rem;
            display: flex;            
        }

        &-icon {
            margin: 0 auto;

            @include for-tablet-landscape-up {
                margin: 0 3rem 0 0;
            }
        }

        &-name {
            font-size: 2rem;
            line-height: 3rem;
            color: $migrol-red;
            @include font-bold;
            margin-bottom: 0.3rem;
        }
    }

    &__price-table {
        width: 100%;
        border-spacing: 0;
        border-collapse: collapse;        
        display: none;

        @include for-tablet-landscape-up {
            display: table;
        }

        tr {
            border-bottom: 2px solid $grey-205;

            &:last-child {
                border-bottom: none;
            }
        }

        th,td {
            padding: 1rem 1.8rem;
            text-align: right;
            font-weight: normal;

            &.tickdown__price-table-col-text {
                text-align: left;
            }
        }

        &-header-title {
            font-size: 2rem;
            line-height: 2rem;
            @include font-bold;
        }
        &-header-subtitle {
            font-size: 1.4rem;
            line-height: 2rem;
        }
        &-row {
            &--important {
                font-size: 2rem;
                line-height: 2rem;
                @include font-bold;
            }
            &--red {
                color: $migrol-red;
            }
            &--green {
                color: $migrol-green;
            }
        }        
    }

    &__price-table-mobile {

        @include for-tablet-landscape-up {
            display: none;
        }

        &-tabs {
            border-collapse: collapse;
            display: flex;
        }

        &-tab {
            @include button-reset;
            border-top: solid 1px $grey-183;
            border-right: solid 1px $grey-183;
            border-bottom: solid 1px $grey-183;
            background-color: $grey-205;
            text-decoration: none;
            @include font-bold;
            font-size: 1.6rem;
            line-height: 2.2rem;
            color: #000;
            flex: 1;
            text-align: center;
            padding: 1rem 0 0.75rem;

            &:last-of-type {
                border-right: none;
            }

            &--active {
                background-color: #fff;
                color: $migrol-red;
            }
        }

        &-tab-content {
            display: none;

            &--active {
                display: block;
            }
        }

        &-row {
            display: flex;
            justify-content: space-between;
            border-bottom: solid 2px $grey-183;
            font-size: 1.6rem;
            color: #000;
            padding: 1.4rem 1.1rem;

            &:last-of-type {
                border-bottom: none;
            }

            &--important {
                background-color: #fff;
                font-size: 1.8rem;
                @include font-bold;
            }
            &--red {
                color: $migrol-red;
            }
            &--green {
                color: $migrol-green;
            }
            &--centered {
                justify-content: center;
            }
        }

        &-price {
            text-align: right;
        }

        &-smalltext {
            @include font-normal;
            font-size: 1.2rem;
            display: block;
        }
    }

    &__starting-price-info {
        margin-top: 2.5rem;
        font-size: 1.4rem;
    }

    &__delivery-period-info {
        background-color: $grey-230;
        padding: 3rem 1rem 2.5rem 1rem;
        margin-top: 3rem;
    }

    &__delivery-period-dates {
        @include font-bold;
        color: $migrol-red;
        margin-bottom: 2.5rem;
    }

    &__order-button-step1 {
        display: none;
        text-align: right;

        @include for-tablet-landscape-up {
            display: block;
        }
    }
    /*#endregion*/

    /*#region Order Step 1*/

    &__price-table-productinfo-price {
        font-size: 2rem;

        &-bold {
            @include font-bold;
        }
        &-small {
            font-size: 1.4rem;
            line-height: 2rem;
        }
    }
    /*#endregion*/

    /*#region Errors*/
    &__error-message-block {
        padding: 2rem;
        border: 2px solid $migrol-red;
        color: $migrol-red;
        background-color: #fff;
        margin-bottom: 3rem;
        margin-top: 3rem;

        @include for-tablet-landscape-up {
            width: 50%;
            margin-left: auto;
            margin-right: auto;
        }

        li {
            list-style: none;
        }
    }
    /*#endregion*/
}

@keyframes tickdown-loading {
    100% {
        transform: rotate(360deg);
    }
}
