﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.shop {
    $progessbarCircleSizeLarge: 4rem;
    $progessbarCircleSizeSmall: 4rem;
    $progessbarCircleSizeCurrent: 5rem;

    hyphens: manual;

    a {
        color: #000;
    }
    /*#region Progress Bar*/
    &__progressbar {
        counter-reset: step;
        display: flex;
        padding-top: 1.8rem;
        padding-bottom: 1.2rem;

        @include for-tablet-landscape-up {
            padding-top: 2.3rem;
            padding-bottom: 1.3rem;
        }
        //each li is a step
        li {
            list-style-type: none;
            flex: 1;
            float: left;
            font-size: 1.6rem;
            position: relative;
            text-align: center;
            text-transform: uppercase;
            color: #000;
            background-color: transparent;
            //step circle
            &:before {
                position: relative;
                width: $progessbarCircleSizeLarge;
                height: $progessbarCircleSizeLarge;
                content: counter(step);
                counter-increment: step;
                line-height: (0.933 * $progessbarCircleSizeLarge);
                border: 1px solid #000;
                display: block;
                text-align: center;
                margin: 0 auto 1rem auto;
                border-radius: 50%;
                background-color: $grey-230;
                z-index: 2;
                @include font-bold;
                font-size: 2rem;

                @include for-tablet-landscape-up {
                    width: $progessbarCircleSizeSmall;
                    height: $progessbarCircleSizeSmall;
                    line-height: (0.933 * $progessbarCircleSizeSmall);
                }
            }
            //step line
            &:after {
                display: none;
                width: 100%;
                height: 2px;
                content: '';
                position: absolute;
                background-color: $grey-153;
                top: ($progessbarCircleSizeLarge / 2);
                left: -50%;
                z-index: 1;

                @include for-tablet-landscape-up {
                    top: ($progessbarCircleSizeSmall / 2);
                }
            }
            //first li does not have a line
            &:first-child:after {
                content: none;
            }

            &.active {
                color: #000;

                &:before {
                    color: #fff;
                    background-color: #000;
                    border-color: #000;
                }

                &:after {
                    display: block;
                }
            }

            &.current {
                color: $migrol-red;

                &:before {
                    color: #fff;
                    background-color: $migrol-red;
                    border-color: $migrol-red;
                    width: $progessbarCircleSizeCurrent;
                    height: $progessbarCircleSizeCurrent;
                    line-height: (0.933 * $progessbarCircleSizeCurrent);
                    margin-top: -0.5rem;
                    font-size: 2.7rem;
                }

                &:after {
                    display: block;
                }

                .shop__progressbar_steptext {
                    margin-top: 0.5rem;
                    @include font-bold;
                }
            }

            &.clickable {
                cursor: pointer;
            }
        }

        &_steptext {
            display: none;

            @include for-tablet-landscape-up {
                display: block;
                margin-top: 1.4rem;
                line-height: 3rem;
            }
        }
    }
    /*#endregion*/

    &__fieldgroup {
        border: none;
        padding-top: 4.5rem;
        padding-bottom: 4.5rem;
        border-bottom: 1px solid #000;

        @include for-tablet-landscape-up {
            padding-left: 10%;
            padding-right: 10%;
        }

        @include for-desktop-up {
            padding-left: 16.6667%;
            padding-right: 16.6667%;
        }

        &--no-separator {
            border-bottom: none;
        }

        &--no-padding-bottom {
            padding-bottom: 0;
        }

        &--no-padding-top {
            padding-top: 0;
        }

        &-title {
            @include font-normal;
            color: $migrol-red;
            font-size: 2.4rem;
            margin-bottom: 2.7rem;

            &--big {
                font-size: 3rem;
            }
        }
    }

    /*#region Unloading Points*/
    &__unloadingpoint {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 2.8rem;
    }

    &__unloadingpoint-warenempfaenger {
        width: 100%;

        @include for-tablet-landscape-up {
            width: 69%;
        }
    }

    &__unloadingpoint-amount {
        width: calc(100% - 4.3rem);
        margin-top: 1.5rem;

        @include for-tablet-landscape-up {
            width: calc(26.4% - 2.9rem);
            margin-top: 0;
        }
    }

    &__removeunloadingpoint {
        width: 2.9rem;
        margin-top: 1.5rem;

        @include for-tablet-landscape-up {
            margin-top: 0;
        }

        &-button {
            @include button-reset;
            background: none;
            height: 2.9rem;
            width: 2.9rem;
            cursor: pointer;
        }
    }

    &__addunloadingpoint {
        margin-top: 3rem;

        &-button {
            @include button-reset;
            background: none;
            font-size: 1.6rem;
            line-height: 3rem;
            @include font-normal;
            cursor: pointer;

            &:hover .shop__addunloadingpoint-icon {
                background-color: $migrol-green-dark;
            }
        }

        &-icon {
            display: inline-block;
            width: 3rem;
            height: 3rem;
            font-size: 2rem;
            line-height: 3rem;
            background-color: $migrol-green;
            color: #fff;
            margin-right: 1.1rem;
            font-family: none;
            text-align: center;
        }
    }

    &__zipcodeOrWarenempfaenger-we + .shop__zipcodeOrWarenempfaenger-zip {
        margin-top: 1rem;
    }

    &__unloadingpoint-presets {
        display: flex;
        margin-top: 1.5rem;
        flex-basis: 100%;

        button {
            @include button-reset;
            @include font-bold;
            text-align: center;
            padding: 0.8rem 1rem;
            font-size: 1.2rem;
            flex: 1;
            background-color: #fff;
            color: #333;
            border: 1px solid $grey-183;

            &:hover {
                background-color: $grey-241;
            }

            & + button {
                margin-left: 0.3rem;
            }
        }
    }

    &__more-unloadingpoints {
        margin-top: 3rem;
    }
    /*#endregion*/

    /* #region Options */
    &__options-block {
        margin-bottom: 5rem;

        &:last-of-type {
            margin-bottom: 1rem;
        }

        @include for-tablet-landscape-up {
            display: flex;
            margin-bottom: 3rem;
        }

        &-input {
            margin-bottom: 2rem;

            @include for-tablet-landscape-up {
                margin-bottom: 0;
                width: 38%;
                margin-right: 1.5%;
            }
        }

        &-desc {

            @include for-tablet-landscape-up {
                flex: 1;
            }

            .migrol-form__input-label--invisible {
                display: none;

                @include for-tablet-landscape-up {
                    display: block;
                    padding-bottom: 1.4rem;
                }
            }
        }

        /* WWW-2212 */
        .buy-energy-header__product-details__teaser {
            background-color: $migrol-red;
            padding: 2.8rem 3.4rem;
            width: 100%;
            color: #fff;
        }
    }
    /* #endregion */

    &__discountcode {
        .shop__error-message, .shop__success-message {
            margin-top: 0;
        }
    }
    /* #region Materials */
    &__materials {
        @include for-tablet-landscape-up {
            display: grid;
            grid-column-gap: 1.2rem;
        }
    }

    &__material {
        margin-bottom: 2rem;        
        display: flex;
        flex-direction: column;
        flex: 1;

        @include for-tablet-landscape-up {
            display: contents;
            margin-bottom: 0;
        }

        &-block-col-1 {
            grid-column: 1/2;
        }
        &-block-col-2 {
            grid-column: 2/3;
        }

        &-block-desc {
            grid-row: 1/2;
            background-color: $grey-230;
            border: 2px solid $grey-230;
            border-width: 2px 2px 0 2px;
            &:last-child {
                border-width: 2px;
            }

            padding: 2.2rem;

            &--grid {

                @include for-tablet-portrait-up {
                    display: grid;
                    grid-template: "icon title" auto "icon text" auto / 1fr 2fr;
                }
            }
        }

        &-icon {
            display: flex;
            justify-content: center;
            margin-bottom: 3rem;
            grid-area: icon;
        }

        &-name {
            @include font-bold;
            color: $migrol-red;
            font-size: 2rem;
            line-height: 3rem;
            margin-bottom: 1rem;
            grid-area: title;
        }

        &-description {
            @include font-normal;
            color: #000;
            font-size: 1.6rem;
            line-height: 2.2rem;
            grid-area: text;
        }

        &-block-price {
            grid-row: 2/3;
            border: 2px solid $grey-230;
            border-width: 0 2px;
            &:last-child {
                border-width: 0 2px 2px 2px;
            }

            color: #000;
        }

        &-price {
            padding: 1.6rem 2.5rem 1rem;
            background-color: $grey-205;
            height: 100%;
        }

        &-price-total {
            @include font-normal;
            font-size: 2.4rem;
            text-align: center;
            margin-top: 2rem;
        }

        &-price-per-unit {
            @include font-bold;
            font-size: 3rem;
            text-align: center;
        }

        &-price-unit {
            @include font-normal;
            font-size: 1.5rem;
            text-align: center;
            display: block;
        }

        &-total-tax {
            @include font-normal;
            font-size: 1.5rem;
            text-align: center;
            display: block;
        }

        &-price-unavailable {
            color: $migrol-red-dark;
        }

        &-price-loading {
            position: relative;
            background-color: $grey-230;
            text-align: center;
            color: #000;
            height: 100%;
        }

        &-price-loading-ani-container {
            height: 73px;
        }

        &-price-loading-ani {

            &, &::before, &::after {
                position: absolute;
                top: 50%;
                left: 50%;
                border: 1px solid $grey-205;
                border-left-color: $migrol-red;
                border-radius: 949px;
            }

            & {
                margin: -43px 0 0 -24px;
                height: 47px;
                width: 47px;
                animation: price-loading-rotation-ani 1150ms linear infinite;
            }

            &::before {
                content: "";
                margin: -22px 0 0 -22px;
                height: 42px;
                width: 42px;
                animation: price-loading-rotation-ani 1150ms linear infinite;
            }

            &::after {
                content: "";
                margin: -28px 0 0 -28px;
                height: 53px;
                width: 53px;
                animation: price-loading-rotation-ani 2300ms linear infinite;
            }

            @keyframes price-loading-rotation-ani {
                100% {
                    transform: rotate(360deg);
                }
            }
        }

        &-block-selection {
            grid-row: 3/4;
            background-color: $grey-230;
            border: 2px solid $grey-230;
            border-width: 0 2px 2px 2px;

            padding: 2rem;
            font-size: 1.6rem;
            display: flex;
            justify-content: center;
        }

        &--selectable {
            cursor: pointer;
        }

        &--selected {
            border-color: $migrol-green;

            & .shop__material-block-desc {
                border-color: $migrol-green;
            }

            & .shop__material-block-price {
                border-color: $migrol-green;
            }

            & .shop__material-price {
                color: #fff;
                background-color: $migrol-green;
            }

            & .shop__material-block-selection {
                border-color: $migrol-green;
                color: $migrol-green;
                @include font-bold;
            }
        }

        &--single {
            //single material only
            @include for-tablet-landscape-up {
                margin-left: 8%;

                &:last-child {
                    margin-right: 8%;
                }
            }

            @include for-desktop-up {
                margin-left: 12.5%;

                &:last-child {
                    margin-right: 12.5%;
                }
            }
        }
    }

    &__price-info {
        margin-top: 3rem;

        .migrol-rt-content {
            font-size: 1.4rem;
            line-height: 2rem;
            margin-bottom: 0;
        }
    }
    /* #endregion */

    /*#region Equipment*/
    &__equipment-row {
        margin-bottom: 2rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
    /*#endregion*/

    /* #region Addresses */
    &__addresses {
        &-separate-billing {
            margin-top: 3rem;
        }
    }

    &__address-block {
        padding-top: 6rem;
        padding-bottom: 6rem;

        @include for-tablet-landscape-up {
            padding-left: 8%;
            padding-right: 8%;
        }

        @include for-desktop-up {
            padding-left: 12.5%;
            padding-right: 12.5%;
        }

        &-title {
            @include font-normal;
            color: $migrol-red;
            font-size: 2.4rem;
            margin-bottom: 2.7rem;
        }

        &-spacer-big {
            margin-bottom: 7rem;
        }

        &-spacer-small {
            margin-bottom: 3rem;
        }

        &--small-padding {
            padding-top: 3rem;
            padding-bottom: 3rem;
        }
    }

    &__paymentmode {
        .migrol-form__radiobutton {
            margin-bottom: 1.5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    /*#endregion*/

    /*#region Product Price Info*/
    &__productprice {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3rem 2rem;
        @include font-normal;
        color: #000;
        border: 2px solid $migrol-green;
        background-color: $grey-230;

        &-block-icon {
        }

        &-block-text {
            margin-top: 2rem;
        }

        &-block-myclimate {
            margin-top: 2rem;
        }

        &-title {
            font-size: 1.4rem;
            line-height: 2rem;
            color: $migrol-green;
            margin-bottom: 1.2rem;
        }

        &-product-name {
            font-size: 2rem;
            line-height: 3rem;
            color: $migrol-red;
            @include font-bold;
        }

        &-amount {
            font-size: 1.6rem;
            line-height: 2.2rem;
        }

        &-price {
            font-size: 2.4rem;
            margin-top: 1.7rem;
            @include font-bold;
        }

        &-tax {
            font-size: 1.4rem;
            display: block;
            @include font-normal;
        }

        &-price-unit {
            font-size: 1.4rem;
            display: block;
            @include font-normal;
        }

        &-price-secondary {
            font-size: 2rem;
            margin-top: 2rem;
        }

        &--horizontal {

            @include for-tablet-landscape-up {
                flex-direction: row;
                align-items: stretch;
                padding: 2.4rem 2.9rem;
                border-width: 1px;
            }

            & .shop__productprice-block-icon {
                @include for-tablet-landscape-up {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-right: 2.1rem;
                }
            }

            & .shop__productprice-block-text {
                @include for-tablet-landscape-up {
                    margin-top: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-right: 2.1rem;
                }
            }

            & .shop__productprice-block-myclimate {
                @include for-tablet-landscape-up {
                    margin-top: 0;
                    flex: 1;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
    /*#endregion*/

    /*#region Position Review*/
    &__position-review {
        display: flex;
        flex-direction: column;

        @include for-tablet-landscape-up {
            flex-direction: row;
        }

        &-address {
            flex: 2;

            &-details {
                @include for-tablet-landscape-up {
                    display: flex;
                    padding-right: 5rem;
                    justify-content: space-between;
                }
            }
        }

        &-product {
            flex: 1;
            margin-top: 4rem;

            @include for-tablet-landscape-up {
                margin-top: 0;
            }

            small {
                @include font-normal;
                font-size: 1.4rem;
            }
        }

        &-filltank {
            margin-top: 3rem;

            @include for-tablet-landscape-up {
                margin-top: 4rem;
            }
        }

        &-total {
            background-color: $grey-230;
            padding: 2.3rem 0;
            @include font-bold;
            font-size: 2rem;
            color: #000;
        }
    }
    /*#endregion*/

    &__acceptance-checkbox {
        margin-bottom: 3rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    /*#region Errors*/
    &__error-message-block {
        padding: 2rem;
        border: 2px solid $migrol-red;
        color: $migrol-red;
        background-color: #fff;
        margin-bottom: 3rem;

        @include for-tablet-landscape-up {
            width: 50%;
            margin-left: auto;
            margin-right: auto;
        }

        li {
            list-style: none;
        }
    }

    &__error-message {
        color: $migrol-red;
        margin-top: 1rem;
    }
    &__success-message {
        color: $migrol-green;
        margin-top: 1rem;

        &--inverted {
            background-color: $migrol-green;
            color: #fff;
            padding: 2rem;
        }
    }

    &__warning-message {
        margin-top: 3rem;
        padding: 2rem;
        background-color: $migrol-yellow;

        &-title {
            @include font-bold;
            font-size: 1.1em;
            margin-bottom: 0.3rem;
        }
    }
    /*#endregion*/

    &__partner-text {
        margin-bottom: 2rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__two-col-block {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 2rem;

        @include for-tablet-landscape-up {
            grid-template-columns: 1fr 1fr;
        }
    }   

    &-teaser__background--tankrevision {
        background-image: url('/assets/MigrolShop/img/teasers/teaser-tankrevision.jpg');
    }
}

/* temporary to hide the debug panel on mobile: */
.shop.migrol-form pre {
    display: none;

    @include for-tablet-landscape-up {
        display: block;
    }
}

.has-error .shop__material {
    border-color: $migrol-red;

    & .shop__material-block-selection {
        color: $migrol-red;
        @include font-bold;
    }
}
