﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.iframe {
    width: 1px;
    min-width: 100%; /*Using min-width to set the width of the iFrame, works around an issue in iOS that can prevent the iFrame from sizing correctly.*/
    display: block;
    margin: 0 auto;

    &__wrapper {
        max-width: 100%; /* so it doesnt totally destroy mobile design */
        overflow: hidden; /* so it doesnt totally destroy mobile design */
        overflow-x: auto;
    }

    &--fixedwidth {
        min-width: 0; /* remove the max-width */
    }
}

@include for-tablet-landscape-up {
    .iframe__wrapper {
        overflow-x: hidden;
    }
}
