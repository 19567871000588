﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.hotline-button {
    display: none;
    background-color: $migrol-red;
    color: #fff;
    @include font-bold;
    align-items: center;
    padding: 1rem 1rem 0.5rem 1rem;

    &__icon {
        font-size: 3rem;
        margin-right: 1rem;
        flex: none;
    }

    &__details {

    }

    &__title {
        font-size: 1.6rem;
        white-space: nowrap;
    }

    &__number {
        font-size: 2.0rem;
        white-space: nowrap;
    }
}

@include for-tablet-landscape-up {
    .hotline-button--desktop {
        display: flex;
        position: absolute;
        right: $content-padding-desktop;
    }
}

@include for-tablet-portrait-down {
    .hotline-button--mobile {
        display: flex;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -2.7rem;
        height: 5.4rem;
        cursor: pointer;

        .hotline-button__details {
            display: none;
        }
    }

    .hotline-button--active {
        .hotline-button__details {
            display: block;
        }
    }
}
