﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.hero {
    &__item {
        position: relative;
        //special styles for hero__item--white when in desktop mode
        @include for-tablet-landscape-up {
            &--white {
                .hero {
                    &__content-container-outer {
                        top: 0;
                        bottom: 0;
                    }

                    &__content-container-inner {
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }

                    &__content {
                        padding: 0;
                        background-color: transparent;
                    }

                    &__title {
                        color: #fff;
                        font-size: 3.8rem;
                        margin-bottom: 2rem;
                    }

                    &__text {
                        color: #fff;
                        font-size: 1.8rem;
                    }

                    &__cta {
                        color: #fff;

                        &:hover {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    &__image {
        padding-top: (500/1440*100%); //aspect ratio
        position: relative;
        background-size: cover;
        background-position: center center;

        @include for-tablet-landscape-up {
            height: 50rem;
            padding-top: 0;
        }

        &--home {
            padding-top: (600/1440*100%); //aspect ratio
            @include for-tablet-landscape-up {
                height: 60rem;
                padding-top: 0;
            }
        }

        &--slim {
            padding-top: (250/1440*100%); //aspect ratio
            @include for-tablet-landscape-up {
                height: 25rem;
                padding-top: 0;
            }
        }
    }

    &__video {
        object-fit: cover;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    &__content-container-outer {
        @include for-tablet-landscape-up {
            position: absolute;
            top: 6rem;
            left: 0;
            right: 0;
        }
    }

    &__item--white &__content-container-outer {
    }

    &__content-container-inner {
        @include for-tablet-landscape-up {
            margin: 0 auto;
            padding: 0 $content-padding-desktop;
            display: flex;
        }

        @include for-desktop-up {
            max-width: $desktop-min-width;
        }
    }

    &__content-sticker-container {
        position: absolute;
        top: 3vw;
        left: 9vw;

        @include for-tablet-landscape-up {
            position: static;
            top: auto;
            left: auto;
            margin-left: 3rem;
            margin-top: -1rem;
        }

        @include for-desktop-up {
            margin-left: 6rem;
        }
    }

    &__content-sticker {
        width: 26vw;
        transform: rotate(-10deg);

        @include for-tablet-landscape-up {
            width: 21rem;
            transform: rotate(10deg);
        }

        @include for-desktop-up {
            width: 23rem;
        }
    }

    &__content-sticker-container-empty-hero {
        position: absolute;
        top: 1.5rem;
        right: 2vw;

        @include for-tablet-landscape-up {
            top: 8rem;
        }

        @include for-desktop-up {
            right: 7.5vw;
        }
    }

    &__content-sticker-empty-hero {
        width: 26vw;
        transform: rotate(10deg);

        @include for-tablet-landscape-up {
            width: 21rem;
        }

        @include for-desktop-up {
            width: 23rem;
        }
    }

    &__image--slim {
        & + .hero__content-sticker-container-empty-hero {
            top: 1rem;

            @include for-tablet-landscape-up {
                top: 2rem;
            }

            .hero__content-sticker-empty-hero {
                width: 14vw;

                @include for-tablet-landscape-up {
                    width: 18rem;
                }

                @include for-desktop-up {
                    width: 19rem;
                }
            }
        }
    }

    &__content {
        border-style: solid;
        border-color: $grey-230;
        border-width: 2rem 2.6rem;
        background-color: rgba(255,255,255,0.9);
        padding: 1rem;

        @include for-tablet-landscape-up {
            border: none;
            width: 50rem;
            padding: $content-padding-desktop;
        }
    }

    &__title {
        @include font-bold;
        font-size: 2rem;
        color: $migrol-red;
        margin-bottom: 0.5rem;
    }

    &__text {
        font-size: 1.6rem;
        margin-bottom: 0.5rem;
    }

    &__cta {
        color: #000;
        text-decoration: none;
        @include font-bold;
        font-size: 1.6rem;

        &:hover {
            color: $migrol-red;
        }
    }

    &__pager {
        list-style: none;
        display: block;
        text-align: center;
        padding: 0;
        margin: 2rem 0 0;

        @include for-tablet-landscape-up {
            position: absolute;
            bottom: 2rem;
            width: 100%;
        }

        li {
            position: relative;
            display: inline-block;
            margin: 0 0.5rem;
            padding: 0;
            cursor: pointer;

            @include for-tablet-landscape-up {
                margin: 0 0.8rem;
            }

            button {
                border: 0;
                background: $grey-241;
                display: block;
                height: 1.1rem;
                width: 1.1rem;
                border-radius: 50%;
                outline: none;
                line-height: 0;
                font-size: 0;
                color: transparent;
                padding: 5px;
                cursor: pointer;

                @include for-tablet-landscape-up {
                    height: 1.4rem;
                    width: 1.4rem;
                }

                @media(hover:hover) {
                    &:hover, &:focus {
                        outline: none;
                        background-color: $grey-183;

                        @include for-tablet-landscape-up {
                            background-color: #fff;
                        }
                    }
                }
            }

            &.slick-active button {
                background-color: $grey-183;

                @include for-tablet-landscape-up {
                    background-color: #fff;
                }
            }
        }
    }
}

//this is to hide jumping/flickering before the slider is initialized
.hero__item:nth-child(n+2) {
    display: none;
}

.slick-initialized .hero__item:nth-child(n+2) {
    display: block;
}
