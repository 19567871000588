﻿@import "../../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

// Content
$si-content-bg: transparent !default; // Background color of the infowindow
$si-content-padding: 0 !default; // Padding of the infowindow
$si-font-size: 1.4rem !default; // Font size of the infowindow content

// Border
$si-border-width: 2px !default; // Width of the border
$si-border-color: $migrol-red !default; // Color of the border
$si-border-radius: 0 !default; // Border radius of the infowindow

// Pointer
$si-pointer-length: 15px !default; // Length of the pointer, from the edge of the infowindow to the tip

// Shadow
$si-shadow-h: 0 !default; // Shadow horizontal offset
$si-shadow-v: 1px !default; // Shadow vertical offset
$si-shadow-blur: 3px !default; // Shadow blur radius
$si-shadow-spread: 0 !default; // Shadow spread radius
$si-shadow-color: #000 !default; // Shadow color
$si-shadow-opacity: 0.298039 !default; // Shadow opacity

// Close button
$si-close-font-size: 24px !default; // Size of the close button text
$si-close-opacity: 0.5 !default; // Initial opacity of the close button
$si-close-hover-opacity: 0.7 !default; // Hover and focus opacity of the close button
