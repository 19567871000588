﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.teaser-carousel {
    position: relative;
    min-height: 5rem;
    @include element-margin;
    padding: 0 $content-padding-mobile;

    @include for-tablet-landscape-up {
        padding: 0 $content-padding-desktop;
    }

    &__item {
        display: none;

        &:first-child {
            display: block;
        }
    }

    &__items.slick-initialized &__item {
        display: block;
    }

    &__pager {
        list-style: none;
        display: block;
        text-align: center;
        padding: 0;
        margin: 0;
        position: relative;
        bottom: -1rem;
        width: 100%;

        li {
            position: relative;
            display: inline-block;
            height: 30px;
            width: 20px;
            margin: 0 5px;
            padding: 0;
            cursor: pointer;

            button {
                border: 0;
                background: #ccc;
                display: block;
                height: 14px;
                width: 14px;
                border-radius: 50%;
                outline: none;
                line-height: 0px;
                font-size: 0px;
                color: transparent;
                padding: 5px;
                cursor: pointer;

                @media(hover:hover) {
                    &:hover, &:focus {
                        outline: none;
                        background-color: #999 !important;
                    }
                }
            }

            &.slick-active button {
                background-color: #999 !important;
            }
        }
    }
}

//special style for home carousel
.home .teaser-carousel {
    max-width: 1260px;
    margin-left: auto;
    margin-right: auto;
}