﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.marktnews-list {
    @include element-margin;

    &__item {
        border-top: 1px solid $grey-183;
        padding: 2rem 1.5rem;
        margin: 0 -1.5rem;

        &:last-of-type {
            border-bottom: 1px solid $grey-183;
        }

        @include for-tablet-landscape-up {
            display: flex;
            padding: 2rem 10rem;
            margin: 0 -10rem;
        }
    }

    &__visual {
        width: 5.5rem;
        height: 5.5rem;
        margin-bottom: 1.5rem;

        @include for-tablet-landscape-up {
            flex: none;
            width: 16rem;
            height: 16rem;
            margin-right: 3rem;
            margin-bottom: 0;
        }
    }

    &__image {
        &--risingstrongly {
            transform: rotate(-45deg);
            color: $migrol-red;
        }

        &--risingslightly {
            transform: rotate(-10deg);
            color: $migrol-red;
        }

        &--stable {
            color: $grey-127;
        }

        &--decliningslightly {
            transform: rotate(10deg);
            color: $migrol-green;
        }

        &--decliningstrongly {
            transform: rotate(45deg);
            color: $migrol-green;
        }
    }

    &__content {
        @include for-tablet-landscape-up {
            flex: 1;
            max-width: 53rem;
        }
    }

    &__date {
        font-size: 2rem;
        @include font-bold;
        color: $migrol-red;
        margin-bottom: 0.5rem;

        @include for-tablet-landscape-up {
            font-size: 2.2rem;
        }
    }

    &__title {
        font-size: 2rem;
        @include font-normal;
        color: $migrol-red;
        margin-bottom: 1.2rem;
    }

    &__lead {
        font-size: 1.6rem;
        line-height: 2.2rem;
        margin-bottom: 1.2rem;

        @include for-tablet-landscape-up {
            font-size: 1.8rem;
            line-height: 2.4rem;
        }
    }

    &__trend-text {
        @include font-bold;
    }

    &__link {
        font-size: 1.6rem;
        @include font-bold;
        text-decoration: none;
        color: #000;

        &:hover {
            color: $migrol-red;
        }
    }

    &__footer {
        margin-top: 5rem;
        text-align: center;

        @include for-tablet-landscape-up {
            margin-top: 10rem;
        }
    }

    &__button {
        @include font-bold;
        font-size: 1.6rem;
        background-color: $grey-205;
        color: #000;
        text-decoration: none;
        padding: 1rem 3rem;
        display: inline-block;

        &:hover {
            background-color: $grey-183;
        }
    }
}
