﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.section-header {
    @include element-margin;
    position: relative;

    h1 {
        font-size: 2.8rem;

        @include for-tablet-landscape-up {
            font-size: 3.8rem;
        }
    }

    h2 {
        font-size: 2.4rem;

        @include for-tablet-landscape-up {
            font-size: 3.0rem;
        }
    }

    h3 {
        font-size: 2.2rem;

        @include for-tablet-landscape-up {
            font-size: 2.2rem;
        }
    }

    h4, h5, h6 {
        font-size: 2.0rem;

        @include for-tablet-landscape-up {
            font-size: 1.8rem;
        }
    }

    &__title-1 {
        margin-bottom: 2.5rem;
        @include font-bold;

        &--red {
            color: $migrol-red;
        }

        &--black {
            //not used for now, black is default color anyway
        }

        &--blog {
            margin-bottom: 1rem;
        }
    }

    &__title-2 {
        &--block {
            display: block;
        }

        @include font-normal;
    }

    &__lead {
        font-size: 2rem;
        line-height: 2.8rem;

        @include for-tablet-landscape-up {
            font-size: 2.4rem;
            line-height: 3.4rem;
        }
    }

    &--no-margin-bottom {
        margin-bottom: 1rem;

        h1, h2, h3, h4, h5, h6, &__lead {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
