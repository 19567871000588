﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.migrol-blog {
    &__category-filter {
        margin-bottom: 10rem;
        text-align: center;
    }

    &__category-filter-tag {
        @include font-normal;
        color: #000;
        font-size: 1.6rem;
        padding: 0.6em;
        background-color: $grey-241;
        display: inline-block;
        text-decoration: none;
        margin: 0 1rem 2rem 0;

        @include for-tablet-landscape-up {
            font-size: 2rem;
        }

        &:hover {
            color: $migrol-red;
        }

        &--active {
            color: $migrol-red;
            background-color: $grey-205;
        }
    }

    &__navi {
        margin-bottom: 1.5rem;
        display: flex;
        flex-direction: row-reverse;

        @include for-tablet-landscape-up {
            margin-bottom: 3rem;
        }

        &__back-to-list {
            display: inline-block;
            font-size: 1.8rem;
            color: #000;
            text-decoration: none;

            &:hover {
                color: $migrol-red;
            }

            @include for-tablet-landscape-up {
                font-size: 2.2rem;
            }
        }
    }

    &__post-categories {
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: $migrol-red;
        margin-bottom: 2rem;
        @include font-bold;
    }

    &__post-date-author {
        color: #000;
        font-size: 1.6rem;
        line-height: 2.2rem;
        margin-bottom: 5rem;
    }

    &__footer {
        margin-top: 5rem;
        text-align: center;

        @include for-tablet-landscape-up {
            margin-top: 10rem;
        }
    }
}