﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.home-section {

    &__heading {
        margin-bottom: 3rem;

        @include for-tablet-landscape-up {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 5.2rem;
        }
    }

    &__title-1 {
        font-size: 2rem;
        color: #000;
        @include font-bold;

        @include for-tablet-landscape-up {
            font-size: 3.0rem;
        }
    }

    &__title-2 {
        display: block;
        @include font-normal;

        @include for-tablet-landscape-up {
            display: inline;
        }
    }

    &__overview-link {
        display: none;

        @include for-tablet-landscape-up {
            display: inline-block;
            color: #000;
            font-size: 2.2rem;
            text-decoration: none;

            &:hover {
                color: $migrol-red;
            }
        }
    }

    &__arrow {
        display: inline-block;
        width: 5.4rem;
        height: 1.6rem;
        margin-right: 1rem;
    }
}
