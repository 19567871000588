﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

$accordion-transition-duration: 0.5s;

.ts-finder-teaser {
    background: url(/assets/MigrolTankstellensuche/img/bg-ts-finder-teaser.jpg) no-repeat right top;
    background-size: cover;
    min-height: 30rem;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    height: 100%;

    @include for-tablet-landscape-up {
        min-height: 40rem;
        padding: $content-padding-desktop;
    }

    &__content {
        max-width: 60rem;
    }

    &__title-1 {
        font-size: 2.2rem;
        color: $migrol-red;
        @include font-bold;
        margin-bottom: 2rem;
    }

    &__title-2 {
        @include font-normal;
        display: block;
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
        background-color: #fff;
        padding: 0 1.5rem 1.5rem;
    }

    &__radios {
        white-space: nowrap;
        height: 4rem;
        overflow: hidden;
        margin: 1.5rem 0.5rem 0 0;

        @include for-tablet-landscape-up {
            margin-right: 2rem;
        }
    }

    &__radio-label {
        display: inline-block;
        background-color: $grey-230;
        color: #000;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        cursor: pointer;
        margin-right: 0.5rem;

        svg {
            pointer-events: none;
        }
    }

    &__radio {
        display: none;
    }

    &__radio:checked + &__radio-label {
        background-color: $migrol-red;
        color: #fff;
    }

    &__inputs {
        display: flex;
        flex: 1;
        white-space: nowrap;
        margin: 1.5rem 0 0;
        min-width: 20rem;
    }

    &__textbox {
        flex: 1;
        @include font-normal;
        line-height: 3.8rem;
        padding: 0 0.5rem;
        border: solid 1px $grey-183;
        outline: none;
        font-size: 1.6rem;

        @media(max-width: 320px) {
            /*iphone 5 special*/
            font-size: 1.3rem;
            flex: none;
        }

        &::placeholder {
            color: $grey-127;
        }
    }

    &__button {
        @include button-reset;
        @include font-bold;
        line-height: 4rem;
        color: #fff;
        background-color: $migrol-green;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
        font-size: 1.6rem;

        &:hover {
            background-color: $migrol-green-dark;
        }
    }

    &__button-text {
        display: none;
    }
}

.hero-wrapper {
    .ts-finder-teaser {
        background: url(/assets/MigrolTankstellensuche/img/bg-ts-finder-teaser-hero.jpg) no-repeat right top;
        background-size: cover;

        &__content {
            width: 100%;
            margin: 0 auto;

            @include for-desktop-up {
                max-width: $desktop-min-width;
            }
        }

        &__form {
            max-width: 60rem;
        }
    }
}

@include for-tablet-landscape-up {
    .col--large, .col--x-large {
        .ts-finder-teaser {
            padding: $content-padding-desktop 9rem;

            &__title-1 {
                font-size: 2.8rem;
            }

            &__button-text {
                display: inline;
            }

            &__button-icon {
                display: none;
            }
        }
    }

    .col--x-large {
        .ts-finder-teaser {
            &__title-1 {
                font-size: 3.4rem;
            }
        }
    }
}

.contentblock--gray .ts-finder-teaser {
    background-image: url(/assets/MigrolTankstellensuche/img/bg-ts-finder-teaser-light.jpg);
}

//special for ts-finder-teaser on home (at the top, only for mobile)
.layout__mobile-only .ts-finder-teaser {
    margin-bottom: 3rem;
    margin-left: 1.1rem;
    margin-right: 1.1rem;
}
