﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.columns__col .mc-login-teaser {
    min-height: 100%;
}

.mc-login-teaser {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $grey-230;
    padding: 1.5rem;

    @include for-tablet-landscape-up {
        padding: 3rem;
    }

    &__top {
        flex: none;
    }

    &__title {
        @include font-bold;
        font-size: 3.0rem;
        color: $migrol-red;
        margin-bottom: 1.5rem;
    }

    &__text {
        font-size: 1.6rem;
        line-height: 1.33;

        @include for-tablet-landscape-up {
            font-size: 1.8rem;
        }

        .migrol-rt-content {
            font-size: 1.6rem;
            line-height: 1.33;
            margin-bottom: 0;

            @include for-tablet-landscape-up {
                font-size: 1.8rem;
            }
        }
    }

    &__button {
        flex: none;
        background-color: $migrol-green;
        margin: 2rem;

        &:hover {
            background-color: $migrol-green-dark;
        }

        a, .scTextWrapper {
            @include font-bold;
            font-size: 1.6rem;
            display: block;
            padding: 1rem;
            color: #fff;
            text-decoration: none;
            text-align: center;
        }
    }

    &__register {
        flex: none;
        font-size: 1.4rem;
        text-align: center;

        a {
            text-decoration: underline;
            color: #000;
        }
    }
}

.contentblock--gray .mc-login-teaser {
    background-color: #fff;
    //padding: 0; //remove padding, when in a gray container
}

.col--medium, .col--large {
    .mc-login-teaser {
        &__text {
            max-width: 60rem;
        }

        &__button {
            margin-left: auto;
            margin-right: auto;

            a, .scTextWrapper {
                padding: 1rem 10rem;
            }
        }
    }
}
