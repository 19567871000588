﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

$accordion-transition-duration: 0.5s;

.footer-links {
    list-style: none;
    margin: 0;
    padding: 0;

    @include for-tablet-landscape-up {
        display: flex;
        max-width: $desktop-min-width;
        margin: 0 auto;
    }

    &__col {
        padding: 0;
        margin: 0;
        border-top: solid 1px $grey-127;

        @include for-tablet-landscape-up {
            flex: 1;
            border-top: none;
            margin-right: 2rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__label {
        display: table;
        width: 100%;
        @include font-bold;
        font-size: 1.6rem;
        cursor: pointer;
        padding: 0 2rem 0 0.5rem;
        user-select: none;
        position: relative;
        height: 5rem;

        @include for-tablet-landscape-up {
            height: 5.5rem;
            cursor: auto;
            padding: 0;
            font-size: 2rem;
            line-height: normal;
        }


        .svg-inline--fa {
            color: $grey-127;
            position: absolute;
            right: 0rem;
            top: 50%;
            margin-top: -7px;
            font-size: 1.4rem;
            transform: rotate(0deg);
            float: right;
            transition: transform $accordion-transition-duration;

            @include for-tablet-landscape-up {
                display: none;
            }
        }
    }

    &__label-text {
        display: table-cell;
        vertical-align: middle;

        @include for-tablet-landscape-up {
            display: block;
            vertical-align: top;
        }
    }

    &__input {
        display: none;
    }

    &__content-container {
        max-height: 0;
        transition: max-height $accordion-transition-duration cubic-bezier(0.075, 1.060, 0.180, 0.965);
        overflow: hidden;

        @include for-tablet-landscape-up {
            max-height: none;
            overflow: auto;
            transition: none;
        }
    }

    &__content {
        padding-left: 0.5rem;
        font-size: 1.6rem;

        @include for-tablet-landscape-up {
            padding-left: 0;
            font-size: 1.4rem;
        }

        p {
            margin: 0 0 2rem 0;
            padding: 0;
        }
    }

    &__content-red {
        color: $migrol-red;
    }

    &__no-underline {
        text-decoration: none;
    }

    &__link {
        display: block;
        color: #000;
        text-decoration: none;
        padding-bottom: 1rem;

        @include for-tablet-landscape-up {
            padding-bottom: 1rem;
        }

        &:hover {
            color: $migrol-red;
        }
    }
    //open state (a bit hacky...)
    &__input:checked + &__label {
        .svg-inline--fa {
            transform: rotate(180deg);
            transition: transform $accordion-transition-duration;
        }
    }

    &__input:checked ~ &__content-container {
        max-height: 2000px;
        transition: max-height $accordion-transition-duration cubic-bezier(0.885, 0.000, 1.000, 0.065);

        @include for-tablet-landscape-up {
            max-height: none;
            transition: none;
        }
    }
}
