﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

$overlay-width: 37rem;
$overlay-margin: 2rem;

@mixin special-icon-aspect-ratio($height, $baseClassName: '') {
    //by default the icons are square, but there can be exceptions (fast charging atm, where there is a different aspect ratio)

    &#{$baseClassName}-fastcharging {
        width: $height * 1.5; //1:1.5 aspect ratio
    }
}

html, body {
  overscroll-behavior-y: none;
}

/*#region desktop styles*/
.ts-finder {
    @import 'snazzy-info-window/snazzy-info-window';
    position: relative;
    @include font-normal;
    font-size: 1.8rem;

    &__loading-overlay {
        //overlay when the app is busy pulling data form the server
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(#eee,0.8);
        color: #777;
        @include font-bold;
        font-size: 2rem;
        z-index: 2;
        text-align: center;
    }

    &__loading-logo {
        display: inline-block;
        animation-name: jump;
        animation-timing-function: ease-in;
        animation-iteration-count: infinite;
        animation-duration: 0.4s;
        animation-direction: alternate;
        margin-bottom: 0.5rem;
    }

    @keyframes jump {
        from {
            transform: translateY(-20px);
        }

        to {
            transform: rotateY(0);
        }
    }

    &__map {
        width: 100%;
        height: 80vh;
        height: 80dvh;
    }

    &__layout-mobile-infowindow {
        //hide mobile infowindow
        display: none;
    }

    &__infowindow {
        background-color: #fff;
        border: solid 1px $migrol-green;
        padding: 2rem;
        margin-top: 12px;
        margin-bottom: 16px;
        position: relative;
        width: 417px;
        //this is the triangle on the bottom of the infowindow
        &:before, &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-color: transparent;
            border-bottom: 0;
        }
        /* Stroke */
        &:before {
            bottom: -16px;
            left: calc(50% - 16px);
            border-top-color: $migrol-green;
            border-width: 16px;
        }
        /* Fill */
        &:after {
            bottom: -15px;
            left: calc(50% - 15px);
            border-top-color: #FFF;
            border-width: 15px;
        }

        &--with-promobanner {
            margin-top: 0;

            .ts-finder__infowindow-station-details-container {
                margin-top: 1rem;
            }

            .ts-finder__infowindow-directions {
                top: -30px;
                right: 1rem;
            }
        }

        &-promobanner {
            width: 100%;
            height: auto;
        }

        &-station-details-container {
            position: relative;
            display: flex;
        }

        &-directions {
            position: absolute;
            top: calc(-2rem - 12px);
            right: 0;
            text-align: center;
            font-size: 1.2rem;
            color: $grey-183;
        }

        &-directions-link {
            display: inline-block;
            width: 40px;
            height: 40px;
            padding: 8px;
            border-radius: 50%;
            background-color: $migrol-green;
            margin-bottom: 0.6rem;
            color: #fff;
            font-size: 2rem;
        }

        &-station-brand {
            width: 4rem;
            flex: none;
            height: auto;
            margin-right: 1.5rem;
            align-self: flex-start;
        }

        &-station-details {
            flex: 1;
            align-self: flex-start;
            margin-right: 1.5rem;
        }

        &-station-name {
            @include font-bold;
            color: #000;
            font-size: 1.8rem;
            margin-bottom: 0.3rem;
        }

        &-station-address {
            color: $grey-127;
            font-size: 1.4rem;
            margin-bottom: 1rem;
        }

        &-station-zipcity {
            white-space: nowrap;
        }

        &-temp-closed {
            color: $migrol-red;
            font-size: 1.4rem;
        }

        &-services-list {
            margin: 0;
            padding: 0;

            li {
                height: 2.5rem;
                width: 2.5rem;
                display: inline-block;
                margin-right: 0.9rem;

                @include special-icon-aspect-ratio(2.5rem, '.ts-finder__icons');

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &-details-link {
            flex: none;
            align-self: flex-end;
            font-size: 1.6rem;
            @include font-bold;

            a {
                color: $migrol-green;
                text-decoration: none;

                &:hover {
                    color: $migrol-green-dark;
                }
            }
        }
    }

    &__details {
        &--desktop-only {
            display: block;
        }

        &--mobile-only {
            display: none;
        }

        &-map {
            height: 600px;
        }

        &-content {
            margin-top: -300px;
        }

        &-content-inner {
            background-color: #fff;
            padding-top: 3rem;
            padding-bottom: 3rem;
            position: relative;
        }

        &-directions {
            position: absolute;
            top: -12px;
            right: 8rem;
            text-align: center;
            font-size: 1.2rem;
            color: $grey-183;
        }

        &-back {
            border: 2px solid $migrol-green;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            position: absolute;
            top: -12px;
            right: 3rem;
            color: $migrol-green;
            font-size: 2.2rem;
            line-height: 3.4rem;
            background-color: #fff;
            text-align: center;

            &:hover {
                border-color: $migrol-green-dark;
                color: $migrol-green-dark;
            }

            .fa-times {
                margin-left: 1px;
            }
        }

        &-directions-link {
            display: inline-block;
            width: 40px;
            height: 40px;
            padding: 8px;
            border-radius: 50%;
            background-color: $migrol-green;
            margin-bottom: 0.6rem;
            color: #fff;
            font-size: 2rem;
        }


        &-header {
            margin: 0 -3rem 3rem -3rem;
            padding: 0 3rem 3rem 3rem;
            border-bottom: solid 3px $migrol-red;
        }

        &-columns {
            display: flex;

            &--middle {
                align-items: center;
            }
        }

        &-left {
            flex: 2;

            &--tablet-same-size {

                flex: 1;

                @include for-desktop-up {
                    flex: 2;
                }
            }
        }

        &-right {
            flex: 1;
        }

        &-full-width {
            flex: none;
        }

        &-logo {
            flex: none;
            margin-right: 4rem;
        }

        &-address {
            font-size: 2.0rem;
            flex: 1;
        }

        &-station-name {
            @include font-bold;
        }

        &-station-address {
        }

        &-station-phone {
            a {
                color: #000;
                text-decoration: underline;
            }
        }

        &-services {
            font-size: 2rem;
            @include font-bold;
        }

        &-services-list {
            list-style: none;
            margin: 1rem 0 0 0;
            padding: 0;

            li {
                display: inline-block;
                margin-right: 1rem;                
            }
        }

        &-service {
            padding: 2rem 0;
            border-bottom: 1px solid $grey-205;
        }

        &-servicetitle {
            @include font-bold;
            font-size: 3.2rem;
            text-transform: uppercase;
            margin-bottom: 3rem;
        }

        /* this is */
        &-autoservice-rte {
            ul {
                columns: 1;

                @include for-tablet-portrait-up {
                    columns: 2;
                    column-gap: 7rem;
                }

                @include for-desktop-up {
                    columns: 3;
                    column-gap: 5rem;
                }
            }
        }

        &-hours {
            font-size: 2rem;
            margin-bottom: 3rem;

            strong {
                @include font-bold;
                display: block;
                margin-bottom: 0.5rem;
            }
        }

        &-migrol-shell-info {
            margin-bottom: 3rem;
        }

        &-capabilities {
            font-size: 2rem;
            margin: 0 0 3rem 0;
            list-style-position: inside;

            &--columns {
                display: flex;
            }

            &--fixed-height li {
                height: 40px;
                line-height: 40px;
            }

            &-left {
                flex: 1;
                margin-right: 2rem;
            }

            &-right {
                flex: 2;
            }

            &-column {
                margin-bottom: 2rem;

                &:not(:last-child) {
                    margin-right: 5rem;
                }
            }
        }

        &-capabilities-with-icons {
            list-style-type: none;
            margin: 0;
            padding: 0;

            &--cols {
                height: 200px;
                column-count: 2;
                column-fill: auto;
            }
        }

        &-capability-icon-row {
            display: flex;
            align-items: center;
        }

        &-capability-icon {
            width: 40px;
            height: 40px;
            background-size: 80%;
            background-position: center center;
            background-repeat: no-repeat;
            flex: none;
            margin-right: 10px;
            image-rendering: pixelated;
            image-rendering: -moz-crisp-edges;
            image-rendering: -webkit-optimize-contrast;
            image-rendering: crisp-edges;

            &-adblue-can {
                background-image: url('/assets/MigrolTankstellensuche/img/ico_service_adblue_can.png');
            }

            &-adblue-pump {
                background-image: url('/assets/MigrolTankstellensuche/img/ico_service_adblue_pump.png');
            }

            &-carwash-sb {
                background-image: url('/assets/MigrolTankstellensuche/img/ico_service_carwash_sb.png');
            }

            &-carwash-sb-sauger {
                background-image: url('/assets/MigrolTankstellensuche/img/ico_service_carwash_sb_sauger.png');
            }

            &-carwash-waschanlage {
                background-image: url('/assets/MigrolTankstellensuche/img/ico_service_carwash_waschanlage.png');
            }

            &-carwash-waschstrasse {
                background-image: url('/assets/MigrolTankstellensuche/img/ico_service_carwash_waschstrasse.png');
            }

            &-carwash-plus {
                background-image: url('/assets/MigrolTankstellensuche/img/ico_service_carwash_plus.png');
            }

            &-carwash-innenreinigung {
                background-image: url('/assets/MigrolTankstellensuche/img/ico_service_carwash_innenreinigung.png');
            }

            &-erdgas {
                background-image: url('/assets/MigrolTankstellensuche/img/ico_service_erdgas.png');
            }

            &-lkw130 {
                background-image: url('/assets/MigrolTankstellensuche/img/ico_service_lkw_130l.png');
            }

            &-lkw70 {
                background-image: url('/assets/MigrolTankstellensuche/img/ico_service_lkw_70l.png');
            }

            &-lkw {
                background-image: url('/assets/MigrolTankstellensuche/img/ico_service_lkw.png');
            }

            &-lpggpl {
                background-image: url('/assets/MigrolTankstellensuche/img/ico_service_lpg-gpl.png');
            }

            &-fastcharging-CCS {
                background-image: url('/assets/MigrolTankstellensuche/img/ico_service_fastcharging_CCS.png');
            }

            &-fastcharging-CHAdeMO {
                background-image: url('/assets/MigrolTankstellensuche/img/ico_service_fastcharging_CHAdeMO.png');
            }

            &-fastcharging-ACTyp2 {
                background-image: url('/assets/MigrolTankstellensuche/img/ico_service_fastcharging_ACTyp2.png');
            }

            &-wasserstoff {
                background-image: url('/assets/MigrolTankstellensuche/img/ico_service_wasserstoff.png');
            }
        }

        &-more-infos {
            margin-bottom: 1rem;
        }

        &-more-infos-link {
            @include font-bold;
            text-decoration: none;
            color: #000;

            &:hover {
                color: $migrol-red;
            }
        }

        &-promo {
            font-size: 2rem;

            img {
                display: block;
                width: 100%;
                max-width: 100%;
                height: auto;
                margin-bottom: 0.5rem;
            }

            a {
                @include font-bold;
                color: #000;
                text-decoration: none;
                display: block;

                &:hover {
                    color: $migrol-red;
                }
            }

            .fa-angle-right {
                margin-left: .2rem;
                position: relative;
                top: 1px;
            }
        }

        &-description-toggle {
            @include button-reset;
            @include font-bold;
            color: #000;
            font-size: 2rem;

            &:hover {
                color: $migrol-red;
            }

            .fa-angle-right {
                margin-left: .2rem;
                position: relative;
                top: 1px;
            }
        }

        &-warning {
            font-size: 1.6rem;
            color: $migrol-red;
            margin-bottom: 3rem;
        }

        &-paymentmethods {
            list-style-type: none;
            margin: 0;
            padding: 0;
            columns: 4;
            column-gap: 3rem;

            li {
                display: inline-block;
                width: 100%;
                margin-bottom: 10px;
            }
        }

        &-payment-icon-row {
            display: flex;
            align-items: center;
        }

        &-payment-icon {
            width: 40px;
            height: 40px;
            background-size: 80%;
            background-position: center center;
            background-repeat: no-repeat;
            flex: none;
            margin-right: 10px;
            image-rendering: pixelated;
            image-rendering: -moz-crisp-edges;
            image-rendering: -webkit-optimize-contrast;
            image-rendering: crisp-edges;

            &-migrolcard {
                background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_migrolcard.png);
            }

            &-visa {
                background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_visa.png);
            }

            &-m-card {
                background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_m-card.png);
            }

            &-euroshell {
                background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_euroshell.jpg);
            }

            &-amex {
                background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_amex.png);
            }

            &-myone {
                background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_myone.png);
            }

            &-maestro {
                background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_maestro.png);
            }

            &-postfinance {
                background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_postfinance.png);
            }

            &-migrol-geschenkkarte {
                background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_migrol-geschenkkarte.png);
            }

            &-mastercard {
                background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_mastercard.png);
            }

            &-v-pay {
                background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_v-pay.png);
            }

            &-migrol-carwashcard {
                background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_carwashcard.png);
            }

            &-twint {
                background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_twint.png);
            }

            &-diners {
                background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_diners.png);
            }

            &-atm {
                background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_atm.png);
            }

            &-cash {
                background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_cash.png);
            }

            &-autosense {
                background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_autosense.png);
            }

            &-migrolcard-app {
                background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_migrolcard-app.svg);
            }
        }

        &-payment-icon-legend {
            flex: 1;
        }

        &-migrolcard-info {
            display: flex;
            margin-right: 4rem;

            &-image {
                margin-right: 4rem;
                flex: 2;

                img {
                    max-width: 100%;
                    height: auto;
                }
            }

            &-text {
                flex: 5;
                margin-bottom: 2rem;
            }
        }

        &-cumulus-info {
            display: flex;

            &-image {
                margin-right: 4rem;
                flex: 1;
                align-self: center;

                img {
                    max-width: 100%;
                    height: auto;
                }
            }

            &-text {
                flex: 5;
                align-self: center;
            }
        }

        &-mcharge-title {
            margin-bottom: 3rem;

            img {
                width: 400px;
            }
        }
    }

    &__icon {
        height: 35px;
        width: 35px;
        background-size: 98%;
        background-position: center bottom;
        background-repeat: no-repeat;
    }

    &__icons {
        &-tankstelle {
            background-image: url(/assets/MigrolTankstellensuche/img/ico_tankstelle.svg);
        }

        &-fastcharging {
            background-image: url(/assets/MigrolTankstellensuche/img/ico_fastcharging.svg);
        }

        &-carwash {
            background-image: url(/assets/MigrolTankstellensuche/img/ico_carwash.svg);
        }

        &-shop {
            background-image: url(/assets/MigrolTankstellensuche/img/ico_shop.svg);
        }

        &-migrolino {
            background-image: url(/assets/MigrolTankstellensuche/img/ico_migrolino.svg);
        }

        &-mio {
            background-image: url(/assets/MigrolTankstellensuche/img/ico_mio_by_migrolino.svg);
        }

        &-autoservice {
            background-image: url(/assets/MigrolTankstellensuche/img/ico_autoservice.svg);
        }

        &-migrolcard {
            background-image: url(/assets/MigrolTankstellensuche/img/ico_migrolcard_akzeptanzstelle.svg);
        }

        &-wasserstoff {
            background-image: url(/assets/MigrolTankstellensuche/img/ico_wasserstoff.svg);
        }

        &-hasWC {
            background-image: url(/assets/MigrolTankstellensuche/img/ico_hasWC.png);
            background-size: 100%;
        }

        @include special-icon-aspect-ratio(35px);
    }

    &__searchbox {
        position: absolute;
        top: $overlay-margin;
        left: $overlay-margin;
        max-height: calc(80vh - #{2 * $overlay-margin});
        max-height: calc(80dvh - #{2 * $overlay-margin});
        width: $overlay-width;
        background-color: #FFF;
        z-index: 1;
        font-size: 1.6rem;
        display: flex;
        flex-direction: column;

        &-top {
            padding: 2rem;
            flex: none;
        }

        &-resultlist {
            flex: 1;
            overflow-y: auto;
            overflow-x: hidden;
        }

        &-button-base {
            @include button-reset;
            @include font-bold;
            color: #fff;
            background-color: $migrol-green;
            text-align: center;
            height: 40px;

            &:hover {
                background-color: $migrol-green-dark;
            }

            &--inverted {
                @include font-normal;
                color: $migrol-green;
                border: 2px solid $migrol-green;
                background-color: #fff;

                &:hover {
                    background-color: $grey-241;
                }
            }
        }

        &-fulltext {
            display: flex;
            margin-bottom: 1rem;
            position: relative;
        }

        &-locateme {
            flex: none;
            width: 40px;
        }

        &-textfield {
            flex: 1;
            @include font-normal;
            line-height: 40px;
            height: 40px;
            padding: 0 .5rem;
            border: 1px solid $grey-205;
            outline: none;
            font-size: 1.6rem;
        }

        &-search-icon {
            position: absolute;
            top: 0.9rem;
            right: 1rem;
            font-size: 2rem;
            color: $grey-205;
        }

        &-close-search {
            position: absolute;
            right: 0;
            top: 0;
            height: 40px;
            width: 40px;
        }

        &-filter-reset {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 1.5rem;

            &-button {
                @include button-reset;
                font-size: 1.6rem;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &-filters {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 2rem;
        }

        &-toggle-filters {
            padding: 0 1rem;
            margin-left: 2rem;
        }

        &-checkbox-label {
            display: inline-block;
            background-color: $grey-230;
            color: #000;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            cursor: pointer;

            &:hover {
                background-color: $grey-205;
            }

            svg {
                //optimization for tooltips (so the SVG does not delay the showing of the tooltip)
                pointer-events: none;
            }
        }

        &-checkbox {
            display: none;
        }

        &-checkbox:checked + &-checkbox-label {
            background-color: $migrol-red;
            color: #fff;
        }

        &-tags {
            margin-bottom: 2rem;
        }

        &-tag {
            @include button-reset;
            background-color: $grey-241;
            height: 30px;
            line-height: 30px;
            border-radius: 12px;
            padding: 0 1rem;
            margin-right: 0.5rem;
            margin-bottom: 0.5rem;

            &:hover {
                background-color: $grey-230;
            }
        }

        &-resultlist-toggle {
            @include button-reset;
            @include font-bold;
            width: 100%;
            text-align: center;
            user-select: none;
            height: 4rem;
            background-color: $grey-230;
            color: #000;

            &:hover {
                background-color: $grey-205;
            }

            .resultlist-icon {
                transform: rotate(0deg);
                transition: transform ease-in-out 0.2s;
            }

            &.active .resultlist-icon {
                transform: rotate(-180deg);
                transition: transform ease-in-out 0.2s;
            }
        }
    }

    &__resultlist {
        border-top: 1px solid $grey-205;

        .ts-finder__pdf-button {
            margin: 2rem;
        }

        &-item {
            border-bottom: 1px solid $grey-205;
            cursor: pointer;
            display: flex;

            &--station {
                padding: 2rem 1rem 2rem 2rem;
            }

            &--station-search, &--place {
                padding: 1rem;
            }

            &:hover, &--selected {
                background-color: $grey-241;
            }
        }

        &-show-more {
            @include button-reset;
            margin: 2rem;
            width: 100%;
            text-align: center;
            font-size: 1.8rem;
        }

        &-logo {
            flex: none;
            margin-right: 1rem;
            width: 4rem;
            align-self: flex-start;
        }

        &-place-icon {
            flex: none;
            margin-right: 1rem;
            width: 4rem;
            align-self: flex-start;
            text-align: center;
            font-size: 3rem;
            color: $grey-183;
        }

        &-details {
            flex: 1;
            align-self: center;
        }

        &-name {
            @include font-bold;
            font-size: 1.8rem;
            margin-bottom: 0.5rem;
        }

        &-place-name {
            @include font-bold;
            margin-bottom: 0.5rem;
        }

        &-address {
            color: $grey-127;
            font-size: 1.4rem;
            margin-bottom: 1rem;
        }

        &-services-list {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                height: 1.8rem;
                width: 1.8rem;
                margin-right: 0.9rem;
                display: inline-block;

                @include special-icon-aspect-ratio(1.8rem, '.ts-finder__icons');
            }
        }

        &-directions {
            flex: none;
            margin-left: 1rem;
            text-align: center;
            font-size: 1.2rem;
            color: #b7b7b7;
            width: 5rem;
        }

        &-directions-link {
            display: inline-block;
            width: 40px;
            height: 40px;
            padding: 8px;
            border-radius: 50%;
            background-color: $migrol-green;
            margin-bottom: .6rem;
            color: #fff;
            font-size: 2rem;
        }
    }

    &__filterpanel {
        position: absolute;
        top: $overlay-margin;
        left: $overlay-margin;
        max-height: calc(80vh - #{2 * $overlay-margin});
        max-height: calc(80dvh - #{2 * $overlay-margin});
        width: $overlay-width;
        background-color: #FFF;
        z-index: 1;
        display: flex;
        flex-direction: column;

        &-top {
            flex: none;
        }

        &-mid {
            flex: 1;
            overflow-y: auto;
        }

        &-bottom {
            flex: none;
            padding: 2rem;
            margin-top: 1rem;
            border-top: solid 1px $grey-205;
        }

        &-filter-count {
            font-size: 1.2rem;
            color: $grey-127;
            @include font-normal;
        }

        &-title {
            @include font-bold;
            font-size: 1.8rem;
            padding: 2rem 5rem 2rem 2rem;
            text-align: center;
        }

        &-close {
            position: absolute;
            top: 1rem;
            right: 1rem;

            @include button-reset;
            background-color: $grey-205;
            color: #000;
            width: 3rem;
            height: 3rem;
            text-align: center;
            line-height: 3rem;
            border-radius: 50%;

            &:hover {
                background-color: $grey-183;
            }
        }

        &-tabs {
            border-bottom: 1px solid $grey-205;
            padding: 0 1rem 0 2rem;
            display: flex;
        }

        &-tab {
            flex: 1;
            border: solid 1px $grey-205;
            font-size: 1.6rem;
            margin-left: 0.5rem;
            margin-bottom: -1px; /*so bottom border of tab and tabs collapse*/
            cursor: pointer;
            position: relative;
            padding: 0.4rem 4rem 0.4rem 0.4rem;
            user-select: none;
            background-color: $grey-230;
            hyphens: none;
            display: flex;
            align-items: center;

            &:first-child {
                margin-left: 0;
            }

            &.active {
                border-bottom: solid 1px #fff;
                background-color: #fff;
                @include font-bold;
            }
        }

        &-tab-count {
            position: absolute;
            right: 0.4rem;
            line-height: 2rem;
            top: calc(50% - 1rem);
        }

        &-show-stations {
            @include button-reset;
            @include font-bold;
            background-color: $migrol-green;
            color: #fff;
            text-align: center;
            width: 100%;
            font-size: 2rem;
            padding: 1rem 2rem;
            margin-bottom: 1rem;

            &:hover {
                background-color: $migrol-green-dark;
            }
        }

        .ts-finder__pdf-button {
            margin-bottom: 2rem;
        }

        &-reset {
            @include button-reset;
            width: 100%;
            font-size: 1.6rem;
            text-align: center;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__capability-filter {
        font-size: 1.6rem;

        &-list {
            list-style: none;
            padding: 0;
            margin: 0;

            &--level-1 {
                margin-top: 1rem;
            }

            &--level-2 {
                display: none; //level 2 is hidden by default
            }
        }

        &-item {
            position: relative;

            &--level-1 {
                @include font-bold;
                border-bottom: solid 1px $grey-205;
                padding: 1rem 2rem;

                &:first-child {
                    border-top: none;
                    margin-top: 0;
                }
            }

            &--level-2 {
                @include font-normal;
                margin-top: 10px;
                margin-left: 25px;
            }
        }

        &-list-toggle {
            @include button-reset;
            position: absolute;
            top: 1rem;
            right: 1rem;
            width: 2rem;
            height: 2rem;
            text-align: center;

            &-icon {
                transform: rotate(0deg);
                transition: transform ease-in-out 0.2s;
            }

            &.active &-icon {
                transform: rotate(180deg);
                transition: transform ease-in-out 0.2s;
            }
        }
    }

    &__canton-filter {
        font-size: 1.6rem;

        &-list {
            list-style: none;
            padding: 0;
            margin: 0;

            &--level-1 {
            }

            &--level-2 {
            }
        }

        &-item {
            position: relative;

            &--level-1 {
                @include font-bold;
                border-bottom: solid 1px $grey-205;
                padding: 1rem 2rem;

                &:first-child {
                    border-top: none;
                    margin-top: 0;
                }
            }

            &--level-2 {
                @include font-normal;
                margin-top: 10px;
                margin-left: 25px;
            }
        }

        &-list-toggle {
            @include button-reset;
            position: relative;
            @include font-bold;
            margin-top: 2rem;
            margin-bottom: 1rem;
            padding: 0 2rem;
            width: 100%;
            user-select: none;

            &-icon {
                text-align: center;
                width: 2rem;
                position: absolute;
                top: -2px;
                right: 1rem;
                transform: rotate(0deg);
                transition: transform ease-in-out 0.2s;
            }

            &.active &-icon {
                transform: rotate(180deg);
                transition: transform ease-in-out 0.2s;
            }
        }
    }

    &__checkbox {
        position: relative;

        input {
            position: absolute;
            left: -10000px;
            top: 0;
            width: 1px;
            height: 1px;
            overflow: hidden;
        }

        label {
            position: relative;
            cursor: pointer;
            user-select: none;

            &::before, &::after {
                position: absolute;
            }
        }

        &--checkbox {
            label {
                margin-left: 25px;
            }

            label::before {
                //this is the box
                content: "";
                display: block;
                height: 18px;
                width: 18px;
                border: 1px solid;
                top: 3px;
                left: -25px;
            }

            label::after {
                //this is the tickmark
                content: "";
                display: none;
            }

            input:checked + label::after /*, input.semi-checked:checked + label::after*/ {
                display: block; //show tickmark when checked
                height: 7px;
                width: 11px;
                border-left: 3px solid $migrol-green;
                border-bottom: 3px solid $migrol-green;
                background-color: transparent;
                transform: rotate(-45deg);
                left: -21px;
                top: 7px;
            }

            input.semi-checked + label::after {
                display: block; //show tickmark when semi-checked
                height: 12px;
                width: 12px;
                background-color: $migrol-green;
                border-left: none;
                border-bottom: none;
                transform: rotate(0);
                left: -22px;
                top: 6px;
            }

            input:disabled {
                & + label {
                    color: $grey-205;
                }

                & + label::before {
                    background-color: $grey-230;
                }

                & + label::after {
                    display: none;
                }
            }
        }

        &--switch {
            label {
                display: block;
            }

            label::before {
                //this is the switch-bg
                content: "";
                display: block;
                height: 22px;
                width: 38px;
                border-radius: 11px;
                background-color: $grey-205;
                top: -4px;
                right: 0;
            }

            label::after {
                //this is the switch-"button"
                content: "";
                position: absolute;
                top: -2px;
                right: 18px;
                width: 18px;
                height: 18px;
                background: #fff;
                border-radius: 9px;
                transition: .3s;
                background-color: #fff;
            }

            input:checked {
                & + label::before {
                    background: $migrol-green;
                }

                & + label::after {
                    right: -16px;
                    transform: translateX(-100%);
                }
            }

            input:disabled {
                & + label {
                    color: $grey-205;
                }

                & + label::after {
                    background-color: $grey-230;
                }
            }
        }
    }

    &__hours-table {
        .today {
            color: $migrol-green;
            @include font-bold;
        }

        td {
            padding: 0 0.3rem 0.3rem;

            &.dayname {
                padding-left: 0;
                padding-right: 2rem;
            }
        }
    }

    &__pdf-button {
        display: block;
        text-decoration: none;
        background-color: #fff;
        border: 2px solid $migrol-green;
        @include font-normal;
        color: $migrol-green;
        text-align: center;
        padding: 1rem;

        &:hover {
            background-color: $grey-241;
        }

        &-active {
            display: none;
        }
    }
}
/*#endregion*/

/*#region mobile styles*/
@include for-tablet-portrait-down {
    .ts-finder-body {
        .layout__page-start, .breadcrumbs, .layout__footer {
            //hide breadcrumbs at the top and the whole footer on mobile
            display: none !important;
        }
    }

    .gm-bundled-control {
        //hide map controls on mobile
        display: none !important;
    }

    .ts-finder {
        &__searchbox {
            top: 0;
            left: 0;
            right: 0;
            width: auto;
            max-height: calc(100vh - 60px);
            max-height: calc(100dvh - 60px);

            &-top {
                padding: 1rem;
            }

            &-filters {
                margin-bottom: 1rem;
            }

            &-filter-reset {
                display: none; //WWW-2090
            }
        }

        &__resultlist {
            &-pdf {
                margin: 1rem;
            }

            &-name {
                font-size: 1.4rem;
            }

            &-address {
                font-size: 1.4rem;
                margin-bottom: 0.5rem;
            }

            &-item {
                &--station, &--station-search {
                    padding: 1rem 1rem 0.5rem 1rem;
                }
            }
        }

        &__filterpanel {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            max-height: unset;
            width: unset;            

            &-reset {
                display: none; //WWW-2090
            }
        }

        &__map {
            height: calc(100vh - 60px);
            height: calc(100dvh - 60px);
        }

        &__map &__infowindow {
            //always hide infowindow inside of map (there is a sticky one at the bottom)
            display: none !important;
        }

        &__layout-mobile-infowindow {
            display: block;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
        }

        &__layout-mobile-infowindow &__infowindow {
            padding: 2rem;
            width: 100%;
            margin: 0;
            border: 0;

            &::before, &::after {
                display: none;
            }

            &-station-name {
                font-size: 1.4rem;
            }
        }

        &__infowindow--with-promobanner {
            .ts-finder__infowindow-station-details-container {
                margin-top: 1rem;
            }
        }

        &__icon {
            width: 25px;
            height: 25px;
        }

        &__details {
            &--desktop-only {
                display: none;
            }

            &--mobile-only {
                display: block;
            }

            &-columns {
                display: block;
            }

            &-left {
                margin-bottom: 2rem;
            }

            &-map {
                height: 150px;
            }

            &-header {
                margin: 0 -1rem;
                padding: 0 1rem 2rem;
            }

            &-content {
                margin-top: 0;
            }

            &-content-inner {
                padding: 1rem;
            }

            &-directions {
                right: 1rem;
                top: 1rem;
            }

            &-back {
                top: -140px;
                right: 1rem;
            }

            &-logo {
                display: none;
            }

            &-address {
                font-size: 1.6rem;
                margin-right: 5.5rem;
            }

            &-services {
                font-size: 1.6rem;
            }

            &-services-list {
                li {
                    width: 1.8rem;

                    @include special-icon-aspect-ratio(1.8rem, '.ts-finder__icons');
                }
            }

            &-hours {
                font-size: 1.6rem;
            }

            &-capabilities {
                font-size: 1.6rem;

                &--fixed-height li {
                    height: unset;
                    line-height: unset;
                }

                &--columns {
                    display: block;
                }
            }

            &-capabilities-with-icons {

                &--cols {
                    height: auto;
                    column-count: unset;
                    column-fill: unset;
                }
            }

            &-promo {
                font-size: 1.6rem;
            }

            &-servicetitle {
                font-size: 2.4rem;
                margin-bottom: 1.2rem;

                img {
                    max-width: 90%;
                }
            }

            &-paymentmethods {
                columns: unset;
            }

            &-migrolcard-info {
                display: block;

                &-image {
                    margin: 2rem;
                    flex: unset;
                }

                &-text {
                    flex: unset;
                }
            }
        }
    }
}
/*#endregion*/
