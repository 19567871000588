@charset "UTF-8";
.migrol-blog__category-filter {
  margin-bottom: 10rem;
  text-align: center; }

.migrol-blog__category-filter-tag {
  font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #000;
  font-size: 1.6rem;
  padding: 0.6em;
  background-color: #f1f1f1;
  display: inline-block;
  text-decoration: none;
  margin: 0 1rem 2rem 0; }
  @media (min-width: 900px) {
    .migrol-blog__category-filter-tag {
      font-size: 2rem; } }
  .migrol-blog__category-filter-tag:hover {
    color: #a4091e; }
  .migrol-blog__category-filter-tag--active {
    color: #a4091e;
    background-color: #cdcdcd; }

.migrol-blog__navi {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row-reverse; }
  @media (min-width: 900px) {
    .migrol-blog__navi {
      margin-bottom: 3rem; } }
  .migrol-blog__navi__back-to-list {
    display: inline-block;
    font-size: 1.8rem;
    color: #000;
    text-decoration: none; }
    .migrol-blog__navi__back-to-list:hover {
      color: #a4091e; }
    @media (min-width: 900px) {
      .migrol-blog__navi__back-to-list {
        font-size: 2.2rem; } }

.migrol-blog__post-categories {
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #a4091e;
  margin-bottom: 2rem;
  font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal; }

.migrol-blog__post-date-author {
  color: #000;
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-bottom: 5rem; }

.migrol-blog__footer {
  margin-top: 5rem;
  text-align: center; }
  @media (min-width: 900px) {
    .migrol-blog__footer {
      margin-top: 10rem; } }

.blog-post-teaser {
  height: 100%;
  min-height: 30rem;
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer; }
  .blog-post-teaser:hover .blog-post-teaser__link {
    color: #a4091e; }
  @media (min-width: 900px) {
    .blog-post-teaser {
      min-height: 40rem; } }
  .blog-post-teaser__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
    transform: scale(1, 1);
    transition: transform 0.5s ease-out;
    z-index: -1; }
  .blog-post-teaser:hover .blog-post-teaser__background {
    transition: transform 0.5s ease-out;
    transform: scale(1.03, 1.03); }
  .blog-post-teaser__content {
    display: flex;
    flex-direction: column;
    min-height: 14rem;
    max-width: 55.4rem;
    margin: 1.5rem;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.9); }
    @media (min-width: 900px) {
      .blog-post-teaser__content {
        margin: 2rem;
        padding: 2rem; } }
  .blog-post-teaser__title-1 {
    color: #a4091e;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 2rem;
    margin-bottom: 0.5rem; }
    @media (min-width: 900px) {
      .blog-post-teaser__title-1 {
        font-size: 2.2rem; } }
  .blog-post-teaser__title-2 {
    font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    display: block; }
    .blog-post-teaser__title-2::first-letter {
      text-transform: uppercase; }
  .blog-post-teaser__copy {
    color: #000;
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-bottom: 1rem; }
    @media (min-width: 900px) {
      .blog-post-teaser__copy {
        font-size: 1.8rem; } }
  .blog-post-teaser__link {
    color: #000;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.6rem;
    margin-top: auto;
    text-decoration: none; }
  .blog-post-teaser__arrow {
    margin-left: .2rem;
    position: relative;
    top: 1px; }
  .blog-post-teaser__categories {
    color: #a4091e;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.1rem;
    margin-bottom: 0.5rem; }
    @media (min-width: 900px) {
      .blog-post-teaser__categories {
        font-size: 1.2rem; } }
  .blog-post-teaser__date-author {
    color: #000;
    font-size: 1rem;
    margin-bottom: 1rem; }
    @media (min-width: 900px) {
      .blog-post-teaser__date-author {
        font-size: 1.2rem; } }
  .blog-post-teaser--overview {
    margin-bottom: 3rem; }
    .blog-post-teaser--overview .blog-post-teaser__title-1 {
      font-size: 2.8rem;
      margin-bottom: 0.7rem; }
      @media (min-width: 900px) {
        .blog-post-teaser--overview .blog-post-teaser__title-1 {
          font-size: 3.0rem; } }
    .blog-post-teaser--overview .blog-post-teaser__categories {
      font-size: 1.2rem;
      margin-bottom: 0.8rem; }
      @media (min-width: 900px) {
        .blog-post-teaser--overview .blog-post-teaser__categories {
          font-size: 1.6rem; } }
    @media (max-width: 899px) {
      .blog-post-teaser--overview .blog-post-teaser__background {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        background-size: cover;
        background-position: center center;
        transform: none !important;
        transition: none !important;
        z-index: auto;
        height: 30rem; } }

.blog-social-media-share {
  position: fixed;
  margin: 0;
  padding: 0;
  z-index: 950;
  left: 0;
  bottom: 0;
  width: 100vw; }
  @media (min-width: 900px) {
    .blog-social-media-share {
      top: 200px;
      bottom: auto;
      width: auto; } }
  .blog-social-media-share__item {
    list-style: none;
    display: inline-block; }
    @media (min-width: 900px) {
      .blog-social-media-share__item {
        display: block; } }
  .blog-social-media-share__button {
    text-decoration: none;
    color: #000;
    background-color: #e6e6e6;
    width: 25vw;
    height: 5.5rem;
    font-size: 2.8rem;
    line-height: 5.5rem;
    text-align: center;
    display: block; }
    @media (min-width: 900px) {
      .blog-social-media-share__button {
        width: 5.5rem;
        transition: all 0.2s ease-in; }
        .blog-social-media-share__button:hover {
          width: 7.5rem;
          padding-left: 1rem; } }

.in-experience-editor .blog-social-media-share {
  display: none; }

.blog-comments__title {
  color: #a4091e;
  font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 2.0rem;
  margin-bottom: 0.5em; }
  @media (min-width: 900px) {
    .blog-comments__title {
      font-size: 2.4rem; } }

.blog-comments__comment-container {
  margin-bottom: 5rem;
  font-size: 1.6rem; }

.blog-comments__comment {
  border-bottom: 1px solid #b7b7b7;
  margin-bottom: 2rem; }
  .blog-comments__comment--level-1 {
    padding: 2rem 0; }
  .blog-comments__comment--level-2 {
    padding: 2rem 0 2rem 10%; }
  .blog-comments__comment .blog-comments__cancel-button {
    display: inline-block; }
  .blog-comments__comment:target {
    animation-name: targetedCommentAni;
    animation-duration: 0.75s;
    animation-iteration-count: 5; }

@keyframes targetedCommentAni {
  0% {
    color: #000; }
  50% {
    color: #a4091e; }
  100% {
    color: #000; } }
  .blog-comments__comment a, .blog-comments__comment a:hover, .blog-comments__comment a:visited {
    color: #000;
    text-decoration: underline; }

.blog-comments__cancel-button {
  display: none; }

.blog-comments__title-row {
  display: flex;
  margin-bottom: 1.25rem; }

.blog-comments__by-migrol {
  height: 10px;
  display: inline-block;
  margin-right: 0.5rem; }

.blog-comments__author {
  flex: 1;
  font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal; }

.blog-comments__date {
  flex: none; }

.blog-comments__text {
  margin-bottom: 1.25rem;
  line-height: 1.3; }

.blog-comments__reply {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  text-align: inherit;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;
  /* Remove excess padding and border in Firefox 4+ */
  font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal; }
  .blog-comments__reply::-moz-focus-inner {
    border: 0;
    padding: 0; }

.blog-comments__form-row {
  margin-bottom: 2rem; }

.blog-comments__recaptcha-info {
  font-size: 1rem;
  color: #7f7f7f; }
  .blog-comments__recaptcha-info a {
    color: #7f7f7f; }

.blog-comments__success-message {
  margin: 2rem 0;
  font-size: 1.6rem;
  padding: 2rem;
  background-color: #fff;
  border: 0.2rem solid #4e6c30;
  color: #4e6c30; }

.blog-comments__error-message {
  margin: 2rem 0;
  font-size: 1.6rem;
  padding: 2rem;
  background-color: #fff;
  border: 0.2rem solid #a4091e;
  color: #a4091e; }

.blog-comments__closed-message {
  margin: 2rem 0;
  font-size: 1.6rem;
  padding: 2rem;
  background-color: #fff;
  border: 0.2rem solid #7f7f7f;
  color: #7f7f7f; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/*
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 1.5em 1.4em;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  outline: none; }
  .hamburger:hover {
    opacity: 0.7; }
  .hamburger.is-active:hover {
    opacity: 0.7; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #000; }
  .hamburger.is-active .hamburger-inner {
    transform: rotate(225deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    .hamburger.is-active .hamburger-inner::before {
      top: 0;
      opacity: 0;
      transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
    .hamburger.is-active .hamburger-inner::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger-box {
  width: 2.2em;
  height: 1.7em;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -0.15em;
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 2.2em;
    height: 0.3em;
    background-color: #000;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -0.7em;
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger-inner::after {
    bottom: -0.7em;
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

@media (max-width: 899px) {
  .main-overlay--force {
    display: block !important; }
  .main-nav {
    display: none;
    position: fixed;
    top: 6rem;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-y: auto;
    transform: translateX(-100vw);
    transition: transform 0.5s;
    font-size: 1.4rem;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal; }
  .main-nav--active {
    transform: translateX(0);
    transition: transform 0.5s; }
  .main-nav__breadcrumbs {
    position: fixed;
    overflow-x: auto;
    left: -80vw;
    width: 80vw;
    height: 5rem;
    transition: left 0.5s;
    z-index: 1001;
    background-color: #fff;
    border-bottom: solid 1px #cdcdcd;
    padding: 0 2rem;
    white-space: nowrap;
    font-size: 1.2rem;
    font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    .main-nav__breadcrumbs .svg-inline--fa {
      color: #a4091e;
      font-size: 0.9em; }
  .main-nav__breadcrumbs--active {
    left: 0; }
  .main-nav__breadcrumbs-item {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    text-align: inherit;
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;
    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;
    /* Remove excess padding and border in Firefox 4+ */
    color: #000; }
    .main-nav__breadcrumbs-item::-moz-focus-inner {
      border: 0;
      padding: 0; }
  .main-nav__breadcrumbs-item--current {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    margin-right: 2rem; }
  .main-nav__ul {
    margin: 0;
    padding: 5rem 0 0 4rem;
    position: absolute;
    left: 150vw;
    top: 0;
    min-height: calc(100vh - 6rem);
    width: 80vw;
    background-color: #f1f1f1;
    transition: left 0.5s;
    z-index: 1001;
    overflow: hidden; }
  .main-nav__ul--level-1 {
    padding: 0 0 50px 0;
    top: 0;
    left: 0;
    width: 80vw;
    z-index: 1000;
    overflow-x: hidden;
    min-height: 100%; }
    .main-nav__ul--level-1 > .main-nav__li--title {
      margin-left: 0; }
  .main-nav__ul--active {
    left: 0; }
  .main-nav__li {
    list-style-type: none;
    background-color: #FFF;
    border-bottom: solid 2px #f1f1f1;
    height: 5rem; }
  .main-nav__li--title {
    margin-left: -4rem; }
  .main-nav__li--with-padding {
    padding: 0 4rem 0 2rem;
    display: flex;
    width: 80vw;
    align-items: center; }
  .main-nav__li--meta {
    background-color: #f1f1f1;
    border-bottom: solid 2px #FFF; }
  .main-nav__link {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    text-align: inherit;
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;
    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;
    /* Remove excess padding and border in Firefox 4+ */
    display: table-cell;
    height: 5rem;
    width: 80vw;
    vertical-align: middle;
    color: #000;
    text-decoration: none;
    font-weight: bold;
    padding: 0 4rem 0 2rem;
    position: relative; }
    .main-nav__link::-moz-focus-inner {
      border: 0;
      padding: 0; }
    .main-nav__link .svg-inline--fa {
      display: none; }
  .main-nav__link--level-2 {
    width: calc(80vw - 4rem); }
  .main-nav__link--level-3 {
    width: calc(80vw - 4rem); }
  .main-nav__link--drilldown .svg-inline--fa {
    display: block;
    color: #a4091e;
    position: absolute;
    right: 2rem;
    top: 2.5rem;
    margin-top: -0.7rem; }
  .main-nav__link--level-1--active, .main-nav__link--level-2--active, .main-nav__link--level-3--active {
    color: #a4091e; }
  .main-nav__link--title {
    color: #a4091e; }
  .main-nav__link--desktop {
    display: none; }
  .main-nav__back-button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    text-align: inherit;
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;
    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;
    /* Remove excess padding and border in Firefox 4+ */
    position: absolute;
    top: 10rem;
    left: -4rem;
    bottom: 0;
    width: 4rem;
    background-color: #d8d8d8;
    transition: left 0.5s;
    z-index: 1001;
    font-size: 2rem;
    text-align: center; }
    .main-nav__back-button::-moz-focus-inner {
      border: 0;
      padding: 0; }
    .main-nav__back-button .svg-inline--fa {
      color: #a4091e;
      position: absolute;
      top: 50%;
      margin-top: -1rem;
      left: 50%;
      margin-left: -0.625rem; }
  .main-nav__back-button--active {
    left: 0; }
  .main-nav__link-migros-username {
    color: #000;
    text-decoration: none; } }

@media (min-width: 900px) {
  .main-nav .hamburger {
    display: none; }
  .main-nav__breadcrumbs {
    display: none; }
  .main-nav__ul {
    margin: 0;
    padding: 0; }
  .main-nav__ul--level-1 {
    display: flex;
    justify-content: flex-end;
    height: auto !important; }
  .main-nav__ul--level-2 {
    background-color: #fff;
    position: absolute;
    left: 1rem;
    right: 1rem;
    padding: 2.5rem 2rem 2rem 2rem;
    columns: 2;
    column-gap: 3rem;
    column-rule: solid 1px #f1f1f1;
    transform: translateY(-1000px);
    transition: transform 0.25s;
    z-index: -1; } }
  @media (min-width: 900px) and (min-width: 1260px) {
    .main-nav__ul--level-2 {
      columns: 3; } }

@media (min-width: 900px) {
  .main-nav__li {
    list-style-type: none; }
  .main-nav__li--level-1::before {
    content: '';
    display: block;
    float: left;
    height: 8.6rem;
    margin-bottom: -1px;
    width: 1.5rem; } }
  @media (min-width: 900px) and (min-width: 1260px) {
    .main-nav__li--level-1::before {
      width: 3.5rem; } }

@media (min-width: 900px) {
  .main-nav__li--level-1.is-open .main-nav__link--level-1 {
    color: #a4091e;
    border-bottom: solid 2px #a4091e; }
  .main-nav__li--level-1.is-open .main-nav__ul--level-2 {
    transform: translateY(0);
    transition: transform 0.25s; }
  .main-nav__li--level-1:nth-child(2) .main-nav__link--level-1 {
    margin-left: 0; } }

@media (min-width: 900px) and (min-width: 1260px) {
  .main-nav__li--level-1:nth-child(2):hover::before {
    width: 20rem; } }

@media (min-width: 900px) {
  .main-nav__li--level-2 {
    page-break-inside: avoid;
    break-inside: avoid;
    margin-bottom: 4rem; }
  .main-nav__li--meta, .main-nav__li--title {
    display: none; }
  .main-nav__link {
    text-decoration: none;
    color: #000;
    white-space: nowrap; }
  .main-nav__link--level-1 {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 2rem;
    display: inline-block;
    height: 8.5rem;
    padding: 3.6rem 1rem 0 2.7rem;
    position: relative;
    top: 1px;
    line-height: 1.6rem; }
    .main-nav__link--level-1--active {
      color: #a4091e; }
    .main-nav__link--level-1 .svg-inline--fa {
      font-size: 0.8em;
      position: absolute;
      left: 0.5rem;
      color: #a4091e;
      font-size: 0.9em;
      transform: rotate(90deg); }
  .main-nav__link--level-2 {
    font-size: 2rem;
    line-height: 4rem;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal; }
    .main-nav__link--level-2--active {
      color: #a4091e; }
    .main-nav__link--level-2 .svg-inline--fa {
      margin-left: 0.5rem;
      color: #a4091e; }
  a.main-nav__link--level-2:hover {
    color: #a4091e; }
  .main-nav__link--level-3 {
    font-size: 1.8rem;
    line-height: 3rem; }
    .main-nav__link--level-3--active {
      color: #a4091e; }
    .main-nav__link--level-3:hover {
      color: #a4091e; }
  .main-nav__link--drilldown, .main-nav__back-button {
    display: none; } }

.in-experience-editor .main-nav__ul--level-2 {
  transition: none !important; }

.meta-nav {
  display: none; }

@media (min-width: 900px) {
  .meta-nav {
    background-color: #000;
    display: block; }
    .meta-nav__content {
      margin: 0 auto;
      padding: 0 3rem;
      height: 3.6rem;
      text-align: right; } }
    @media (min-width: 900px) and (min-width: 1260px) {
      .meta-nav__content {
        max-width: 1260px; } }

@media (min-width: 900px) {
    .meta-nav__item {
      margin-left: 3rem;
      position: relative;
      height: 3.6rem;
      display: inline-block; }
      .meta-nav__item--migrolcard {
        padding-top: 0.7rem; }
        .meta-nav__item--migrolcard img {
          display: block; }
      .meta-nav__item--mlogin {
        overflow: hidden;
        font-size: 1.2rem;
        line-height: 3.6rem; }
      .meta-nav__item:first-child {
        margin-left: 0; }
    .meta-nav__item:hover .meta-nav__subitems {
      display: block; }
    .meta-nav__link {
      display: table-cell;
      vertical-align: middle;
      color: #fff;
      text-decoration: none;
      font-size: 1.2rem;
      cursor: pointer;
      height: 3.6rem; }
    .meta-nav__subitems {
      position: absolute;
      top: 3.6rem;
      left: -50%;
      z-index: 1002;
      background-color: #fff;
      display: none; }
    .meta-nav__subitems-inner {
      padding: 0.5rem 1.5rem 0.1rem;
      background-color: #000;
      margin-top: 5px;
      position: relative;
      white-space: nowrap; }
      .meta-nav__subitems-inner::before {
        content: '';
        position: absolute;
        top: -5px;
        left: 50%;
        margin-left: -5px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid black; }
    .meta-nav__link-migros-username {
      color: #fff;
      text-decoration: none; } }

@media (max-width: 899px) {
  .search-nav__close-desktop {
    display: none; }
  .search-nav__toggle {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    text-align: inherit;
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;
    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;
    /* Remove excess padding and border in Firefox 4+ */
    width: 5rem;
    height: 5rem;
    text-align: center;
    font-size: 2.2rem;
    display: table-cell;
    color: #000;
    text-decoration: none;
    vertical-align: middle;
    margin-right: 1.1rem; }
    .search-nav__toggle::-moz-focus-inner {
      border: 0;
      padding: 0; }
    .search-nav__toggle .hamburger-box {
      display: none; }
    .search-nav__toggle .fa-search {
      display: inline-block; }
    .search-nav__toggle--active .hamburger-box {
      display: inline-block;
      font-size: 1rem; }
    .search-nav__toggle--active .fa-search {
      display: none; }
  .search-nav__form {
    position: absolute;
    top: 4.6rem;
    left: 0;
    right: 0;
    align-items: stretch;
    background-color: #fff;
    display: none;
    border-bottom: 2px solid #f1f1f1; }
    .search-nav__form--active {
      display: flex; }
    .search-nav__form .autocomplete-suggestions {
      display: none !important; }
  .search-nav__input {
    font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.9rem;
    border: solid 1px #b7b7b7;
    background-color: transparent;
    outline: none;
    flex: 1;
    margin: 1.2rem 1.1rem;
    padding: 1rem; }
    .search-nav__input::placeholder {
      color: #b7b7b7; }
  .search-nav__triangle {
    position: absolute;
    top: -2px;
    right: 2.2rem; }
  .search-nav__submit {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    text-align: inherit;
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;
    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;
    /* Remove excess padding and border in Firefox 4+ */
    position: absolute;
    font-size: 1.9rem;
    right: 2.4rem;
    top: 2.2rem;
    color: #b7b7b7; }
    .search-nav__submit::-moz-focus-inner {
      border: 0;
      padding: 0; } }

@media (min-width: 900px) {
  .search-nav {
    margin-left: 3.5rem; }
    .search-nav__toggle {
      border: none;
      margin: 0;
      padding: 0;
      width: auto;
      overflow: visible;
      background: transparent;
      text-align: inherit;
      /* inherit font & color from ancestor */
      color: inherit;
      font: inherit;
      /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
      line-height: normal;
      /* Corrects font smoothing for webkit */
      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;
      /* Corrects inability to style clickable `input` types in iOS */
      -webkit-appearance: none;
      cursor: pointer;
      outline: none;
      /* Remove excess padding and border in Firefox 4+ */
      padding-top: 0.9rem;
      width: 5rem;
      height: 5rem;
      text-align: center;
      font-size: 2.2rem;
      display: table-cell;
      color: #000;
      text-decoration: none;
      vertical-align: middle; }
      .search-nav__toggle::-moz-focus-inner {
        border: 0;
        padding: 0; }
      .search-nav__toggle .hamburger-box {
        display: none; }
    .search-nav__form {
      position: absolute;
      top: 1.5rem;
      bottom: 1.5rem;
      left: 30rem;
      right: 3rem;
      align-items: stretch;
      background-color: #f1f1f1;
      display: none; }
      .search-nav__form--active {
        display: flex; }
      .search-nav__form .autocomplete-suggestions {
        top: 5.5rem;
        left: 0;
        right: 0;
        width: auto !important;
        background-color: #f1f1f1;
        font-size: 1.8rem;
        overflow-y: auto; }
      .search-nav__form .autocomplete-suggestion {
        cursor: pointer;
        border-top: 1px solid #cdcdcd;
        padding: 1rem 5.5rem; }
        .search-nav__form .autocomplete-suggestion.autocomplete-selected {
          background-color: #cdcdcd; }
      .search-nav__form .autocomplete-title {
        font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-style: normal;
        color: #a4091e;
        margin-bottom: 0.5rem; }
      .search-nav__form .autocomplete-highlight {
        font-size: 1.6rem; }
        .search-nav__form .autocomplete-highlight strong {
          font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
          font-weight: normal;
          font-style: normal; }
      .search-nav__form .autocomplete-intro {
        font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-style: normal;
        border-top: 1px solid #b7b7b7;
        padding: 0.5rem 5.5rem;
        padding-top: 1rem;
        padding-bottom: 1rem; }
    .search-nav__input {
      font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 2.6rem;
      border: none;
      background-color: transparent;
      outline: none;
      flex: 1; }
      .search-nav__input::placeholder {
        color: #b7b7b7; }
    .search-nav__triangle {
      display: none; }
    .search-nav__submit, .search-nav__close-desktop {
      border: none;
      margin: 0;
      padding: 0;
      width: auto;
      overflow: visible;
      background: transparent;
      text-align: inherit;
      /* inherit font & color from ancestor */
      color: inherit;
      font: inherit;
      /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
      line-height: normal;
      /* Corrects font smoothing for webkit */
      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;
      /* Corrects inability to style clickable `input` types in iOS */
      -webkit-appearance: none;
      cursor: pointer;
      outline: none;
      /* Remove excess padding and border in Firefox 4+ */
      width: 5.5rem;
      font-size: 2rem;
      text-align: center; }
      .search-nav__submit::-moz-focus-inner, .search-nav__close-desktop::-moz-focus-inner {
        border: 0;
        padding: 0; }
    .search-nav--visible .main-nav {
      display: none; } }

.hero__item {
  position: relative; }
  @media (min-width: 900px) {
    .hero__item--white .hero__content-container-outer {
      top: 0;
      bottom: 0; }
    .hero__item--white .hero__content-container-inner {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center; }
    .hero__item--white .hero__content {
      padding: 0;
      background-color: transparent; }
    .hero__item--white .hero__title {
      color: #fff;
      font-size: 3.8rem;
      margin-bottom: 2rem; }
    .hero__item--white .hero__text {
      color: #fff;
      font-size: 1.8rem; }
    .hero__item--white .hero__cta {
      color: #fff; }
      .hero__item--white .hero__cta:hover {
        color: #fff; } }

.hero__image {
  padding-top: 34.72222%;
  position: relative;
  background-size: cover;
  background-position: center center; }
  @media (min-width: 900px) {
    .hero__image {
      height: 50rem;
      padding-top: 0; } }
  .hero__image--home {
    padding-top: 41.66667%; }
    @media (min-width: 900px) {
      .hero__image--home {
        height: 60rem;
        padding-top: 0; } }
  .hero__image--slim {
    padding-top: 17.36111%; }
    @media (min-width: 900px) {
      .hero__image--slim {
        height: 25rem;
        padding-top: 0; } }

.hero__video {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

@media (min-width: 900px) {
  .hero__content-container-outer {
    position: absolute;
    top: 6rem;
    left: 0;
    right: 0; } }

@media (min-width: 900px) {
  .hero__content-container-inner {
    margin: 0 auto;
    padding: 0 3rem;
    display: flex; } }

@media (min-width: 1260px) {
  .hero__content-container-inner {
    max-width: 1260px; } }

.hero__content-sticker-container {
  position: absolute;
  top: 3vw;
  left: 9vw; }
  @media (min-width: 900px) {
    .hero__content-sticker-container {
      position: static;
      top: auto;
      left: auto;
      margin-left: 3rem;
      margin-top: -1rem; } }
  @media (min-width: 1260px) {
    .hero__content-sticker-container {
      margin-left: 6rem; } }

.hero__content-sticker {
  width: 26vw;
  transform: rotate(-10deg); }
  @media (min-width: 900px) {
    .hero__content-sticker {
      width: 21rem;
      transform: rotate(10deg); } }
  @media (min-width: 1260px) {
    .hero__content-sticker {
      width: 23rem; } }

.hero__content-sticker-container-empty-hero {
  position: absolute;
  top: 1.5rem;
  right: 2vw; }
  @media (min-width: 900px) {
    .hero__content-sticker-container-empty-hero {
      top: 8rem; } }
  @media (min-width: 1260px) {
    .hero__content-sticker-container-empty-hero {
      right: 7.5vw; } }

.hero__content-sticker-empty-hero {
  width: 26vw;
  transform: rotate(10deg); }
  @media (min-width: 900px) {
    .hero__content-sticker-empty-hero {
      width: 21rem; } }
  @media (min-width: 1260px) {
    .hero__content-sticker-empty-hero {
      width: 23rem; } }

.hero__image--slim + .hero__content-sticker-container-empty-hero {
  top: 1rem; }
  @media (min-width: 900px) {
    .hero__image--slim + .hero__content-sticker-container-empty-hero {
      top: 2rem; } }
  .hero__image--slim + .hero__content-sticker-container-empty-hero .hero__content-sticker-empty-hero {
    width: 14vw; }
    @media (min-width: 900px) {
      .hero__image--slim + .hero__content-sticker-container-empty-hero .hero__content-sticker-empty-hero {
        width: 18rem; } }
    @media (min-width: 1260px) {
      .hero__image--slim + .hero__content-sticker-container-empty-hero .hero__content-sticker-empty-hero {
        width: 19rem; } }

.hero__content {
  border-style: solid;
  border-color: #e6e6e6;
  border-width: 2rem 2.6rem;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 1rem; }
  @media (min-width: 900px) {
    .hero__content {
      border: none;
      width: 50rem;
      padding: 3rem; } }

.hero__title {
  font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 2rem;
  color: #a4091e;
  margin-bottom: 0.5rem; }

.hero__text {
  font-size: 1.6rem;
  margin-bottom: 0.5rem; }

.hero__cta {
  color: #000;
  text-decoration: none;
  font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1.6rem; }
  .hero__cta:hover {
    color: #a4091e; }

.hero__pager {
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 2rem 0 0; }
  @media (min-width: 900px) {
    .hero__pager {
      position: absolute;
      bottom: 2rem;
      width: 100%; } }
  .hero__pager li {
    position: relative;
    display: inline-block;
    margin: 0 0.5rem;
    padding: 0;
    cursor: pointer; }
    @media (min-width: 900px) {
      .hero__pager li {
        margin: 0 0.8rem; } }
    .hero__pager li button {
      border: 0;
      background: #f1f1f1;
      display: block;
      height: 1.1rem;
      width: 1.1rem;
      border-radius: 50%;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      @media (min-width: 900px) {
        .hero__pager li button {
          height: 1.4rem;
          width: 1.4rem; } }
      @media (hover: hover) {
        .hero__pager li button:hover, .hero__pager li button:focus {
          outline: none;
          background-color: #b7b7b7; } }
  @media (hover: hover) and (min-width: 900px) {
    .hero__pager li button:hover, .hero__pager li button:focus {
      background-color: #fff; } }
    .hero__pager li.slick-active button {
      background-color: #b7b7b7; }
      @media (min-width: 900px) {
        .hero__pager li.slick-active button {
          background-color: #fff; } }

.hero__item:nth-child(n+2) {
  display: none; }

.slick-initialized .hero__item:nth-child(n+2) {
  display: block; }

.contentblock {
  margin-bottom: 3.75rem; }
  @media (min-width: 900px) {
    .contentblock {
      margin-bottom: 7.5rem; }
      .contentblock--small-margin-bottom {
        margin-bottom: 3.75rem; } }
  .contentblock--gray {
    background-color: #e6e6e6;
    padding: 3.75rem 0; }
    @media (min-width: 900px) {
      .contentblock--gray {
        padding: 6rem 0 7.5rem; } }
  .contentblock--no-margin-and-padding {
    margin: 0;
    padding: 0; }
  .contentblock__content {
    padding: 0 2.6rem;
    position: relative; }
    @media (min-width: 900px) {
      .contentblock__content {
        padding: 0 3rem; } }
  @media (min-width: 900px) and (min-width: 1260px) {
    .contentblock__content {
      max-width: 1260px;
      margin: 0 auto; } }
    .contentblock__content--narrow {
      padding: 0 2.6rem; }
      @media (min-width: 900px) {
        .contentblock__content--narrow {
          padding: 0 23rem; } }
    .contentblock__content > *:last-child {
      margin-bottom: 0; }

.buy-energy {
  background-color: #e6e6e6; }
  .buy-energy__title {
    padding: 2.8rem 2.5rem 1.5rem 2.5rem;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 2.8rem;
    color: #a4091e; }
    @media (min-width: 900px) {
      .buy-energy__title {
        font-size: 3.0rem; } }
  .buy-energy__tabs {
    border-collapse: collapse;
    display: flex; }
  .buy-energy__tab {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    text-align: inherit;
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;
    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;
    /* Remove excess padding and border in Firefox 4+ */
    border-top: solid 1px #b7b7b7;
    border-right: solid 1px #b7b7b7;
    border-bottom: solid 1px #b7b7b7;
    background-color: #cdcdcd;
    text-decoration: none;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.6rem;
    color: #000;
    padding: 0 2rem;
    line-height: 3.3rem;
    text-align: center;
    flex: 1; }
    .buy-energy__tab::-moz-focus-inner {
      border: 0;
      padding: 0; }
    .buy-energy__tab:first-child {
      border-left: none; }
    .buy-energy__tab:last-of-type {
      border-right: none; }
  .buy-energy__tab--active {
    background-color: transparent;
    border-bottom-color: transparent;
    color: #a4091e; }
  .buy-energy__tab-content {
    display: none;
    padding: 2.6rem 3.6rem 3.0rem 3.6rem; }
  .buy-energy__icon {
    display: none; }
  .buy-energy__tab-content-title {
    font-size: 2rem;
    line-height: 2.9rem; }
    @media (min-width: 900px) {
      .buy-energy__tab-content-title {
        font-size: 2.4rem; } }
  .buy-energy__tab-content-copy {
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin: 0.6rem 0 2.1rem;
    overflow: hidden; }
  .buy-energy__tab-content--active {
    display: block; }
  .buy-energy__intro::after {
    content: "";
    display: table;
    clear: both; }
  .buy-energy__inputs {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr; }
    @media (min-width: 1260px) {
      .buy-energy__inputs {
        grid-template-columns: 1fr 165px; } }
  .buy-energy__zipcity {
    background-color: #fff;
    font-size: 1.4rem; }
  .buy-energy__amount {
    font-size: 1.4rem; }
  .buy-energy__submit {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    text-align: inherit;
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;
    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;
    /* Remove excess padding and border in Firefox 4+ */
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    text-align: center;
    font-size: 1.6rem;
    border: none;
    color: #fff;
    background-color: #4e6c30;
    display: block;
    width: 100%;
    height: 4rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    margin-top: 1.5rem; }
    .buy-energy__submit::-moz-focus-inner {
      border: 0;
      padding: 0; }
    .buy-energy__submit:hover {
      background-color: #445e2a; }

.contentblock--gray .buy-energy {
  background-color: #fff; }

.columns__col .buy-energy {
  min-height: 100%; }

.col--large .buy-energy__tab, .col--x-large .buy-energy__tab {
  flex: none; }

.col--large .buy-energy__tab-filler, .col--x-large .buy-energy__tab-filler {
  flex: 1;
  border-bottom: solid 1px #b7b7b7;
  border-left: solid 1px #b7b7b7; }

.col--large .buy-energy__tab-content, .col--x-large .buy-energy__tab-content {
  max-width: 55rem; }

.col--large .buy-energy__icon, .col--x-large .buy-energy__icon {
  display: block;
  float: left;
  margin-right: 2rem;
  border-radius: 50%;
  border: solid 1px #000; }

@media (min-width: 900px) {
  .hero .buy-energy {
    background-color: rgba(255, 255, 255, 0.9);
    width: 50rem; }
    .hero .buy-energy__title {
      font-size: 3.8rem; }
    .hero .buy-energy__tab {
      display: inline-block;
      background-color: #e6e6e6;
      padding: 0 3rem;
      border-left: none;
      flex: none; }
      .hero .buy-energy__tab:last-of-type {
        border-right: solid 1px #b7b7b7; }
    .hero .buy-energy__tab-filler {
      flex: 1;
      border-bottom: solid 1px #b7b7b7; }
    .hero .buy-energy__tab--active {
      background-color: transparent;
      border-bottom-color: transparent; }
    .hero .buy-energy__icon {
      display: block;
      float: left;
      margin-right: 2rem;
      border-radius: 50%;
      border: solid 1px #000; } }
  @media (min-width: 900px) and (min-width: 900px) {
    .hero .buy-energy__inputs {
      grid-template-columns: 1fr 165px; } }

.hero__image--buy-energy-header {
  background-image: url("/assets/MigrolContent/img/header-buy-energy-home-98733581d8.jpg"); }

.contentblock--buy-energy {
  background-color: #e6e6e6;
  padding: 3.75rem 0; }
  .contentblock--buy-energy-single-product {
    padding: 0 !important; }
    .contentblock--buy-energy-single-product .buy-energy-header {
      padding: 0 !important; }
      .contentblock--buy-energy-single-product .buy-energy-header__products {
        display: block; }
      .contentblock--buy-energy-single-product .buy-energy-header__product-details {
        padding: 2.8rem 3.4rem; }
  .contentblock--buy-energy-single-product-promo {
    background-color: #a4091e; }
    .contentblock--buy-energy-single-product-promo .buy-energy-header {
      background-color: #a4091e; }
  @media (min-width: 900px) {
    .contentblock--buy-energy {
      padding: 6rem 0 7.5rem; } }
  @media (min-width: 1260px) {
    .contentblock--buy-energy {
      background-color: transparent;
      padding: 0; } }

.layout__page-start .contentblock--buy-energy {
  margin-bottom: 0; }

.buy-energy-header {
  background-color: #e6e6e6; }
  @media (min-width: 1260px) {
    .buy-energy-header {
      padding: 3.5rem 10rem 5rem 10rem;
      margin-top: -15.7rem; } }
  .buy-energy-header--in-content {
    margin-top: 0; }
  .buy-energy-header__title {
    display: block;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 2.8rem;
    color: #a4091e;
    margin-bottom: 3.3rem; }
    @media (min-width: 1260px) {
      .buy-energy-header__title {
        font-size: 3.6rem; } }
  .buy-energy-header__products {
    display: grid;
    grid-row-gap: 1.9rem;
    grid-column-gap: 1.2rem;
    grid-auto-columns: minmax(0, 1fr); }
  .buy-energy-header__product-tab {
    height: 12.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #b7b7b7;
    cursor: pointer;
    background-color: #fff; }
    .buy-energy-header__product-tab:hover {
      border-color: #a4091e;
      color: #a4091e; }
    .buy-energy-header__product-tab--active {
      border-color: #a4091e;
      border-width: 2px;
      color: #a4091e; }
    .buy-energy-header__product-tab__logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .buy-energy-header__product-tab__logo__product {
        position: relative; }
        .buy-energy-header__product-tab__logo__product__icon {
          height: 5.8rem;
          width: 5.8rem; }
        .buy-energy-header__product-tab__logo__product__promo-icon {
          position: absolute;
          height: 32px;
          top: -1.5rem;
          right: -3.3rem;
          color: #a4091e; }
    .buy-energy-header__product-tab__name {
      display: block;
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      margin-top: 0.6rem;
      text-align: center; }
  .buy-energy-header__product-details {
    grid-column: 1/2;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-top: 1rem;
    margin-bottom: 4rem; }
    .buy-energy-header__product-details--pos1 {
      grid-row: 2/3; }
    .buy-energy-header__product-details--pos2 {
      grid-row: 3/4; }
    .buy-energy-header__product-details--pos3 {
      grid-row: 4/5; }
    .buy-energy-header__product-details--pos4 {
      grid-row: 5/6; }
    .buy-energy-header__product-details--pos5 {
      grid-row: 6/7; }
    @media (min-width: 900px) {
      .buy-energy-header__product-details {
        flex-direction: row;
        margin-bottom: 1.8rem; } }
    @media (min-width: 1260px) {
      .buy-energy-header__product-details {
        grid-row: 2/3;
        margin-top: 2.4rem;
        margin-bottom: 0; }
        .buy-energy-header__product-details--size1 {
          grid-column: 1/2; }
        .buy-energy-header__product-details--size2 {
          grid-column: 1/3; }
        .buy-energy-header__product-details--size3 {
          grid-column: 1/4; }
        .buy-energy-header__product-details--size4 {
          grid-column: 1/5; }
        .buy-energy-header__product-details--size5 {
          grid-column: 1/6; } }
    .buy-energy-header__product-details--single-product {
      margin-top: 0; }
    .buy-energy-header__product-details--promo {
      padding: 2.8rem 3.4rem;
      background-color: #a4091e;
      color: #fff;
      margin-top: -1.9rem;
      margin-bottom: 0; }
      @media (min-width: 900px) {
        .buy-energy-header__product-details--promo {
          margin-top: 0;
          margin-bottom: 1.8rem; } }
      @media (min-width: 1260px) {
        .buy-energy-header__product-details--promo {
          margin-bottom: 0; } }
    @media (min-width: 900px) {
      .buy-energy-header__product-details__order {
        width: 52%; } }
    .buy-energy-header__product-details__title {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 2.4rem;
      margin-bottom: 0.6rem;
      line-height: 3.2rem; }
      .buy-energy-header__product-details__title__promo-icon {
        height: 3.2rem;
        width: 3.2rem;
        display: inline-block;
        position: absolute;
        margin-left: 0.8rem; }
    .buy-energy-header__product-details__desc {
      margin-bottom: 3.5rem; }
    .buy-energy-header__product-details__teaser {
      margin-top: 3rem; }
      @media (min-width: 900px) {
        .buy-energy-header__product-details__teaser {
          margin-top: 0;
          width: 37%; } }
      .buy-energy-header__product-details__teaser__content.migrol-rt-content {
        font-size: 1.6rem;
        line-height: 2.2rem;
        margin-bottom: 0; }
      .buy-energy-header__product-details__teaser__content a, .buy-energy-header__product-details__teaser__content a:hover {
        color: #fff !important; }
  .buy-energy-header__form__zipcity {
    background-color: #fff;
    font-size: 1.4rem;
    margin-bottom: 1.2rem;
    color: #000; }
  .buy-energy-header__form__amount {
    font-size: 1.4rem;
    margin-bottom: 1.2rem; }
    @media (min-width: 900px) {
      .buy-energy-header__form__amount {
        margin-bottom: 0;
        width: 48%; } }
  .buy-energy-header__form__submit {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    text-align: inherit;
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;
    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;
    /* Remove excess padding and border in Firefox 4+ */
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    text-align: center;
    font-size: 1.6rem;
    border: none;
    color: #fff;
    background-color: #4e6c30;
    display: block;
    height: 4rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    width: 100%; }
    .buy-energy-header__form__submit::-moz-focus-inner {
      border: 0;
      padding: 0; }
    @media (min-width: 900px) {
      .buy-energy-header__form__submit {
        width: 48%; } }
    .buy-energy-header__form__submit:hover {
      background-color: #445e2a; }
  .buy-energy-header__form__side-by-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    @media (min-width: 900px) {
      .buy-energy-header__form__side-by-side {
        flex-direction: row; } }
  .buy-energy-header__link-button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    text-align: inherit;
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;
    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;
    /* Remove excess padding and border in Firefox 4+ */
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.6rem;
    color: #fff;
    text-decoration: none;
    background-color: #4e6c30;
    display: block;
    padding: 1rem 2.5rem;
    text-align: center; }
    .buy-energy-header__link-button::-moz-focus-inner {
      border: 0;
      padding: 0; }
    .buy-energy-header__link-button:hover {
      background-color: #445e2a; }
    @media (min-width: 900px) {
      .buy-energy-header__link-button {
        display: inline-block; } }

.breadcrumbs {
  overflow-x: auto;
  width: 100vw; }
  @media (min-width: 900px) {
    .breadcrumbs {
      overflow: hidden;
      width: auto;
      margin-top: 2.5rem; } }
  .breadcrumbs__list {
    font-size: 1.2rem;
    list-style: none;
    margin: 0;
    padding: 0;
    white-space: nowrap; }
    @media (min-width: 900px) {
      .breadcrumbs__list {
        padding: 0 3rem; } }
  @media (min-width: 900px) and (min-width: 1260px) {
    .breadcrumbs__list {
      max-width: 1260px;
      margin: 0 auto; } }
  .breadcrumbs__item {
    display: inline-block; }
    .breadcrumbs__item:first-child {
      padding-left: 2.5rem; }
      @media (min-width: 900px) {
        .breadcrumbs__item:first-child {
          padding-left: 0; } }
    .breadcrumbs__item:last-child {
      padding-right: 2.5rem; }
      @media (min-width: 900px) {
        .breadcrumbs__item:last-child {
          padding-right: 0; } }
    .breadcrumbs__item .svg-inline--fa {
      color: #a4091e;
      font-size: 1rem;
      margin: 0 0.5rem; }
  .breadcrumbs__link {
    color: #000;
    text-decoration: none;
    display: inline-block;
    line-height: 5rem;
    height: 5rem; }
    @media (min-width: 900px) {
      .breadcrumbs__link {
        line-height: normal;
        height: auto; } }
  .breadcrumbs__item:last-child .breadcrumbs__link {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal; }

.accordion {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 3.75rem; }
  @media (min-width: 900px) {
    .accordion {
      margin-bottom: 3.75rem; } }
  .accordion__item {
    padding: 0;
    margin: 0;
    border-top: solid 1px #7f7f7f;
    position: relative; }
  .accordion__label {
    color: #a4091e;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.6rem;
    cursor: pointer;
    display: block;
    padding: 1rem 4rem 1rem 2rem;
    user-select: none;
    position: relative; }
    @media (min-width: 900px) {
      .accordion__label {
        font-size: 2rem; } }
    .accordion__label .svg-inline--fa {
      position: absolute;
      right: 2rem;
      top: 50%;
      margin-top: -7px;
      font-size: 1.4rem;
      transform: rotate(0deg);
      float: right;
      transition: transform, opacity 0.5s;
      opacity: 1; }
  .accordion__input {
    visibility: hidden;
    position: absolute;
    top: -10rem;
    left: 0;
    outline: none;
    pointer-events: none; }
  .accordion__content-container {
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0.075, 1.06, 0.18, 0.965), background-color 0.5s linear;
    overflow: hidden; }
  .accordion__content {
    position: relative;
    padding: 2rem; }
    .accordion__content .migrol-rt-content {
      margin-bottom: 0; }
  .accordion__image {
    display: block;
    max-width: 100%;
    height: auto; }
  .accordion__columns {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    grid-gap: 3rem; }
    @media (min-width: 900px) {
      .accordion__columns {
        display: grid;
        grid-gap: 3rem;
        grid-template-areas: "left right right";
        grid-template-rows: auto;
        grid-template-columns: repeat(3, calc((100% - 6rem) / 3)); }
        .accordion__columns > :first-child {
          grid-area: left; }
        .accordion__columns > :last-child {
          grid-area: right; } }
  .accordion__input:checked + .accordion__label {
    background-color: #e6e6e6;
    transition: background-color 0.5s; }
    .accordion__input:checked + .accordion__label .svg-inline--fa {
      transform: rotate(180deg);
      transition: transform, opacity 0.5s; }
  .accordion__input.radio:checked + .accordion__label {
    cursor: auto; }
    .accordion__input.radio:checked + .accordion__label .svg-inline--fa {
      transform: rotate(0deg);
      opacity: 0; }
  .accordion__input:checked ~ .accordion__content-container {
    max-height: 2000px;
    background-color: #e6e6e6;
    transition: max-height 0.5s cubic-bezier(0.885, 0, 1, 0.065), background-color 0.5s linear; }

.image-bullets {
  margin-bottom: 3.75rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.75rem; }
  @media (min-width: 900px) {
    .image-bullets {
      margin-bottom: 3.75rem; } }
  @media (min-width: 900px) {
    .image-bullets {
      margin-bottom: 3.75rem; } }
  .image-bullets.slick-dotted {
    margin-bottom: 7.75rem; }
    @media (min-width: 900px) {
      .image-bullets.slick-dotted {
        margin-bottom: 7.75rem; } }
  .image-bullets .slick-slide {
    height: 100%; }
  @media (min-width: 900px) {
    .image-bullets--for-3 {
      width: 75%;
      margin-left: auto;
      margin-right: auto; } }
  @media (min-width: 900px) {
    .image-bullets--for-2 {
      width: 50%;
      margin-left: auto;
      margin-right: auto; } }
  @media (min-width: 900px) {
    .image-bullets--for-1 {
      width: 25%;
      margin-left: auto;
      margin-right: auto; } }
  .image-bullets__item {
    margin: 0;
    padding: 0 0.6rem;
    height: 100%;
    position: relative;
    visibility: hidden; }
  .image-bullets.slick-initialized .image-bullets__item {
    visibility: visible !important; }
  .image-bullets__content {
    background-color: #fff;
    margin: 2rem 2rem 0 2rem;
    height: 100%; }
    .image-bullets__content--centered {
      text-align: center; }
  .image-bullets__title {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 2rem;
    margin-bottom: 1.8rem; }
  .image-bullets__copy.migrol-rt-content {
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin-bottom: 0; }
  .image-bullets__button {
    position: absolute;
    color: #a4091e;
    font-weight: bold;
    text-align: center;
    font-size: 24px;
    background: none;
    border: none;
    z-index: 2;
    outline: none;
    padding: 0 0.6rem; }
    .image-bullets__button--previous {
      top: 0;
      left: -2.6rem; }
      @media (min-width: 900px) {
        .image-bullets__button--previous {
          left: -3rem; } }
    .image-bullets__button--next {
      top: 0;
      right: -2.6rem; }
      @media (min-width: 900px) {
        .image-bullets__button--next {
          right: -3rem; } }
    .image-bullets__button.slick-disabled {
      display: none !important; }
  .image-bullets__pager {
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: -60px;
    width: 100%; }
    .image-bullets__pager li {
      position: relative;
      display: inline-block;
      height: 30px;
      width: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer; }
      .image-bullets__pager li button {
        border: 0;
        background: #ccc;
        display: block;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        outline: none;
        line-height: 0px;
        font-size: 0px;
        color: transparent;
        padding: 5px;
        cursor: pointer; }
        @media (hover: hover) {
          .image-bullets__pager li button:hover, .image-bullets__pager li button:focus {
            outline: none;
            background-color: #999 !important; } }
      .image-bullets__pager li.slick-active button {
        background-color: #999 !important; }

.contentblock--gray .image-bullets__content {
  padding: 1.5rem 1rem;
  margin-left: 0;
  margin-right: 0; }

.contentblock--gray .image-bullets__pager li button {
  background: #cdcdcd; }

.image-bullets--round .image-bullets__image {
  width: 70%;
  padding-top: 70%;
  margin: 0 auto;
  border-radius: 50%;
  background-size: cover;
  background-position: center center; }

.image-bullets--round .image-bullets__image-load-helper {
  width: 1000px;
  height: 1px;
  margin: 0 auto;
  object-fit: cover;
  display: block; }

.image-bullets--square .image-bullets__image {
  width: 100%;
  padding-top: 70%;
  background-size: cover;
  background-position: center center; }

.image-bullets--square .image-bullets__image-load-helper {
  width: 1000px;
  height: 1px;
  object-fit: cover;
  display: block; }

.image-bullets--text-red .image-bullets__title {
  color: #a4091e; }

.migrol-rt-content {
  margin-bottom: 3.75rem;
  font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1.6rem;
  line-height: 2.2rem;
  position: relative; }
  @media (min-width: 900px) {
    .migrol-rt-content {
      margin-bottom: 3.75rem; } }
  .migrol-rt-content--emphasis {
    background-color: #fff;
    padding: 2.6rem; }
    @media (min-width: 900px) {
      .migrol-rt-content--emphasis {
        padding: 3rem; } }
  @media (min-width: 900px) {
    .migrol-rt-content:not(.migrol-rt-content--small) {
      font-size: 2rem;
      line-height: 3rem; } }
  .migrol-rt-content--small {
    font-size: 1.6rem;
    line-height: 2.2rem; }
  .migrol-rt-content h1, .migrol-rt-content h2, .migrol-rt-content h3, .migrol-rt-content h4, .migrol-rt-content h5, .migrol-rt-content h6, .migrol-rt-content p {
    margin: 0;
    padding: 0; }
  .migrol-rt-content h2 {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    margin-top: 1.75em;
    margin-bottom: 0.5em;
    font-size: 1.1em; }
    .migrol-rt-content h2.red {
      color: #a4091e; }
    .migrol-rt-content h2:first-child {
      margin-top: 0; }
    .migrol-rt-content h2 + h3, .migrol-rt-content h2 + h4 {
      margin-top: 0; }
  .migrol-rt-content h3 {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    margin-top: 1.75em;
    margin-bottom: 0.5em;
    font-size: 1.1em; }
    .migrol-rt-content h3.red {
      color: #a4091e; }
    .migrol-rt-content h3:first-child {
      margin-top: 0; }
    .migrol-rt-content h3 + h4 {
      margin-top: 0; }
  .migrol-rt-content h4, .migrol-rt-content h5, .migrol-rt-content h6 {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    margin-bottom: 0.5em; }
    .migrol-rt-content h4.red, .migrol-rt-content h5.red, .migrol-rt-content h6.red {
      color: #a4091e; }
  .migrol-rt-content h4 {
    font-size: 1.0em;
    margin-top: 1.75em; }
    .migrol-rt-content h4:first-child {
      margin-top: 0; }
  .migrol-rt-content strong, .migrol-rt-content b, .migrol-rt-content em {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal; }
  .migrol-rt-content p {
    margin-bottom: 1em; }
    .migrol-rt-content p:last-child {
      margin-bottom: 0; }
  .migrol-rt-content p.small {
    font-size: 0.8em;
    line-height: 1.3; }
  .migrol-rt-content .red {
    color: #a4091e; }
  .migrol-rt-content span.red {
    color: #a4091e; }
  .migrol-rt-content ul {
    margin: 0 0 1em 2.6rem;
    padding: 0;
    list-style: none; }
    .migrol-rt-content ul ul {
      margin-top: 0;
      margin-bottom: 0; }
    .migrol-rt-content ul li {
      margin: 0;
      position: relative; }
      .migrol-rt-content ul li::before {
        font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-style: normal;
        color: #a4091e;
        content: '–';
        position: absolute;
        left: -2.6rem; }
    .migrol-rt-content ul.small {
      font-size: 0.8em;
      line-height: 1.3; }
    .migrol-rt-content ul.migrol-rt-content__download-list {
      margin: 0 0 1em 2.6rem;
      padding: 0;
      list-style: none; }
      .migrol-rt-content ul.migrol-rt-content__download-list li {
        margin: 0;
        position: relative; }
        .migrol-rt-content ul.migrol-rt-content__download-list li::before {
          content: '';
          position: absolute;
          top: 0;
          left: -2.6rem;
          display: block;
          color: #a4091e;
          background: transparent url(/assets/MigrolContent/img/icon-download-3615ce5826.svg) center center;
          background-size: cover;
          width: 1.7rem;
          height: 1.7rem;
          margin-right: 0.6rem; }
          @media (min-width: 900px) {
            .migrol-rt-content ul.migrol-rt-content__download-list li::before {
              top: 0.5rem; } }
  .migrol-rt-content img {
    max-width: 100%;
    height: auto !important; }
  .migrol-rt-content ol {
    margin: 0 0 1em 2.6rem;
    padding: 0;
    list-style: none;
    counter-reset: item; }
    .migrol-rt-content ol.small {
      font-size: 0.8em;
      line-height: 1.3; }
    .migrol-rt-content ol ol {
      margin-top: 0;
      margin-bottom: 0; }
    .migrol-rt-content ol li {
      margin: 0;
      position: relative;
      counter-increment: item; }
      .migrol-rt-content ol li::before {
        color: #a4091e;
        content: counter(item) ".";
        left: -2.6rem;
        position: absolute; }
  .migrol-rt-content p + ul, .migrol-rt-content p + ol {
    margin-top: -1.5rem !important; }
  .migrol-rt-content a {
    text-decoration: underline;
    color: #000; }
    .migrol-rt-content a:hover {
      color: #a4091e; }
    .migrol-rt-content a.call-to-action-button {
      position: relative;
      display: inline-block;
      left: 50%;
      transform: translateX(-50%);
      padding: 0.5rem 1rem;
      max-width: 100%;
      color: #FFF;
      background-color: #4e6c30;
      font-size: 0.8em;
      text-align: center;
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      text-decoration: none; }
      @media (min-width: 600px) {
        .migrol-rt-content a.call-to-action-button {
          padding: 0.5rem 8rem; } }
      .migrol-rt-content a.call-to-action-button:hover {
        color: #FFF;
        background-color: #445e2a; }
    .migrol-rt-content a.zoom-image {
      cursor: zoom-in; }
  .migrol-rt-content div.migrol-rt-content__download {
    position: relative; }
    .migrol-rt-content div.migrol-rt-content__download a {
      color: #000;
      text-decoration: underline;
      display: inline-block;
      margin-left: 2.3rem; }
      .migrol-rt-content div.migrol-rt-content__download a:hover {
        color: #a4091e; }
    .migrol-rt-content div.migrol-rt-content__download div.migrol-rt-content__download-icon {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      color: #a4091e;
      background: transparent url(/assets/MigrolContent/img/icon-download-3615ce5826.svg) center center;
      background-size: cover;
      width: 1.7rem;
      height: 1.7rem;
      margin-right: 0.6rem; }
      @media (min-width: 900px) {
        .migrol-rt-content div.migrol-rt-content__download div.migrol-rt-content__download-icon {
          top: 0.5rem; } }
    .migrol-rt-content div.migrol-rt-content__download div.migrol-rt-content__download-size {
      font-size: 1.2rem;
      line-height: 1.2rem;
      display: block;
      margin-left: 2.3rem; }
  .migrol-rt-content div.migrol-rt-content__hotline {
    display: inline-flex;
    background-color: #a4091e;
    color: #fff;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    align-items: center;
    padding: 1rem 1rem 0.5rem 1rem;
    line-height: 1.15; }
    .migrol-rt-content div.migrol-rt-content__hotline-icon {
      font-size: 3rem;
      margin-right: 1rem;
      flex: none; }
    .migrol-rt-content div.migrol-rt-content__hotline-title {
      font-size: 1.6rem;
      white-space: nowrap; }
    .migrol-rt-content div.migrol-rt-content__hotline-number {
      font-size: 2.0rem;
      white-space: nowrap; }
  .migrol-rt-content blockquote {
    font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.2em;
    line-height: 1.1em;
    color: #4e6c30;
    margin: 1em 0; }
    @media (min-width: 900px) {
      .migrol-rt-content blockquote {
        font-size: 1.3em;
        line-height: 1.2em; } }
    @media (min-width: 1260px) {
      .migrol-rt-content blockquote {
        padding: 0 2.5em; } }
    .migrol-rt-content blockquote footer {
      text-align: right;
      font-size: 0.9em; }
      .migrol-rt-content blockquote footer cite {
        font-style: normal; }
  .migrol-rt-content td {
    vertical-align: top; }
  .migrol-rt-content .neutralTable {
    width: 100% !important;
    height: auto !important;
    border: none;
    color: #000;
    border-collapse: collapse;
    font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.6rem;
    vertical-align: top; }
    @media (min-width: 900px) {
      .migrol-rt-content .neutralTable {
        font-size: 1.8rem; } }
    .migrol-rt-content .neutralTable td, .migrol-rt-content .neutralTable th {
      border: solid 1px #fff;
      padding: 0.5rem 1.3rem;
      font-weight: normal; }
    .migrol-rt-content .neutralTable tr.neutralTableHeaderRow {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      color: #000;
      background-color: #f1f1f1;
      vertical-align: middle; }
      @media (min-width: 900px) {
        .migrol-rt-content .neutralTable tr.neutralTableHeaderRow {
          font-size: 2rem; } }
    .migrol-rt-content .neutralTable td.neutralTableHeaderFirstCol {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      color: #000;
      background-color: #f1f1f1;
      vertical-align: middle; }
      @media (min-width: 900px) {
        .migrol-rt-content .neutralTable td.neutralTableHeaderFirstCol {
          font-size: 2rem; } }
    .migrol-rt-content .neutralTable td.neutralTableHeaderLastCol {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      color: #000;
      background-color: #f1f1f1;
      vertical-align: middle; }
      @media (min-width: 900px) {
        .migrol-rt-content .neutralTable td.neutralTableHeaderLastCol {
          font-size: 2rem; } }
    .migrol-rt-content .neutralTable tr.neutralTableOddRow {
      vertical-align: top; }
    .migrol-rt-content .neutralTable tr.neutralTableEvenRow {
      vertical-align: top; }
    .migrol-rt-content .neutralTable td.neutralTableFirstCol {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      color: #000;
      background-color: #f1f1f1;
      vertical-align: middle; }
      @media (min-width: 900px) {
        .migrol-rt-content .neutralTable td.neutralTableFirstCol {
          font-size: 2rem; } }
    .migrol-rt-content .neutralTable td.neutralTableLastCol {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      color: #000;
      background-color: #f1f1f1;
      vertical-align: middle; }
      @media (min-width: 900px) {
        .migrol-rt-content .neutralTable td.neutralTableLastCol {
          font-size: 2rem; } }
    .migrol-rt-content .neutralTable td.neutralTableOddCol {
      background-color: #f1f1f1; }
    .migrol-rt-content .neutralTable td.neutralTableEvenCol {
      background-color: #f1f1f1; }
    .migrol-rt-content .neutralTable tr.neutralTableFooterRow {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      color: #000;
      background-color: #f1f1f1;
      vertical-align: middle; }
      @media (min-width: 900px) {
        .migrol-rt-content .neutralTable tr.neutralTableFooterRow {
          font-size: 2rem; } }
    .migrol-rt-content .neutralTable td.neutralTableFooterFirstCol {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      color: #000;
      background-color: #f1f1f1;
      vertical-align: middle; }
    .migrol-rt-content .neutralTable td.neutralTableFooterLastCol {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      color: #000;
      background-color: #f1f1f1;
      vertical-align: middle; }
      @media (min-width: 900px) {
        .migrol-rt-content .neutralTable td.neutralTableFooterLastCol {
          font-size: 2rem; } }
  .migrol-rt-content .redTable {
    width: 100% !important;
    height: auto !important;
    border: none;
    color: #000;
    border-collapse: collapse;
    font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.6rem;
    vertical-align: top; }
    @media (min-width: 900px) {
      .migrol-rt-content .redTable {
        font-size: 1.8rem; } }
    .migrol-rt-content .redTable td, .migrol-rt-content .redTable th {
      border: solid 1px #fff;
      padding: 0.5rem 1.3rem;
      font-weight: normal; }
    .migrol-rt-content .redTable tr.redTableHeaderRow {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      color: #fff;
      background-color: #a4091e;
      vertical-align: middle; }
      @media (min-width: 900px) {
        .migrol-rt-content .redTable tr.redTableHeaderRow {
          font-size: 2rem; } }
    .migrol-rt-content .redTable td.redTableHeaderFirstCol {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      color: #fff;
      background-color: #a4091e;
      vertical-align: middle; }
      @media (min-width: 900px) {
        .migrol-rt-content .redTable td.redTableHeaderFirstCol {
          font-size: 2rem; } }
    .migrol-rt-content .redTable td.redTableHeaderLastCol {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      color: #fff;
      background-color: #a4091e;
      vertical-align: middle; }
      @media (min-width: 900px) {
        .migrol-rt-content .redTable td.redTableHeaderLastCol {
          font-size: 2rem; } }
    .migrol-rt-content .redTable tr.redTableOddRow {
      vertical-align: top; }
    .migrol-rt-content .redTable tr.redTableEvenRow {
      vertical-align: top; }
    .migrol-rt-content .redTable td.redTableFirstCol {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      color: #fff;
      background-color: #a4091e;
      vertical-align: middle; }
      @media (min-width: 900px) {
        .migrol-rt-content .redTable td.redTableFirstCol {
          font-size: 2rem; } }
    .migrol-rt-content .redTable td.redTableLastCol {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      color: #fff;
      background-color: #a4091e;
      vertical-align: middle; }
      @media (min-width: 900px) {
        .migrol-rt-content .redTable td.redTableLastCol {
          font-size: 2rem; } }
    .migrol-rt-content .redTable td.redTableOddCol {
      background-color: #f1f1f1; }
    .migrol-rt-content .redTable td.redTableEvenCol {
      background-color: #f1f1f1; }
    .migrol-rt-content .redTable tr.redTableFooterRow {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      color: #fff;
      background-color: #a4091e;
      vertical-align: middle; }
      @media (min-width: 900px) {
        .migrol-rt-content .redTable tr.redTableFooterRow {
          font-size: 2rem; } }
    .migrol-rt-content .redTable td.redTableFooterFirstCol {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      color: #fff;
      background-color: #a4091e;
      vertical-align: middle; }
    .migrol-rt-content .redTable td.redTableFooterLastCol {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      color: #fff;
      background-color: #a4091e;
      vertical-align: middle; }
      @media (min-width: 900px) {
        .migrol-rt-content .redTable td.redTableFooterLastCol {
          font-size: 2rem; } }
  .migrol-rt-content .grayTable {
    width: 100% !important;
    height: auto !important;
    border: none;
    color: #000;
    border-collapse: collapse;
    font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.6rem;
    vertical-align: top; }
    @media (min-width: 900px) {
      .migrol-rt-content .grayTable {
        font-size: 1.8rem; } }
    .migrol-rt-content .grayTable td, .migrol-rt-content .grayTable th {
      border: solid 1px #fff;
      padding: 0.5rem 1.3rem;
      font-weight: normal; }
    .migrol-rt-content .grayTable tr.grayTableHeaderRow {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      color: #fff;
      background-color: #7f7f7f;
      vertical-align: middle; }
      @media (min-width: 900px) {
        .migrol-rt-content .grayTable tr.grayTableHeaderRow {
          font-size: 2rem; } }
    .migrol-rt-content .grayTable td.grayTableHeaderFirstCol {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      color: #fff;
      background-color: #7f7f7f;
      vertical-align: middle; }
      @media (min-width: 900px) {
        .migrol-rt-content .grayTable td.grayTableHeaderFirstCol {
          font-size: 2rem; } }
    .migrol-rt-content .grayTable td.grayTableHeaderLastCol {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      color: #fff;
      background-color: #7f7f7f;
      vertical-align: middle; }
      @media (min-width: 900px) {
        .migrol-rt-content .grayTable td.grayTableHeaderLastCol {
          font-size: 2rem; } }
    .migrol-rt-content .grayTable tr.grayTableOddRow {
      vertical-align: top; }
    .migrol-rt-content .grayTable tr.grayTableEvenRow {
      vertical-align: top; }
    .migrol-rt-content .grayTable td.grayTableFirstCol {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      color: #fff;
      background-color: #7f7f7f;
      vertical-align: middle; }
      @media (min-width: 900px) {
        .migrol-rt-content .grayTable td.grayTableFirstCol {
          font-size: 2rem; } }
    .migrol-rt-content .grayTable td.grayTableLastCol {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      color: #fff;
      background-color: #7f7f7f;
      vertical-align: middle; }
      @media (min-width: 900px) {
        .migrol-rt-content .grayTable td.grayTableLastCol {
          font-size: 2rem; } }
    .migrol-rt-content .grayTable td.grayTableOddCol {
      background-color: #f1f1f1; }
    .migrol-rt-content .grayTable td.grayTableEvenCol {
      background-color: #f1f1f1; }
    .migrol-rt-content .grayTable tr.grayTableFooterRow {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      color: #fff;
      background-color: #7f7f7f;
      vertical-align: middle; }
      @media (min-width: 900px) {
        .migrol-rt-content .grayTable tr.grayTableFooterRow {
          font-size: 2rem; } }
    .migrol-rt-content .grayTable td.grayTableFooterFirstCol {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      color: #fff;
      background-color: #7f7f7f;
      vertical-align: middle; }
    .migrol-rt-content .grayTable td.grayTableFooterLastCol {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      color: #fff;
      background-color: #7f7f7f;
      vertical-align: middle; }
      @media (min-width: 900px) {
        .migrol-rt-content .grayTable td.grayTableFooterLastCol {
          font-size: 2rem; } }

.contentblock--gray .migrol-rt-content--emphasis {
  background-color: #fff; }

.migrol-body .neutralTable, .migrol-body .redTable, .migrol-body .grayTable {
  display: block;
  overflow-x: auto; }
  .migrol-body .neutralTable tbody, .migrol-body .redTable tbody, .migrol-body .grayTable tbody {
    display: table;
    width: 100%; }

.columns__col .migrol-rt-content {
  margin-bottom: 0; }

.top-news {
  background-color: #f1f1f1;
  padding: 3rem 2.6rem; }
  @media (min-width: 900px) {
    .top-news {
      padding: 4rem 0; } }
  .top-news__content {
    position: relative;
    background-color: #fff;
    padding: 0.7rem 1rem; }
    @media (min-width: 900px) {
      .top-news__content {
        max-width: 80rem;
        margin: 0 auto;
        padding: 2rem 2rem; } }
  .top-news__title-1 {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 2rem;
    color: #a4091e;
    margin-bottom: 0.5rem;
    margin-right: 2rem; }
    @media (min-width: 900px) {
      .top-news__title-1 {
        font-size: 2.2rem;
        margin-bottom: 1rem; } }
  .top-news__title-2 {
    display: block;
    font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal; }
  .top-news__copy {
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-bottom: 0.5rem; }
    @media (min-width: 900px) {
      .top-news__copy {
        margin-bottom: 1rem; } }
  .top-news__link {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.6rem;
    color: #000;
    text-decoration: none; }
  .top-news__close-button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    text-align: inherit;
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;
    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;
    /* Remove excess padding and border in Firefox 4+ */
    position: absolute;
    top: 0.7rem;
    right: 1rem;
    font-size: 1.6rem; }
    .top-news__close-button::-moz-focus-inner {
      border: 0;
      padding: 0; }
    @media (min-width: 900px) {
      .top-news__close-button {
        top: 2rem;
        right: 2rem; } }

.footer-links {
  list-style: none;
  margin: 0;
  padding: 0; }
  @media (min-width: 900px) {
    .footer-links {
      display: flex;
      max-width: 1260px;
      margin: 0 auto; } }
  .footer-links__col {
    padding: 0;
    margin: 0;
    border-top: solid 1px #7f7f7f; }
    @media (min-width: 900px) {
      .footer-links__col {
        flex: 1;
        border-top: none;
        margin-right: 2rem; }
        .footer-links__col:last-child {
          margin-right: 0; } }
  .footer-links__label {
    display: table;
    width: 100%;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.6rem;
    cursor: pointer;
    padding: 0 2rem 0 0.5rem;
    user-select: none;
    position: relative;
    height: 5rem; }
    @media (min-width: 900px) {
      .footer-links__label {
        height: 5.5rem;
        cursor: auto;
        padding: 0;
        font-size: 2rem;
        line-height: normal; } }
    .footer-links__label .svg-inline--fa {
      color: #7f7f7f;
      position: absolute;
      right: 0rem;
      top: 50%;
      margin-top: -7px;
      font-size: 1.4rem;
      transform: rotate(0deg);
      float: right;
      transition: transform 0.5s; }
      @media (min-width: 900px) {
        .footer-links__label .svg-inline--fa {
          display: none; } }
  .footer-links__label-text {
    display: table-cell;
    vertical-align: middle; }
    @media (min-width: 900px) {
      .footer-links__label-text {
        display: block;
        vertical-align: top; } }
  .footer-links__input {
    display: none; }
  .footer-links__content-container {
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0.075, 1.06, 0.18, 0.965);
    overflow: hidden; }
    @media (min-width: 900px) {
      .footer-links__content-container {
        max-height: none;
        overflow: auto;
        transition: none; } }
  .footer-links__content {
    padding-left: 0.5rem;
    font-size: 1.6rem; }
    @media (min-width: 900px) {
      .footer-links__content {
        padding-left: 0;
        font-size: 1.4rem; } }
    .footer-links__content p {
      margin: 0 0 2rem 0;
      padding: 0; }
  .footer-links__content-red {
    color: #a4091e; }
  .footer-links__no-underline {
    text-decoration: none; }
  .footer-links__link {
    display: block;
    color: #000;
    text-decoration: none;
    padding-bottom: 1rem; }
    @media (min-width: 900px) {
      .footer-links__link {
        padding-bottom: 1rem; } }
    .footer-links__link:hover {
      color: #a4091e; }
  .footer-links__input:checked + .footer-links__label .svg-inline--fa {
    transform: rotate(180deg);
    transition: transform 0.5s; }
  .footer-links__input:checked ~ .footer-links__content-container {
    max-height: 2000px;
    transition: max-height 0.5s cubic-bezier(0.885, 0, 1, 0.065); }
    @media (min-width: 900px) {
      .footer-links__input:checked ~ .footer-links__content-container {
        max-height: none;
        transition: none; } }

.footer-external-links {
  padding: 6rem 0;
  border-top: solid 1px #7f7f7f; }
  @media (min-width: 900px) {
    .footer-external-links {
      display: flex;
      justify-content: space-between;
      border-top: none; } }
  @media (min-width: 900px) {
    .footer-external-links__col {
      flex: none;
      max-width: 25%; } }
  .footer-external-links__title {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 2rem;
    margin-bottom: 1rem; }
  .footer-external-links__content + .footer-external-links__title {
    margin-top: 3rem; }
  .footer-external-links__content {
    font-size: 1.4rem;
    margin-bottom: 3rem;
    max-width: 60rem; }
    .footer-external-links__content::after {
      content: "";
      display: table;
      clear: both; }
    @media (min-width: 900px) {
      .footer-external-links__content {
        margin-bottom: 0;
        max-width: none; } }
  .footer-external-links__link {
    text-decoration: none;
    color: #000; }

.columns {
  margin-bottom: 3.75rem; }
  @media (min-width: 900px) {
    .columns {
      margin-bottom: 3.75rem; } }
  @media (min-width: 900px) {
    .columns {
      margin-left: 0;
      margin-right: 0; } }
  .columns + .columns {
    margin-top: -2.55rem; }
  .columns--one-col {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-gap: 1.2rem; }
  .columns--two-col-1-1 {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    grid-gap: 1.2rem; }
    @media (min-width: 900px) {
      .columns--two-col-1-1 {
        grid-template-columns: repeat(2, calc((100% - 1.2rem) / 2));
        grid-template-rows: auto; } }
  .columns--two-col-2-1 {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    grid-gap: 1.2rem; }
    @media (min-width: 900px) {
      .columns--two-col-2-1 {
        display: grid;
        grid-gap: 1.2rem;
        grid-template-areas: "left left right";
        grid-template-rows: auto;
        grid-template-columns: repeat(3, calc((100% - 2.4rem) / 3)); }
        .columns--two-col-2-1 .columns__col:first-child {
          grid-area: left; }
        .columns--two-col-2-1 .columns__col:last-child {
          grid-area: right; } }
  .columns--two-col-1-2 {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    grid-gap: 1.2rem; }
    @media (min-width: 900px) {
      .columns--two-col-1-2 {
        display: grid;
        grid-gap: 1.2rem;
        grid-template-areas: "left right right";
        grid-template-rows: auto;
        grid-template-columns: repeat(3, calc((100% - 2.4rem) / 3)); }
        .columns--two-col-1-2 .columns__col:first-child {
          grid-area: left; }
        .columns--two-col-1-2 .columns__col:last-child {
          grid-area: right; } }
  .columns--two-col-3-1 {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    grid-gap: 1.2rem; }
    @media (min-width: 900px) {
      .columns--two-col-3-1 {
        display: grid;
        grid-gap: 1.2rem;
        grid-template-areas: "left left left right";
        grid-template-rows: auto;
        grid-template-columns: repeat(4, calc((100% - 3.6rem) / 4)); }
        .columns--two-col-3-1 .columns__col:first-child {
          grid-area: left; }
        .columns--two-col-3-1 .columns__col:last-child {
          grid-area: right; } }
  .columns--two-col-1-3 {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    grid-gap: 1.2rem; }
    @media (min-width: 900px) {
      .columns--two-col-1-3 {
        display: grid;
        grid-gap: 1.2rem;
        grid-template-areas: "left right right right";
        grid-template-rows: auto;
        grid-template-columns: repeat(4, calc((100% - 3.6rem) / 4)); }
        .columns--two-col-1-3 .columns__col:first-child {
          grid-area: left; }
        .columns--two-col-1-3 .columns__col:last-child {
          grid-area: right; } }
  .columns--three-col {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    grid-gap: 1.2rem; }
    @media (min-width: 900px) {
      .columns--three-col {
        grid-template-columns: repeat(3, calc((100% - 2.4rem) / 3));
        grid-template-rows: auto; } }
  .columns--four-col {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto auto;
    grid-gap: 1.2rem; }
    @media (min-width: 900px) {
      .columns--four-col {
        grid-template-columns: repeat(2, calc((100% - 1.2rem) / 2));
        grid-template-rows: auto auto; } }
    @media (min-width: 1260px) {
      .columns--four-col {
        grid-template-columns: repeat(4, calc((100% - 3.6rem) / 4));
        grid-template-rows: auto; } }

.teaser {
  height: 100%;
  min-height: 30rem;
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer; }
  @media (min-width: 900px) {
    .teaser {
      min-height: 40rem; } }
  .teaser__background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
    transform: scale(1, 1);
    transition: transform 0.5s ease-out;
    z-index: -1; }
  .teaser:hover .teaser__background {
    transition: transform 0.5s ease-out;
    transform: scale(1.03, 1.03); }
  .teaser:hover .teaser__link {
    color: #a4091e; }
  .teaser__tooltip {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    text-align: inherit;
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;
    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;
    /* Remove excess padding and border in Firefox 4+ */
    display: block;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 2rem;
    z-index: 2; }
    .teaser__tooltip::-moz-focus-inner {
      border: 0;
      padding: 0; }
    @media (min-width: 900px) {
      .teaser__tooltip {
        top: 2rem;
        right: 2rem; } }
  .teaser__content {
    display: flex;
    flex-direction: column;
    min-height: 14rem;
    max-width: 55.4rem;
    margin: 1.5rem; }
    @media (min-width: 900px) {
      .teaser__content {
        margin: 2rem; } }
  .teaser__title-1 {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 2rem;
    margin-bottom: 0.5rem; }
    @media (min-width: 900px) {
      .teaser__title-1 {
        font-size: 2.2rem; } }
  .teaser__title-2 {
    font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    display: block; }
  .teaser__copy {
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-bottom: 1rem; }
    @media (min-width: 900px) {
      .teaser__copy {
        font-size: 1.8rem; } }
  .teaser__link {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.6rem;
    margin-top: auto;
    text-decoration: none; }
  .teaser__arrow {
    margin-left: .2rem;
    position: relative;
    top: 1px; }
  .teaser--with-image {
    flex-direction: column-reverse;
    justify-content: flex-start; }
    .teaser--with-image .teaser__image-container {
      max-width: 55.4rem;
      margin: 1.5rem 1.5rem 0 1.5rem;
      flex: none; }
      @media (min-width: 900px) {
        .teaser--with-image .teaser__image-container {
          margin: 2rem 2rem 0 2rem; } }
    .teaser--with-image .teaser__image {
      width: 100%;
      height: auto;
      display: inline-block; }
  .teaser--box {
    padding-top: 15rem; }
    @media (min-width: 900px) {
      .teaser--box {
        padding-top: 0; } }
    .teaser--box .teaser__content {
      padding: 1rem;
      background-color: rgba(255, 255, 255, 0.9); }
      @media (min-width: 900px) {
        .teaser--box .teaser__content {
          padding: 2rem; } }
    .teaser--box .teaser__title-1 {
      color: #a4091e; }
    .teaser--box .teaser__copy, .teaser--box .teaser__link {
      color: #000; }
  .teaser--box-small {
    min-height: auto; }
    .teaser--box-small .teaser__content {
      padding: 1rem;
      background-color: rgba(255, 255, 255, 0.9);
      max-width: none;
      min-height: auto; }
      @media (min-width: 900px) {
        .teaser--box-small .teaser__content {
          padding: 2rem; } }
    .teaser--box-small .teaser__title-1 {
      color: #a4091e; }
    .teaser--box-small .teaser__copy, .teaser--box-small .teaser__link {
      display: none; }
  .teaser--no-box-white .teaser__content {
    justify-content: flex-end; }
    @media (min-width: 900px) {
      .teaser--no-box-white .teaser__content {
        padding: 2rem; } }
  .teaser--no-box-white .teaser__tooltip {
    color: #fff; }
  .teaser--no-box-white .teaser__title-1, .teaser--no-box-white .teaser__copy, .teaser--no-box-white .teaser__link {
    color: #fff;
    margin-top: 0; }
  .teaser--no-box-white:hover .teaser__link {
    color: #fff;
    text-decoration: underline; }
  .teaser--no-box-black .teaser__content {
    justify-content: flex-end; }
    @media (min-width: 900px) {
      .teaser--no-box-black .teaser__content {
        padding: 2rem; } }
  .teaser--no-box-black .teaser__title-1, .teaser--no-box-black .teaser__copy, .teaser--no-box-black .teaser__link {
    color: #000;
    margin-top: 0; }
  .teaser--no-box-black:hover .teaser__link {
    color: #000;
    text-decoration: underline; }
  .teaser--no-box-gray {
    min-height: 0;
    justify-content: flex-start;
    background-color: #e6e6e6; }
    .teaser--no-box-gray .teaser__background {
      position: relative;
      background-size: cover;
      background-position: center center;
      transform: none;
      transition: none;
      z-index: auto;
      overflow: hidden;
      height: 0; }
    .teaser--no-box-gray:hover .teaser__background {
      transition: none;
      transform: none; }
    .teaser--no-box-gray .teaser__title-1 {
      color: #a4091e; }
    .teaser--no-box-gray .teaser__copy, .teaser--no-box-gray .teaser__link {
      color: #000; }
  .teaser--product {
    min-height: 0;
    justify-content: flex-start; }
    .teaser--product .teaser__background {
      position: relative;
      background-size: cover;
      background-position: center center;
      transform: none;
      transition: none;
      z-index: auto;
      border: 2px solid #e6e6e6;
      overflow: hidden;
      height: 0; }
    .teaser--product:hover .teaser__background {
      transition: none;
      transform: none; }
    .teaser--product .teaser__title-1 {
      color: #a4091e; }
    .teaser--product .teaser__copy, .teaser--product .teaser__link {
      color: #000; }
  .teaser--no-link {
    cursor: default; }

.teaser--box.teaser--with-image {
  padding-top: 0; }

/*teaser styles for all the column widths*/
.col--small .teaser__image {
  width: 100%; }

.col--medium .teaser__image-container {
  max-width: none;
  text-align: center; }

.col--medium .teaser__image {
  width: 75%; }

.col--large .teaser, .col--x-large .teaser {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end; }
  .col--large .teaser--no-box-gray, .col--x-large .teaser--no-box-gray {
    flex-direction: column;
    align-items: initial; }
    .col--large .teaser--no-box-gray .teaser__background, .col--x-large .teaser--no-box-gray .teaser__background {
      background-size: contain;
      background-repeat: no-repeat;
      padding-top: 25% !important; }
  .col--large .teaser__image-container, .col--x-large .teaser__image-container {
    margin-bottom: 1.5rem;
    text-align: right;
    flex: 1; }
    @media (min-width: 900px) {
      .col--large .teaser__image-container, .col--x-large .teaser__image-container {
        margin-bottom: 2rem; } }
  .col--large .teaser__content, .col--x-large .teaser__content {
    flex: 1; }
  .col--large .teaser__image, .col--x-large .teaser__image {
    width: 100%; }

.col--x-large .teaser__image {
  width: 75%; }

.teaser-carousel {
  position: relative;
  min-height: 5rem;
  margin-bottom: 3.75rem;
  padding: 0 2.6rem; }
  @media (min-width: 900px) {
    .teaser-carousel {
      margin-bottom: 3.75rem; } }
  @media (min-width: 900px) {
    .teaser-carousel {
      padding: 0 3rem; } }
  .teaser-carousel__item {
    display: none; }
    .teaser-carousel__item:first-child {
      display: block; }
  .teaser-carousel__items.slick-initialized .teaser-carousel__item {
    display: block; }
  .teaser-carousel__pager {
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    position: relative;
    bottom: -1rem;
    width: 100%; }
    .teaser-carousel__pager li {
      position: relative;
      display: inline-block;
      height: 30px;
      width: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer; }
      .teaser-carousel__pager li button {
        border: 0;
        background: #ccc;
        display: block;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        outline: none;
        line-height: 0px;
        font-size: 0px;
        color: transparent;
        padding: 5px;
        cursor: pointer; }
        @media (hover: hover) {
          .teaser-carousel__pager li button:hover, .teaser-carousel__pager li button:focus {
            outline: none;
            background-color: #999 !important; } }
      .teaser-carousel__pager li.slick-active button {
        background-color: #999 !important; }

.home .teaser-carousel {
  max-width: 1260px;
  margin-left: auto;
  margin-right: auto; }

.section-header {
  margin-bottom: 3.75rem;
  position: relative; }
  @media (min-width: 900px) {
    .section-header {
      margin-bottom: 3.75rem; } }
  .section-header h1 {
    font-size: 2.8rem; }
    @media (min-width: 900px) {
      .section-header h1 {
        font-size: 3.8rem; } }
  .section-header h2 {
    font-size: 2.4rem; }
    @media (min-width: 900px) {
      .section-header h2 {
        font-size: 3.0rem; } }
  .section-header h3 {
    font-size: 2.2rem; }
    @media (min-width: 900px) {
      .section-header h3 {
        font-size: 2.2rem; } }
  .section-header h4, .section-header h5, .section-header h6 {
    font-size: 2.0rem; }
    @media (min-width: 900px) {
      .section-header h4, .section-header h5, .section-header h6 {
        font-size: 1.8rem; } }
  .section-header__title-1 {
    margin-bottom: 2.5rem;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal; }
    .section-header__title-1--red {
      color: #a4091e; }
    .section-header__title-1--blog {
      margin-bottom: 1rem; }
  .section-header__title-2 {
    font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal; }
    .section-header__title-2--block {
      display: block; }
  .section-header__lead {
    font-size: 2rem;
    line-height: 2.8rem; }
    @media (min-width: 900px) {
      .section-header__lead {
        font-size: 2.4rem;
        line-height: 3.4rem; } }
  .section-header--no-margin-bottom {
    margin-bottom: 1rem; }
    .section-header--no-margin-bottom h1:last-child, .section-header--no-margin-bottom h2:last-child, .section-header--no-margin-bottom h3:last-child, .section-header--no-margin-bottom h4:last-child, .section-header--no-margin-bottom h5:last-child, .section-header--no-margin-bottom h6:last-child, .section-header--no-margin-bottom__lead:last-child {
      margin-bottom: 0; }

.ts-finder-teaser {
  background: url(/assets/MigrolTankstellensuche/img/bg-ts-finder-teaser-f856b14592.jpg) no-repeat right top;
  background-size: cover;
  min-height: 30rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 100%; }
  @media (min-width: 900px) {
    .ts-finder-teaser {
      min-height: 40rem;
      padding: 3rem; } }
  .ts-finder-teaser__content {
    max-width: 60rem; }
  .ts-finder-teaser__title-1 {
    font-size: 2.2rem;
    color: #a4091e;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    margin-bottom: 2rem; }
  .ts-finder-teaser__title-2 {
    font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    display: block; }
  .ts-finder-teaser__form {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    padding: 0 1.5rem 1.5rem; }
  .ts-finder-teaser__radios {
    white-space: nowrap;
    height: 4rem;
    overflow: hidden;
    margin: 1.5rem 0.5rem 0 0; }
    @media (min-width: 900px) {
      .ts-finder-teaser__radios {
        margin-right: 2rem; } }
  .ts-finder-teaser__radio-label {
    display: inline-block;
    background-color: #e6e6e6;
    color: #000;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    cursor: pointer;
    margin-right: 0.5rem; }
    .ts-finder-teaser__radio-label svg {
      pointer-events: none; }
  .ts-finder-teaser__radio {
    display: none; }
  .ts-finder-teaser__radio:checked + .ts-finder-teaser__radio-label {
    background-color: #a4091e;
    color: #fff; }
  .ts-finder-teaser__inputs {
    display: flex;
    flex: 1;
    white-space: nowrap;
    margin: 1.5rem 0 0;
    min-width: 20rem; }
  .ts-finder-teaser__textbox {
    flex: 1;
    font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    line-height: 3.8rem;
    padding: 0 0.5rem;
    border: solid 1px #b7b7b7;
    outline: none;
    font-size: 1.6rem; }
    @media (max-width: 320px) {
      .ts-finder-teaser__textbox {
        /*iphone 5 special*/
        font-size: 1.3rem;
        flex: none; } }
    .ts-finder-teaser__textbox::placeholder {
      color: #7f7f7f; }
  .ts-finder-teaser__button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    text-align: inherit;
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;
    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;
    /* Remove excess padding and border in Firefox 4+ */
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    line-height: 4rem;
    color: #fff;
    background-color: #4e6c30;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    font-size: 1.6rem; }
    .ts-finder-teaser__button::-moz-focus-inner {
      border: 0;
      padding: 0; }
    .ts-finder-teaser__button:hover {
      background-color: #445e2a; }
  .ts-finder-teaser__button-text {
    display: none; }

.hero-wrapper .ts-finder-teaser {
  background: url(/assets/MigrolTankstellensuche/img/bg-ts-finder-teaser-hero-a3b59fa036.jpg) no-repeat right top;
  background-size: cover; }
  .hero-wrapper .ts-finder-teaser__content {
    width: 100%;
    margin: 0 auto; }
    @media (min-width: 1260px) {
      .hero-wrapper .ts-finder-teaser__content {
        max-width: 1260px; } }
  .hero-wrapper .ts-finder-teaser__form {
    max-width: 60rem; }

@media (min-width: 900px) {
  .col--large .ts-finder-teaser, .col--x-large .ts-finder-teaser {
    padding: 3rem 9rem; }
    .col--large .ts-finder-teaser__title-1, .col--x-large .ts-finder-teaser__title-1 {
      font-size: 2.8rem; }
    .col--large .ts-finder-teaser__button-text, .col--x-large .ts-finder-teaser__button-text {
      display: inline; }
    .col--large .ts-finder-teaser__button-icon, .col--x-large .ts-finder-teaser__button-icon {
      display: none; }
  .col--x-large .ts-finder-teaser__title-1 {
    font-size: 3.4rem; } }

.contentblock--gray .ts-finder-teaser {
  background-image: url(/assets/MigrolTankstellensuche/img/bg-ts-finder-teaser-light-ab1072d55a.jpg); }

.layout__mobile-only .ts-finder-teaser {
  margin-bottom: 3rem;
  margin-left: 1.1rem;
  margin-right: 1.1rem; }

.columns__col > .picture {
  margin-bottom: 0; }

.picture {
  position: relative;
  margin: 0 0 0;
  padding: 0;
  margin-bottom: 3.75rem; }
  @media (min-width: 900px) {
    .picture {
      margin-bottom: 3.75rem; } }
  .picture__image {
    overflow: hidden;
    margin-bottom: 1rem; }
    .picture__image--no-legend {
      margin-bottom: 0; }
    .picture__image img {
      max-width: 100%;
      height: auto;
      display: block;
      margin: 0 auto; }
  .picture__caption {
    font-size: 1.6rem;
    align-self: center; }
    @media (min-width: 900px) {
      .picture__caption {
        font-size: 2rem; } }
    .picture__caption--small {
      font-size: 1.3rem; }
      @media (min-width: 900px) {
        .picture__caption--small {
          font-size: 1.6rem; } }
  .picture--caption-bottom-centered .picture__caption {
    text-align: center; }
  @media (min-width: 1260px) {
    .picture--caption-left {
      display: grid;
      grid-gap: 3.2rem;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      grid-template-areas: "caption image"; }
      .picture--caption-left .picture__image {
        grid-area: image;
        margin-bottom: 0; }
      .picture--caption-left .picture__caption {
        grid-area: caption; }
    .picture--caption-right {
      display: grid;
      grid-gap: 3.2rem;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      grid-template-areas: "image caption"; }
      .picture--caption-right .picture__image {
        grid-area: image;
        margin-bottom: 0; }
      .picture--caption-right .picture__caption {
        grid-area: caption; } }

.video {
  position: relative;
  margin-bottom: 3.75rem; }
  @media (min-width: 900px) {
    .video {
      margin-bottom: 3.75rem; } }
  .video__wrapper {
    position: relative;
    padding-bottom: 56.25%;
    /*16:9*/
    height: 0;
    overflow: hidden; }
  .video__iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .video__placeholder {
    display: block;
    font-size: 4rem;
    color: #FFF;
    text-shadow: 0 0 1rem #000;
    transform: rotate(-5deg);
    text-decoration: none;
    position: absolute;
    top: 33%;
    left: 0;
    right: 0;
    text-align: center; }

.wl-teaser {
  position: relative;
  background-color: #666;
  background-size: cover;
  background-position: center;
  padding: 2.6rem;
  height: 100%; }
  .wl-teaser__title-1 {
    font-size: 2.2rem;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    color: #FFF; }
  .wl-teaser__title-2 {
    display: block;
    font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal; }
  .wl-teaser__lead {
    margin-top: 1.5rem;
    color: #fff;
    font-size: 1.6rem;
    line-height: 2.4rem; }
  .wl-teaser__items {
    position: relative;
    margin: 3rem 0; }
  .wl-teaser__item {
    text-align: center;
    width: 20%;
    display: inline-block;
    visibility: hidden; }
  .wl-teaser__icon {
    position: relative;
    border: 2px solid #fff;
    border-radius: 50%;
    width: 50%;
    height: 0;
    padding-top: 50%;
    margin: 0 auto;
    color: #fff; }
  .wl-teaser__image {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -45%;
    margin-left: -45%;
    max-width: 90%;
    height: auto; }
  .wl-teaser__step-text {
    color: #fff;
    font-size: 1.6rem;
    margin-top: 2rem; }
  .wl-teaser__call-to-action {
    text-align: center; }
  .wl-teaser__call-to-action-link {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.4rem;
    border: 1px solid #fff;
    display: inline-block; }
    .wl-teaser__call-to-action-link:hover {
      background-color: rgba(255, 255, 255, 0.2); }
    .wl-teaser__call-to-action-link a, .wl-teaser__call-to-action-link .scTextWrapper {
      display: inline-block;
      padding: 1rem 2rem;
      text-decoration: none;
      color: #fff; }
  .wl-teaser__button {
    position: absolute;
    color: #fff;
    font-weight: bold;
    text-align: center;
    font-size: 24px;
    background: none;
    border: none;
    z-index: 2;
    outline: none;
    height: 70%; }
    .wl-teaser__button--previous {
      left: 0;
      top: 0; }
    .wl-teaser__button--next {
      right: 0;
      top: 0; }
    .wl-teaser__button.slick-disabled {
      display: none !important; }
  .wl-teaser__pager {
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 2rem 0 0 0; }
    .wl-teaser__pager li {
      position: relative;
      display: inline-block;
      height: 30px;
      width: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer; }
      .wl-teaser__pager li button {
        border: 0;
        background: #ccc;
        display: block;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        outline: none;
        line-height: 0px;
        font-size: 0px;
        color: transparent;
        padding: 5px;
        cursor: pointer; }
        @media (hover: hover) {
          .wl-teaser__pager li button:hover, .wl-teaser__pager li button:focus {
            outline: none;
            background-color: #999 !important; } }
      .wl-teaser__pager li.slick-active button {
        background-color: #999 !important; }

.slick-initialized .wl-teaser__item {
  visibility: visible; }

.contentblock__content > .wl-teaser {
  margin-bottom: 3.75rem;
  margin-left: -2.6rem;
  margin-right: -2.6rem; }
  @media (min-width: 900px) {
    .contentblock__content > .wl-teaser {
      margin-bottom: 3.75rem; } }
  @media (min-width: 900px) {
    .contentblock__content > .wl-teaser {
      margin-left: 0;
      margin-right: 0; } }

@media (min-width: 900px) {
  .contentblock__content > .wl-teaser, .col--x-large > .wl-teaser {
    padding: 6rem 10rem; }
    .contentblock__content > .wl-teaser .wl-teaser__title-1, .col--x-large > .wl-teaser .wl-teaser__title-1 {
      font-size: 3.8rem;
      line-height: normal; }
    .contentblock__content > .wl-teaser .wl-teaser__lead, .col--x-large > .wl-teaser .wl-teaser__lead {
      font-size: 2.4rem;
      line-height: normal;
      max-width: 60rem; }
    .contentblock__content > .wl-teaser .wl-teaser__items, .col--x-large > .wl-teaser .wl-teaser__items {
      margin: 6rem 0; }
    .contentblock__content > .wl-teaser .wl-teaser__icon, .col--x-large > .wl-teaser .wl-teaser__icon {
      width: 66%;
      padding-top: 66%; }
    .contentblock__content > .wl-teaser .wl-teaser__call-to-action, .col--x-large > .wl-teaser .wl-teaser__call-to-action {
      margin-top: 6rem; } }

.col--large .wl-teaser .wl-teaser__title-1 {
  font-size: 2.8rem;
  line-height: normal; }

.col--large .wl-teaser .wl-teaser__lead {
  font-size: 2.0rem;
  line-height: normal;
  max-width: 60rem; }

.col--large .wl-teaser .wl-teaser__items {
  margin: 6rem 0; }

.col--large .wl-teaser .wl-teaser__icon {
  width: 66%;
  padding-top: 66%; }

.home-section__heading {
  margin-bottom: 3rem; }
  @media (min-width: 900px) {
    .home-section__heading {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 5.2rem; } }

.home-section__title-1 {
  font-size: 2rem;
  color: #000;
  font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal; }
  @media (min-width: 900px) {
    .home-section__title-1 {
      font-size: 3.0rem; } }

.home-section__title-2 {
  display: block;
  font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal; }
  @media (min-width: 900px) {
    .home-section__title-2 {
      display: inline; } }

.home-section__overview-link {
  display: none; }
  @media (min-width: 900px) {
    .home-section__overview-link {
      display: inline-block;
      color: #000;
      font-size: 2.2rem;
      text-decoration: none; }
      .home-section__overview-link:hover {
        color: #a4091e; } }

.home-section__arrow {
  display: inline-block;
  width: 5.4rem;
  height: 1.6rem;
  margin-right: 1rem; }

.contact-person {
  margin-bottom: 3.75rem;
  text-align: center; }
  @media (min-width: 900px) {
    .contact-person {
      margin-bottom: 3.75rem; } }
  @media (min-width: 900px) {
    .contact-person {
      text-align: left; } }
  @media (min-width: 900px) {
    .contact-person__layout {
      display: flex; } }
  .contact-person__image {
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 50%;
    height: 0;
    padding-top: 50%;
    margin: 0 auto; }
    @media (min-width: 600px) {
      .contact-person__image {
        width: 16.7rem;
        height: 16.7rem;
        padding-top: 0; } }
    @media (min-width: 900px) {
      .contact-person__image {
        flex: none;
        width: 16.7rem;
        height: 16.7rem;
        padding-top: 0;
        margin: 0 2rem 0 0; } }
  .contact-person__details {
    flex: none; }
    @media (min-width: 900px) {
      .contact-person__details {
        flex: 1; } }
  .contact-person__name {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 2rem;
    margin-top: 2.4rem; }
    @media (min-width: 900px) {
      .contact-person__name {
        font-size: 2.4rem; } }
  .contact-person__address {
    font-size: 1.6rem;
    margin-top: 0.5rem;
    line-height: 1.38; }
    @media (min-width: 900px) {
      .contact-person__address {
        font-size: 1.8rem; } }
    .contact-person__address a {
      text-decoration: none;
      color: #000; }
  .contact-person__call-to-action {
    display: block;
    background-color: #4e6c30;
    color: #fff;
    text-align: center;
    margin-top: 3rem;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.6rem; }
    @media (min-width: 600px) {
      .contact-person__call-to-action {
        display: inline-block; } }
    .contact-person__call-to-action:hover {
      background-color: #445e2a; }
    .contact-person__call-to-action a, .contact-person__call-to-action .scTextWrapper {
      display: block;
      color: #fff;
      text-decoration: none;
      padding: 1.1rem 6rem;
      /*@include for-tablet-portrait-up {
                padding: 0.5rem 8rem;
            }*/ }

.columns__col .mc-login-teaser {
  min-height: 100%; }

.mc-login-teaser {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #e6e6e6;
  padding: 1.5rem; }
  @media (min-width: 900px) {
    .mc-login-teaser {
      padding: 3rem; } }
  .mc-login-teaser__top {
    flex: none; }
  .mc-login-teaser__title {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 3.0rem;
    color: #a4091e;
    margin-bottom: 1.5rem; }
  .mc-login-teaser__text {
    font-size: 1.6rem;
    line-height: 1.33; }
    @media (min-width: 900px) {
      .mc-login-teaser__text {
        font-size: 1.8rem; } }
    .mc-login-teaser__text .migrol-rt-content {
      font-size: 1.6rem;
      line-height: 1.33;
      margin-bottom: 0; }
      @media (min-width: 900px) {
        .mc-login-teaser__text .migrol-rt-content {
          font-size: 1.8rem; } }
  .mc-login-teaser__button {
    flex: none;
    background-color: #4e6c30;
    margin: 2rem; }
    .mc-login-teaser__button:hover {
      background-color: #445e2a; }
    .mc-login-teaser__button a, .mc-login-teaser__button .scTextWrapper {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.6rem;
      display: block;
      padding: 1rem;
      color: #fff;
      text-decoration: none;
      text-align: center; }
  .mc-login-teaser__register {
    flex: none;
    font-size: 1.4rem;
    text-align: center; }
    .mc-login-teaser__register a {
      text-decoration: underline;
      color: #000; }

.contentblock--gray .mc-login-teaser {
  background-color: #fff; }

.col--medium .mc-login-teaser__text, .col--large .mc-login-teaser__text {
  max-width: 60rem; }

.col--medium .mc-login-teaser__button, .col--large .mc-login-teaser__button {
  margin-left: auto;
  margin-right: auto; }
  .col--medium .mc-login-teaser__button a, .col--medium .mc-login-teaser__button .scTextWrapper, .col--large .mc-login-teaser__button a, .col--large .mc-login-teaser__button .scTextWrapper {
    padding: 1rem 10rem; }

.iframe {
  width: 1px;
  min-width: 100%;
  /*Using min-width to set the width of the iFrame, works around an issue in iOS that can prevent the iFrame from sizing correctly.*/
  display: block;
  margin: 0 auto; }
  .iframe__wrapper {
    max-width: 100%;
    /* so it doesnt totally destroy mobile design */
    overflow: hidden;
    /* so it doesnt totally destroy mobile design */
    overflow-x: auto; }
  .iframe--fixedwidth {
    min-width: 0;
    /* remove the max-width */ }

@media (min-width: 900px) {
  .iframe__wrapper {
    overflow-x: hidden; } }

.hotline-button {
  display: none;
  background-color: #a4091e;
  color: #fff;
  font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  align-items: center;
  padding: 1rem 1rem 0.5rem 1rem; }
  .hotline-button__icon {
    font-size: 3rem;
    margin-right: 1rem;
    flex: none; }
  .hotline-button__title {
    font-size: 1.6rem;
    white-space: nowrap; }
  .hotline-button__number {
    font-size: 2.0rem;
    white-space: nowrap; }

@media (min-width: 900px) {
  .hotline-button--desktop {
    display: flex;
    position: absolute;
    right: 3rem; } }

@media (max-width: 899px) {
  .hotline-button--mobile {
    display: flex;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -2.7rem;
    height: 5.4rem;
    cursor: pointer; }
    .hotline-button--mobile .hotline-button__details {
      display: none; }
  .hotline-button--active .hotline-button__details {
    display: block; } }

@font-face {
  font-family: "Helvetica Neue Condensed";
  font-weight: 700;
  font-display: fallback;
  src: url("https://cdn.migros.ch/ch.migros/static/fonts/helveticaneue-condensed-bold/helveticaneue-condensed-bold.eot?#iefix");
  src: url("https://cdn.migros.ch/ch.migros/static/fonts/helveticaneue-condensed-bold/helveticaneue-condensed-bold.eot?#iefix") format("embedded-opentype"), url("https://cdn.migros.ch/ch.migros/static/fonts/helveticaneue-condensed-bold/helveticaneue-condensed-bold.woff") format("woff"), url("https://cdn.migros.ch/ch.migros/static/fonts/helveticaneue-condensed-bold/helveticaneue-condensed-bold.ttf") format("truetype"), url("https://cdn.migros.ch/ch.migros/static/fonts/helveticaneue-condensed-bold/helveticaneue-condensed-bold.svg#9d3bb5f2-6932-419e-a56f-382131ffd4b8") format("svg"); }

/* Basic styles */
.a-service-login {
  text-decoration: none !important;
  box-sizing: border-box; }

/* service login atom */
.a-service-login {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  color: #333 !important;
  display: inline-block;
  font-size: 12px;
  height: 22px;
  line-height: 22px;
  padding: 0 !important;
  padding-left: 35px !important;
  position: relative;
  text-align: left;
  font-family: "Helvetica Neue Condensed", Helvetica, Arial !important;
  font-weight: 600;
  text-transform: uppercase; }

.a-service-login:before {
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEyLjQgMTRWNS45TDguOSAxNEg3TDMuNiA1LjlWMTRIMFYwaDQuOGwzLjMgNy45TDExLjUgMEgxNnYxNGgtMy42WiIgZmlsbD0iI0Y2MCIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+) no-repeat 8px 2px;
  background-size: 11px auto;
  border-right: 1px solid #ccc;
  content: '';
  height: 12px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 27px; }

.a-service-login:hover, .a-service-login:active {
  background-color: #f7f7f7;
  color: #333; }

.a-service-login:focus {
  color: #333; }

.a-service-login--big {
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  padding-left: 48px !important; }

.a-service-login--big:before {
  background: transparent url(//www.migros.ch/.resources/m5-relaunch-core-theme/1.57.1-rb5a4f_3401/img/svg/icons/general/icon-m.svg) no-repeat 12px 3px;
  background-size: 15px auto;
  height: 22px;
  width: 38px;
  top: 10px; }

.a-service-login--dark {
  border-color: transparent; }

.a-service-login--disabled {
  color: #adadad; }

.a-service-login--disabled.a-service-login:hover, .a-service-login--disabled.a-service-login:active {
  background-color: #fff;
  color: #adadad; }

.a-service-login--lang-de {
  width: 102px; }

.a-service-login--lang-de.a-service-login--big {
  width: 145px; }

.a-service-login--lang-fr {
  width: 114px;
  /* 114px*/ }

.a-service-login--lang-fr.a-service-login--big {
  width: 148px;
  /* 169px; */ }

.a-service-login--lang-it {
  width: 80px; }

.a-service-login--lang-it.a-service-login--big {
  width: 119px; }

.mlogin__user {
  padding-right: 10px; }

.searchresults {
  font-size: 1.8rem; }
  .searchresults__form {
    position: relative;
    display: flex;
    align-items: stretch;
    background-color: #f1f1f1;
    margin-bottom: 3rem; }
  .searchresults .autocomplete-suggestions {
    left: 0;
    right: 0;
    width: auto !important;
    background-color: #f1f1f1;
    font-size: 1.8rem;
    overflow-y: auto;
    top: 3.7rem; }
    @media (min-width: 900px) {
      .searchresults .autocomplete-suggestions {
        top: 4.1rem; } }
  .searchresults .autocomplete-suggestion {
    cursor: pointer;
    border-top: 1px solid #cdcdcd;
    padding: 1rem; }
    .searchresults .autocomplete-suggestion.autocomplete-selected {
      background-color: #cdcdcd; }
  .searchresults .autocomplete-title {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    color: #a4091e;
    margin-bottom: 0.5rem; }
  .searchresults .autocomplete-highlight {
    font-size: 1.6rem; }
    .searchresults .autocomplete-highlight strong {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal; }
  .searchresults .autocomplete-intro {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    border-top: 1px solid #b7b7b7;
    padding: 1rem; }
  .searchresults__input {
    font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    border: none;
    background-color: transparent;
    outline: none;
    flex: 1;
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding: 1rem; }
    @media (min-width: 900px) {
      .searchresults__input {
        font-size: 2rem;
        line-height: 3rem; } }
    .searchresults__input::placeholder {
      color: #b7b7b7; }
  .searchresults__submit {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    text-align: inherit;
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;
    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;
    /* Remove excess padding and border in Firefox 4+ */
    width: 5.5rem;
    text-align: center;
    font-size: 1.6rem; }
    .searchresults__submit::-moz-focus-inner {
      border: 0;
      padding: 0; }
    @media (min-width: 900px) {
      .searchresults__submit {
        font-size: 2.0rem; } }
  .searchresults__query {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    margin-bottom: 2rem; }
  .searchresults__list {
    list-style: none;
    margin: 3rem 0;
    padding: 0; }
  .searchresults__item {
    margin: 0 0 2rem 0;
    padding: 0 0 2rem 0;
    border-bottom: 1px solid #cdcdcd; }
    .searchresults__item:last-child {
      border-bottom: none; }
  .searchresults__link {
    text-decoration: none;
    color: #000; }
  .searchresults__title {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    color: #a4091e;
    font-size: 2rem; }
  .searchresults__text {
    line-height: 1.3; }
  .searchresults__highlight {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal; }

.searchbox {
  margin-bottom: 3.75rem; }
  @media (min-width: 900px) {
    .searchbox {
      margin-bottom: 3.75rem; } }
  .searchbox__form {
    position: relative;
    display: flex;
    align-items: stretch;
    background-color: #f1f1f1; }
  .searchbox .autocomplete-suggestions {
    left: 0;
    right: 0;
    width: auto !important;
    background-color: #f1f1f1;
    font-size: 1.8rem;
    overflow-y: auto;
    top: 3.7rem; }
    @media (min-width: 900px) {
      .searchbox .autocomplete-suggestions {
        top: 4.1rem; } }
  .searchbox .autocomplete-suggestion {
    cursor: pointer;
    border-top: 1px solid #cdcdcd;
    padding: 1rem; }
    .searchbox .autocomplete-suggestion.autocomplete-selected {
      background-color: #cdcdcd; }
  .searchbox .autocomplete-title {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    color: #a4091e;
    margin-bottom: 0.5rem; }
  .searchbox .autocomplete-highlight {
    font-size: 1.6rem; }
    .searchbox .autocomplete-highlight strong {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal; }
  .searchbox .autocomplete-intro {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    border-top: 1px solid #b7b7b7;
    padding: 1rem; }
  .searchbox__input {
    font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    border: none;
    background-color: transparent;
    outline: none;
    flex: 1;
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding: 1rem; }
    @media (min-width: 900px) {
      .searchbox__input {
        font-size: 2rem;
        line-height: 3rem; } }
    .searchbox__input::placeholder {
      color: #b7b7b7; }
  .searchbox__submit {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    text-align: inherit;
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;
    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;
    /* Remove excess padding and border in Firefox 4+ */
    width: 5.5rem;
    text-align: center;
    font-size: 1.6rem; }
    .searchbox__submit::-moz-focus-inner {
      border: 0;
      padding: 0; }
    @media (min-width: 900px) {
      .searchbox__submit {
        font-size: 2.0rem; } }

.oil-finder__filters {
  background-color: #e6e6e6;
  padding: 3rem;
  margin-bottom: 3rem; }
  @media (min-width: 600px) {
    .oil-finder__filters {
      display: flex;
      column-gap: 3rem; } }
  .oil-finder__filters .migrol-form__input-label {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.6rem; }
    @media (min-width: 900px) {
      .oil-finder__filters .migrol-form__input-label {
        font-size: 2rem; } }
  .oil-finder__filters .v-select {
    background-color: #fff; }

@media (max-width: 899px) {
  .oil-finder__filter {
    margin-bottom: 1rem; } }

@media (min-width: 600px) {
  .oil-finder__filter {
    flex: 1; } }

.oil-finder__no-results {
  background-color: #e6e6e6;
  width: 100%;
  font-size: 1.6rem;
  line-height: 2.2rem;
  padding: 3rem;
  margin: 3rem 0; }

.oil-finder__oil {
  background-color: #e6e6e6;
  width: 100%;
  font-size: 1.6rem;
  line-height: 2.2rem;
  padding: 3rem;
  margin: 3rem 0; }
  @media (min-width: 600px) {
    .oil-finder__oil {
      font-size: 2rem;
      line-height: 3rem; } }
  .oil-finder__oil-columns {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    grid-gap: 3rem; }
    @media (min-width: 600px) {
      .oil-finder__oil-columns {
        display: grid;
        grid-gap: 3rem;
        grid-template-areas: "left right right";
        grid-template-rows: auto;
        grid-template-columns: repeat(3, calc((100% - 6rem) / 3)); }
        .oil-finder__oil-columns > :first-child {
          grid-area: left; }
        .oil-finder__oil-columns > :last-child {
          grid-area: right; } }
  .oil-finder__oil-image {
    max-width: 100%;
    width: 100%;
    height: auto; }
  .oil-finder__oil-title {
    color: #a4091e;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.6rem;
    margin-bottom: 2rem; }
    @media (min-width: 600px) {
      .oil-finder__oil-title {
        font-size: 2rem;
        margin-bottom: 3rem; } }
  .oil-finder__oil-subtitle {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal; }
  .oil-finder__oil-text {
    margin-bottom: 2rem; }
    .oil-finder__oil-text ul {
      margin-left: 25px; }

.timeline {
  height: 100%;
  min-height: 30rem;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column; }
  @media (min-width: 900px) {
    .timeline {
      min-height: 40rem;
      flex-direction: row; } }
  .timeline__content {
    position: relative;
    padding: 4rem 5rem;
    flex: 1;
    background-color: #f1f1f1;
    font-size: 1.6rem;
    display: flex;
    flex-direction: column; }
    @media (min-width: 900px) {
      .timeline__content {
        flex: 0 0 50%;
        font-size: 1.8rem; } }
    .timeline__content-slide {
      display: none;
      margin-bottom: auto; }
  .timeline__title {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 3.5rem;
    color: #a4091e;
    margin-bottom: 2rem; }
    @media (min-width: 900px) {
      .timeline__title {
        font-size: 4.0rem; } }
  .timeline__subtitle {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.8rem;
    color: #7f7f7f;
    margin-bottom: 2rem; }
    @media (min-width: 900px) {
      .timeline__subtitle {
        font-size: 2rem; } }
  .timeline__text {
    color: #7f7f7f; }
  .timeline__image-wrapper {
    position: relative;
    overflow: hidden;
    flex: 1;
    min-height: 30rem; }
    @media (min-width: 900px) {
      .timeline__image-wrapper {
        flex: 0 0 50%; } }
  .timeline__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
    transform: scale(1, 1);
    transition: transform 0.5s ease-out;
    display: none; }
  .timeline:hover .timeline__image {
    transition: transform 0.5s ease-out;
    transform: scale(1.03, 1.03); }
  .timeline__controls {
    display: flex;
    margin-top: 6rem;
    flex-direction: column; }
    @media (min-width: 900px) {
      .timeline__controls {
        flex-direction: row; } }
    .timeline__controls-slider {
      flex: 1;
      margin-right: 3rem; }
      .timeline__controls-slider-years {
        display: flex;
        justify-content: space-between;
        color: #b7b7b7;
        margin-top: 1.3rem; }
      .timeline__controls-slider-firstyear {
        margin-left: -1.8rem; }
      .timeline__controls-slider-lastyear {
        margin-right: -1.8rem; }
      .timeline__controls-slider-bar {
        background-color: #b7b7b7;
        height: 0.3rem;
        width: 100%;
        border-radius: 0.3rem;
        position: relative; }
        .timeline__controls-slider-bar-inner {
          background-color: #a4091e;
          height: 100%;
          width: 0;
          border-radius: 0.3rem; }
        .timeline__controls-slider-bar-position {
          position: absolute;
          left: 0%;
          top: -0.5rem;
          width: 1.3rem;
          height: 1.3rem;
          margin-left: -0.65rem;
          background-color: #a4091e;
          border-radius: 50%;
          box-shadow: 0 0 0 0.6rem rgba(164, 9, 30, 0.2); }
          .timeline__controls-slider-bar-position-currentyear {
            position: absolute;
            top: -3.5rem;
            left: -100%;
            font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
            font-weight: normal;
            font-style: normal;
            color: #a4091e;
            font-size: 2rem; }
    .timeline__controls-buttons {
      padding-top: 2.5rem; }
    .timeline__controls-button {
      color: #a4091e;
      cursor: pointer;
      background: none;
      border: none;
      font-size: 1.8rem; }
      .timeline__controls-button--disabled {
        cursor: default;
        opacity: 0.4; }

.price-index__tabs {
  border-collapse: collapse;
  display: flex; }

.price-index__tab {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  text-align: inherit;
  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;
  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;
  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;
  /* Remove excess padding and border in Firefox 4+ */
  border: solid 1px #b7b7b7;
  background-color: #cdcdcd;
  text-decoration: none;
  font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1.6rem;
  color: #000;
  padding: 0 1rem;
  line-height: 3.3rem;
  text-align: center;
  flex: 1; }
  .price-index__tab::-moz-focus-inner {
    border: 0;
    padding: 0; }

.price-index__tab--active {
  background-color: transparent;
  border-bottom-color: transparent;
  color: #a4091e; }

.price-index__tab-content {
  display: none;
  padding: 1rem;
  border-left: solid 1px #b7b7b7;
  border-right: solid 1px #b7b7b7;
  border-bottom: solid 1px #b7b7b7; }

.price-index__tab-content--active {
  display: block; }

.price-index__chart {
  width: 100%;
  height: 40rem; }

@media (min-width: 900px) {
  .price-index__chart {
    height: 60rem; }
  .price-index__tab {
    display: inline-block;
    padding: 0 3rem;
    flex: none; }
  .price-index__tab-filler {
    flex: 1;
    border-bottom: solid 1px #b7b7b7; } }

.slider__item {
  position: relative; }

.slider__image {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  height: 50rem; }
  @media (min-width: 900px) {
    .slider__image {
      padding-top: 0; } }

.slider__pager {
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 2rem 0 0; }
  @media (min-width: 900px) {
    .slider__pager {
      position: absolute;
      bottom: 2rem;
      width: 100%; } }
  .slider__pager li {
    position: relative;
    display: inline-block;
    margin: 0 0.5rem;
    padding: 0;
    cursor: pointer; }
    @media (min-width: 900px) {
      .slider__pager li {
        margin: 0 0.8rem; } }
    .slider__pager li button {
      border: 0;
      background: #f1f1f1;
      display: block;
      height: 1.1rem;
      width: 1.1rem;
      border-radius: 50%;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      @media (min-width: 900px) {
        .slider__pager li button {
          height: 1.4rem;
          width: 1.4rem; } }
      @media (hover: hover) {
        .slider__pager li button:hover, .slider__pager li button:focus {
          outline: none;
          background-color: #b7b7b7; } }
  @media (hover: hover) and (min-width: 900px) {
    .slider__pager li button:hover, .slider__pager li button:focus {
      background-color: #fff; } }
    .slider__pager li.slick-active button {
      background-color: #b7b7b7; }
      @media (min-width: 900px) {
        .slider__pager li.slick-active button {
          background-color: #fff; } }

.slider__item:nth-child(n+2) {
  display: none; }

.slick-initialized .slider__item:nth-child(n+2) {
  display: block; }

.boxes {
  margin-bottom: 3.75rem;
  column-gap: 1.2rem;
  grid-template-rows: auto auto auto auto;
  grid-auto-flow: column; }
  @media (min-width: 900px) {
    .boxes {
      margin-bottom: 3.75rem; } }
  .boxes--with-recommendation {
    grid-template-rows: auto auto auto auto auto; }
  @media (min-width: 900px) {
    .boxes .boxes__col {
      display: contents; }
    .boxes--2-col {
      display: grid;
      grid-template-columns: 1fr 1fr; }
      .boxes--2-col .boxes__col {
        display: contents; }
    .boxes--3-col {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr; }
    .boxes--4-col {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto auto auto auto auto auto; } }
  @media (min-width: 900px) and (min-width: 1260px) {
    .boxes--4-col {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: auto auto auto auto; } }
  @media (min-width: 900px) {
      .boxes--4-col .boxes__col:nth-child(even) .boxes__row:first-child {
        margin-top: 1.2rem; } }
    @media (min-width: 900px) and (min-width: 1260px) {
      .boxes--4-col .boxes__col:nth-child(even) .boxes__row:first-child {
        margin-top: unset; } }
  @media (min-width: 900px) {
      .boxes--4-col.boxes--with-recommendation {
        grid-template-rows: auto auto auto auto auto auto auto auto auto auto; } }
    @media (min-width: 900px) and (min-width: 1260px) {
      .boxes--4-col.boxes--with-recommendation {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto auto auto auto auto; } }
  .boxes__col {
    display: flex;
    flex-direction: column;
    margin-top: 1.2rem; }
    .boxes__col--recommended .boxes__row {
      border-left-color: #4e6c30;
      border-right-color: #4e6c30; }
    .boxes__col--recommended .boxes__row--num-4 {
      border-bottom-color: #4e6c30; }
  .boxes__row {
    background-color: #e6e6e6;
    padding-left: 2rem;
    padding-right: 2rem;
    border-left: solid 5px transparent;
    border-right: solid 5px transparent; }
    .boxes__row--num-1 {
      padding-top: 2rem; }
    .boxes__row--num-4 {
      border-bottom: solid 5px transparent;
      padding-bottom: 2rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-end; }
      .boxes__row--num-4:not(:empty) {
        padding-top: 2rem; }
      .boxes__row--num-4 .migrolchrome__placeholder-wrapper {
        width: 100%; }
    .boxes__row--recommended {
      text-align: center;
      background-color: #4e6c30;
      color: #fff;
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 2rem;
      padding-top: 0.5rem;
      padding-bottom: 1rem; }
    .boxes__row--no-bg {
      background-color: transparent; }
    .boxes__row .migrol-rt-content {
      margin-bottom: 0;
      display: contents; }
      .boxes__row .migrol-rt-content p + ol, .boxes__row .migrol-rt-content p + ul {
        margin-top: unset !important; }
      .boxes__row .migrol-rt-content ol, .boxes__row .migrol-rt-content ul {
        margin-bottom: 0 !important; }
    .boxes__row p {
      display: contents; }
    .boxes__row h1, .boxes__row h2, .boxes__row h3, .boxes__row h4, .boxes__row h5, .boxes__row h6 {
      margin-top: 0; }
    .boxes__row a.call-to-action-button {
      position: static;
      transform: none;
      left: auto;
      width: 100%;
      padding: .5rem 1rem; }

.contentblock--gray .boxes__row {
  background-color: #fff; }
  .contentblock--gray .boxes__row--recommended {
    background-color: #4e6c30; }
  .contentblock--gray .boxes__row--no-bg {
    background-color: transparent; }

.contentblock__content--narrow .boxes--3-col {
  display: block; }
  .contentblock__content--narrow .boxes--3-col .boxes__col {
    display: block; }
  @media (min-width: 1260px) {
    .contentblock__content--narrow .boxes--3-col {
      display: grid; }
      .contentblock__content--narrow .boxes--3-col .boxes__col {
        display: contents; } }

.contentblock__content--narrow .boxes--4-col {
  display: block; }
  .contentblock__content--narrow .boxes--4-col .boxes__col {
    display: block; }
  @media (min-width: 1260px) {
    .contentblock__content--narrow .boxes--4-col {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto auto auto auto auto auto; }
      .contentblock__content--narrow .boxes--4-col .boxes__col {
        display: contents; }
      .contentblock__content--narrow .boxes--4-col .boxes__col:nth-child(even) .boxes__row:first-child {
        margin-top: 1.2rem; }
      .contentblock__content--narrow .boxes--4-col.boxes--with-recommendation {
        grid-template-rows: auto auto auto auto auto auto auto auto auto auto; } }

.forms-feature {
  hyphens: manual;
  /*#region Errors*/
  /*#endregion*/ }
  .forms-feature a {
    color: #000; }
  @media (min-width: 900px) {
    .forms-feature__form-wrapper {
      padding-left: 10%;
      padding-right: 10%; } }
  @media (min-width: 1260px) {
    .forms-feature__form-wrapper {
      padding-left: 16.6667%;
      padding-right: 16.6667%; } }
  .forms-feature__field-block {
    padding-top: 5rem;
    padding-bottom: 5rem;
    border-bottom: 1px solid #000; }
    @media (min-width: 900px) {
      .forms-feature__field-block {
        padding-left: 8%;
        padding-right: 8%; } }
    @media (min-width: 1260px) {
      .forms-feature__field-block {
        padding-left: 12.5%;
        padding-right: 12.5%; } }
    .forms-feature__field-block:first-child {
      padding-top: 0; }
    .forms-feature__field-block-title {
      font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      color: #a4091e;
      font-size: 2.4rem;
      margin-bottom: 2.7rem; }
    .forms-feature__field-block-description {
      margin-bottom: 2.7rem; }
    .forms-feature__field-block--no-separator {
      border-bottom: none; }
  .forms-feature__field-row {
    display: flex; }
    .forms-feature__field-row + .forms-feature__field-row {
      margin-top: 2rem; }
  .forms-feature__contact-areas {
    display: flex;
    flex-direction: column; }
    @media (min-width: 600px) {
      .forms-feature__contact-areas {
        flex-direction: row; } }
  .forms-feature__contact-area {
    flex: 1;
    cursor: pointer;
    text-align: center;
    background-color: #e6e6e6;
    padding: 2rem;
    border: 1px solid #999999;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.8rem;
    line-height: 2rem;
    color: #000; }
    .forms-feature__contact-area--selected, .forms-feature__contact-area:hover {
      background-color: #cdcdcd; }
    .forms-feature__contact-area + .forms-feature__contact-area {
      border-top: 0; }
      @media (min-width: 600px) {
        .forms-feature__contact-area + .forms-feature__contact-area {
          border-top: 1px solid #999999;
          border-left: 0; } }
    .forms-feature__contact-area-desc {
      margin-top: 1rem;
      text-align: center; }
  .forms-feature__error-message-block {
    padding: 2rem;
    border: 2px solid #a4091e;
    color: #a4091e;
    background-color: #fff;
    margin-bottom: 3rem; }
    @media (min-width: 900px) {
      .forms-feature__error-message-block {
        width: 50%;
        margin-left: auto;
        margin-right: auto; } }
    .forms-feature__error-message-block li {
      list-style: none; }
  .forms-feature__myclimate-checkbox {
    display: flex;
    align-items: center; }
    .forms-feature__myclimate-checkbox-description {
      margin-left: 2rem; }
  .forms-feature__checkbox-desc {
    display: block; }

/*special paddings, when the form is inside a .columns layout*/
.columns .forms-feature__field-block {
  padding-left: 0;
  padding-right: 0; }

.marktnews {
  position: relative; }
  .marktnews__title-1 {
    font-size: 2.8rem;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    margin-bottom: 1rem;
    color: #a4091e; }
    @media (min-width: 900px) {
      .marktnews__title-1 {
        font-size: 3.8rem; } }
  .marktnews__title-2 {
    font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal; }
  .marktnews__date {
    display: block;
    font-size: 1.6rem;
    margin-bottom: 3rem; }
    @media (min-width: 900px) {
      .marktnews__date {
        margin-bottom: 5rem; } }
  .marktnews__lead {
    font-size: 2rem;
    line-height: 2.8rem;
    margin-bottom: 3rem; }
    @media (min-width: 900px) {
      .marktnews__lead {
        font-size: 2.4rem;
        line-height: 3.4rem;
        margin-bottom: 5rem; } }
  .marktnews__text {
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-bottom: 3rem; }
    @media (min-width: 900px) {
      .marktnews__text {
        font-size: 2rem;
        line-height: 3rem;
        margin-bottom: 5rem; } }
  .marktnews__aside {
    display: block;
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-bottom: 3rem; }
    @media (min-width: 900px) {
      .marktnews__aside {
        font-size: 2rem;
        line-height: 3rem;
        margin-bottom: 5rem; } }
    .marktnews__aside a {
      color: #000;
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      text-decoration: underline; }
      .marktnews__aside a:hover {
        color: #a4091e; }
  .marktnews__footertext {
    display: block;
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-bottom: 2rem; }
    @media (min-width: 900px) {
      .marktnews__footertext {
        font-size: 2rem;
        line-height: 3rem;
        margin-bottom: 4rem; } }
    .marktnews__footertext--small {
      font-size: 1.4rem;
      line-height: normal; }

.marktnews-list {
  margin-bottom: 3.75rem; }
  @media (min-width: 900px) {
    .marktnews-list {
      margin-bottom: 3.75rem; } }
  .marktnews-list__item {
    border-top: 1px solid #b7b7b7;
    padding: 2rem 1.5rem;
    margin: 0 -1.5rem; }
    .marktnews-list__item:last-of-type {
      border-bottom: 1px solid #b7b7b7; }
    @media (min-width: 900px) {
      .marktnews-list__item {
        display: flex;
        padding: 2rem 10rem;
        margin: 0 -10rem; } }
  .marktnews-list__visual {
    width: 5.5rem;
    height: 5.5rem;
    margin-bottom: 1.5rem; }
    @media (min-width: 900px) {
      .marktnews-list__visual {
        flex: none;
        width: 16rem;
        height: 16rem;
        margin-right: 3rem;
        margin-bottom: 0; } }
  .marktnews-list__image--risingstrongly {
    transform: rotate(-45deg);
    color: #a4091e; }
  .marktnews-list__image--risingslightly {
    transform: rotate(-10deg);
    color: #a4091e; }
  .marktnews-list__image--stable {
    color: #7f7f7f; }
  .marktnews-list__image--decliningslightly {
    transform: rotate(10deg);
    color: #4e6c30; }
  .marktnews-list__image--decliningstrongly {
    transform: rotate(45deg);
    color: #4e6c30; }
  @media (min-width: 900px) {
    .marktnews-list__content {
      flex: 1;
      max-width: 53rem; } }
  .marktnews-list__date {
    font-size: 2rem;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    color: #a4091e;
    margin-bottom: 0.5rem; }
    @media (min-width: 900px) {
      .marktnews-list__date {
        font-size: 2.2rem; } }
  .marktnews-list__title {
    font-size: 2rem;
    font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    color: #a4091e;
    margin-bottom: 1.2rem; }
  .marktnews-list__lead {
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-bottom: 1.2rem; }
    @media (min-width: 900px) {
      .marktnews-list__lead {
        font-size: 1.8rem;
        line-height: 2.4rem; } }
  .marktnews-list__trend-text {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal; }
  .marktnews-list__link {
    font-size: 1.6rem;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    color: #000; }
    .marktnews-list__link:hover {
      color: #a4091e; }
  .marktnews-list__footer {
    margin-top: 5rem;
    text-align: center; }
    @media (min-width: 900px) {
      .marktnews-list__footer {
        margin-top: 10rem; } }
  .marktnews-list__button {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.6rem;
    background-color: #cdcdcd;
    color: #000;
    text-decoration: none;
    padding: 1rem 3rem;
    display: inline-block; }
    .marktnews-list__button:hover {
      background-color: #b7b7b7; }

.marktnews-teaser {
  height: 100%;
  background-color: #e6e6e6;
  padding: 1.5rem;
  cursor: pointer;
  background: #e6e6e6 none no-repeat center center;
  background-size: cover; }
  .marktnews-teaser:hover .marktnews-teaser__link {
    color: #a4091e; }
  @media (min-width: 900px) {
    .marktnews-teaser {
      padding: 3rem; } }
  .marktnews-teaser__visual {
    width: 12rem;
    height: 12rem; }
    @media (min-width: 900px) {
      .marktnews-teaser__visual {
        width: 16rem;
        height: 16rem; } }
  .marktnews-teaser__image--risingstrongly {
    transform: rotate(-45deg);
    color: #a4091e; }
  .marktnews-teaser__image--risingslightly {
    transform: rotate(-10deg);
    color: #a4091e; }
  .marktnews-teaser__image--stable {
    color: #7f7f7f; }
  .marktnews-teaser__image--decliningslightly {
    transform: rotate(10deg);
    color: #4e6c30; }
  .marktnews-teaser__image--decliningstrongly {
    transform: rotate(45deg);
    color: #4e6c30; }
  .marktnews-teaser__content {
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.9); }
    @media (min-width: 900px) {
      .marktnews-teaser__content {
        padding: 2rem; } }
  .marktnews-teaser__title-1 {
    font-size: 2rem;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    color: #a4091e;
    margin-bottom: 1.2rem; }
  .marktnews-teaser__title-2 {
    font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal; }
  .marktnews-teaser__lead {
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-bottom: 1.2rem; }
    @media (min-width: 900px) {
      .marktnews-teaser__lead {
        font-size: 1.8rem;
        line-height: 2.4rem; } }
  .marktnews-teaser__link {
    font-size: 1.6rem;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    color: #000; }
    .marktnews-teaser__link:hover {
      color: #a4091e; }
  .marktnews-teaser__arrow {
    margin-left: .2rem;
    position: relative;
    top: 1px; }

.contentblock--gray .marktnews-teaser {
  background-color: #fff; }

.col--small .marktnews-teaser__visual {
  width: 12rem;
  height: 12rem;
  margin-bottom: 1.5rem; }

.col--medium .marktnews-teaser {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .col--medium .marktnews-teaser__visual {
    flex: none;
    width: 12rem;
    height: 12rem;
    margin-bottom: 1.5rem; }
  .col--medium .marktnews-teaser__content {
    flex: none; }

.col--large .marktnews-teaser, .col--x-large .marktnews-teaser {
  display: flex;
  align-items: flex-start; }
  .col--large .marktnews-teaser__visual, .col--x-large .marktnews-teaser__visual {
    flex: none;
    width: 16rem;
    height: 16rem;
    margin-right: 5rem; }
  .col--large .marktnews-teaser__content, .col--x-large .marktnews-teaser__content {
    flex: 1; }
  .col--large .marktnews-teaser__lead, .col--large .marktnews-teaser__title-1, .col--x-large .marktnews-teaser__lead, .col--x-large .marktnews-teaser__title-1 {
    max-width: 53rem; }

.pneu-offer {
  hyphens: manual;
  /*#region Progress Bar*/
  /*#endregion*/
  /*#region Step 1*/
  /*&__btn-dimension-help {
        @include button-reset;
        display: flex;
        align-items: center;

        @include for-tablet-portrait-up {
            margin-top: 3rem;
            margin-left: 2rem;
        }

        @include for-tablet-portrait-down {
            flex-basis: 100%;
            margin-top: 1rem;
            margin-left: 0;
        }

        svg {
            flex: none;
            margin-right: 0.5em;
        }
    }*/
  /*&__dimension-help {
        img {
            margin-bottom: 1em;
        }
    }*/
  /*#endregion*/
  /*#region Step 2*/
  /*#endregion*/
  /*#region Step 3*/
  /*#endregion*/
  /*#region Step 4*/
  /*#endregion*/ }
  .pneu-offer a {
    color: #000; }
  .pneu-offer__progressbar {
    counter-reset: step;
    display: flex;
    padding-top: 1.8rem;
    padding-bottom: 1.2rem; }
    @media (min-width: 900px) {
      .pneu-offer__progressbar {
        padding-top: 2.3rem;
        padding-bottom: 1.3rem; } }
    .pneu-offer__progressbar li {
      list-style-type: none;
      flex: 1;
      float: left;
      font-size: 1.6rem;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      color: #000;
      background-color: transparent; }
      .pneu-offer__progressbar li:before {
        position: relative;
        width: 4rem;
        height: 4rem;
        content: counter(step);
        counter-increment: step;
        line-height: 3.732rem;
        border: 1px solid #000;
        display: block;
        text-align: center;
        margin: 0 auto 1rem auto;
        border-radius: 50%;
        background-color: #e6e6e6;
        z-index: 2;
        font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-style: normal;
        font-size: 2rem; }
        @media (min-width: 900px) {
          .pneu-offer__progressbar li:before {
            width: 4rem;
            height: 4rem;
            line-height: 3.732rem; } }
      .pneu-offer__progressbar li:after {
        display: none;
        width: 100%;
        height: 2px;
        content: '';
        position: absolute;
        background-color: #999999;
        top: 2rem;
        left: -50%;
        z-index: 1; }
        @media (min-width: 900px) {
          .pneu-offer__progressbar li:after {
            top: 2rem; } }
      .pneu-offer__progressbar li:first-child:after {
        content: none; }
      .pneu-offer__progressbar li.active {
        color: #000; }
        .pneu-offer__progressbar li.active:before {
          color: #fff;
          background-color: #000;
          border-color: #000; }
        .pneu-offer__progressbar li.active:after {
          display: block; }
      .pneu-offer__progressbar li.current {
        color: #a4091e; }
        .pneu-offer__progressbar li.current:before {
          color: #fff;
          background-color: #a4091e;
          border-color: #a4091e;
          width: 5rem;
          height: 5rem;
          line-height: 4.665rem;
          margin-top: -0.5rem;
          font-size: 2.7rem; }
        .pneu-offer__progressbar li.current:after {
          display: block; }
        .pneu-offer__progressbar li.current .shop__progressbar_steptext {
          margin-top: 0.5rem;
          font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
          font-weight: normal;
          font-style: normal; }
      .pneu-offer__progressbar li.clickable {
        cursor: pointer; }
    .pneu-offer__progressbar_steptext {
      display: none; }
      @media (min-width: 900px) {
        .pneu-offer__progressbar_steptext {
          display: block;
          margin-top: 1.4rem;
          line-height: 3rem; } }
  .pneu-offer__fieldgroup {
    border: none;
    padding-top: 4.5rem;
    padding-bottom: 4.5rem; }
    @media (min-width: 900px) {
      .pneu-offer__fieldgroup {
        padding-left: 10%;
        padding-right: 10%; } }
    @media (min-width: 1260px) {
      .pneu-offer__fieldgroup {
        padding-left: 16.6667%;
        padding-right: 16.6667%; } }
    .pneu-offer__fieldgroup--with-separator {
      border-bottom: 1px solid #b7b7b7; }
    .pneu-offer__fieldgroup--no-padding-bottom {
      padding-bottom: 0; }
    .pneu-offer__fieldgroup--no-padding-top {
      padding-top: 0; }
    .pneu-offer__fieldgroup--buttons {
      border-top: 1px solid #b7b7b7; }
    .pneu-offer__fieldgroup-title {
      font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      color: #a4091e;
      font-size: 2.4rem;
      margin-bottom: 2.7rem; }
      .pneu-offer__fieldgroup-title--big {
        font-size: 3rem; }
  .pneu-offer__nav-buttons {
    display: flex;
    justify-content: space-between; }
  .pneu-offer__promobanner img {
    width: 100%;
    height: auto;
    max-width: 30rem; }
  .pneu-offer__filter-row {
    margin-bottom: 2rem; }
    .pneu-offer__filter-row .migrol-form__input-label {
      line-height: 2.3rem; }
    @media (min-width: 600px) {
      .pneu-offer__filter-row {
        display: flex;
        flex-wrap: wrap; } }
    @media (max-width: 599px) {
      .pneu-offer__filter-row .migrol-form__input-wrapper {
        margin-left: 0;
        margin-bottom: 2rem; } }
    .pneu-offer__filter-row--3 {
      column-gap: 1rem; }
      .pneu-offer__filter-row--3 .pneu-offer__filter-col {
        flex: 2; }
        @media (max-width: 599px) {
          .pneu-offer__filter-row--3 .pneu-offer__filter-col {
            margin-bottom: 2rem; } }
      .pneu-offer__filter-row--3 .pneu-offer__filter-col:nth-child(2) {
        flex: 3; }
      .pneu-offer__filter-row--3 .migrol-form__checkbox {
        margin-top: 0.5rem; }
  .pneu-offer__filter-dimension {
    font-size: 2.4rem;
    padding: 0 1rem 0.8rem;
    align-self: end; }
    @media (max-width: 599px) {
      .pneu-offer__filter-dimension {
        display: none; } }
  .pneu-offer__btn-additional-filters {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    text-align: inherit;
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;
    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;
    /* Remove excess padding and border in Firefox 4+ */
    text-decoration: underline; }
    .pneu-offer__btn-additional-filters::-moz-focus-inner {
      border: 0;
      padding: 0; }
    @media (min-width: 600px) {
      .pneu-offer__btn-additional-filters {
        margin-top: 3rem;
        margin-left: 2rem; } }
    @media (max-width: 899px) {
      .pneu-offer__btn-additional-filters {
        margin-top: 1rem;
        margin-left: 0; } }
  .pneu-offer__btn-pick-tyre {
    height: 4rem;
    padding: 0 3rem; }
  .pneu-offer__sku-list {
    padding-bottom: 4rem;
    margin-bottom: 4rem;
    border-bottom: solid 1px #b7b7b7; }
    .pneu-offer__sku-list-title {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.8rem;
      padding-top: 4rem; }
    .pneu-offer__sku-list-item {
      position: relative;
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: auto;
      grid-template-areas: "name" "icons" "SW" "price" "picker";
      padding: 4rem 0 0;
      margin-top: 4rem;
      background: linear-gradient(180deg, #f0f0f0 0%, white 24px, white 100%);
      grid-column-gap: 2rem;
      grid-row-gap: 2rem;
      align-items: flex-start; }
      @media (min-width: 600px) {
        .pneu-offer__sku-list-item {
          grid-template-columns: 15rem auto 17rem;
          grid-template-areas: "brand  name price" "icons icons picker" "SW SW picker"; } }
      .pneu-offer__sku-list-item--aktion:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 0;
        border-top: 35px solid #a4091e;
        border-left: 35px solid transparent; }
      .pneu-offer__sku-list-item--aktion:after {
        content: '%';
        position: absolute;
        top: 1px;
        right: 2px;
        font-size: 1.8rem;
        line-height: 1.9rem;
        color: #fff; }
      .pneu-offer__sku-list-item--selected {
        outline-style: solid;
        outline-color: #445e2a;
        outline-width: 2px;
        outline-offset: 5px; }
    .pneu-offer__sku-list-brand {
      grid-area: brand;
      display: none; }
      @media (min-width: 600px) {
        .pneu-offer__sku-list-brand {
          display: block; } }
    .pneu-offer__sku-list-name {
      grid-area: name; }
    .pneu-offer__sku-list-icons {
      grid-area: icons; }
      .pneu-offer__sku-list-icons svg {
        height: 3rem; }
        .pneu-offer__sku-list-icons svg:first-child::after {
          content: 'hello';
          display: block; }
        .pneu-offer__sku-list-icons svg + svg {
          margin-left: 3rem; }
        @media (min-width: 900px) {
          .pneu-offer__sku-list-icons svg {
            height: 4rem; } }
    .pneu-offer__sku-list-SW {
      grid-area: SW; }
      @media (min-width: 900px) {
        .pneu-offer__sku-list-SW {
          display: flex; } }
      .pneu-offer__sku-list-SW div {
        flex: 1; }
    .pneu-offer__sku-list-price {
      grid-area: price; }
    .pneu-offer__sku-list-picker {
      grid-area: picker;
      display: flex;
      column-gap: 1rem;
      align-items: flex-end; }
      @media (min-width: 600px) {
        .pneu-offer__sku-list-picker {
          flex-direction: column;
          row-gap: 1rem;
          align-items: stretch; } }
    @media (min-width: 600px) {
      .pneu-offer__sku-list-item-brandname {
        display: none; } }
    .pneu-offer__sku-list-item-title {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 2.4rem; }
    .pneu-offer__sku-list-item-description {
      font-size: 1.6rem; }
    .pneu-offer__sku-list-item-price {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 3rem;
      margin-bottom: 0.5rem; }
    .pneu-offer__sku-list-item-discount-price {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 3rem;
      margin-bottom: 0.5rem;
      color: #a4091e; }
    .pneu-offer__sku-list-item-instead-of-price {
      font-size: 0.8em;
      font-size: 1.2rem;
      margin-bottom: 0.5rem; }
    .pneu-offer__sku-list-item-mwst {
      font-size: 1.2rem; }
  .pneu-offer__services-rimtype {
    margin-bottom: 3rem;
    max-width: 20rem; }
  .pneu-offer__services-list {
    text-align: left; }
    .pneu-offer__services-list th {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal; }
    .pneu-offer__services-list th + th, .pneu-offer__services-list td + td {
      padding-left: 4rem; }
    .pneu-offer__services-list th, .pneu-offer__services-list td {
      padding-bottom: 2rem; }
      .pneu-offer__services-list th:nth-child(2), .pneu-offer__services-list th:nth-child(3), .pneu-offer__services-list td:nth-child(2), .pneu-offer__services-list td:nth-child(3) {
        display: none; }
        @media (min-width: 600px) {
          .pneu-offer__services-list th:nth-child(2), .pneu-offer__services-list th:nth-child(3), .pneu-offer__services-list td:nth-child(2), .pneu-offer__services-list td:nth-child(3) {
            display: table-cell; } }
    .pneu-offer__services-list strong {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal; }
  .pneu-offer__service-price-col {
    text-align: right; }
  .pneu-offer__service-amount-col {
    text-align: center; }
  .pneu-offer__address-fields {
    max-width: 45rem; }
  .pneu-offer__summary-intro {
    margin-bottom: 4rem; }
    .pneu-offer__summary-intro p {
      margin-bottom: 1em; }
  .pneu-offer__summary {
    margin: 3rem 0;
    display: grid;
    grid-column-gap: 4rem;
    grid-row-gap: 6rem;
    grid-template-columns: 1fr;
    grid-template-rows: auto; }
    @media (min-width: 600px) {
      .pneu-offer__summary {
        grid-template-columns: 1fr 1fr; } }
  .pneu-offer__summary-table {
    width: 100%; }
    .pneu-offer__summary-table th {
      text-align: left; }
    .pneu-offer__summary-table td {
      text-align: left; }
  .pneu-offer__summary-bg {
    margin-top: 1rem;
    padding: 3rem 0 0;
    line-height: 1.5;
    background: linear-gradient(180deg, #f0f0f0 0%, white 24px, white 100%); }
  .pneu-offer__promos {
    margin-bottom: 4rem; }
  .pneu-offer__promo + .pneu-offer__promo {
    margin-top: 2rem; }

.shop {
  hyphens: manual;
  /*#region Progress Bar*/
  /*#endregion*/
  /*#region Unloading Points*/
  /*#endregion*/
  /* #region Options */
  /* #endregion */
  /* #region Materials */
  /* #endregion */
  /*#region Equipment*/
  /*#endregion*/
  /* #region Addresses */
  /*#endregion*/
  /*#region Product Price Info*/
  /*#endregion*/
  /*#region Position Review*/
  /*#endregion*/
  /*#region Errors*/
  /*#endregion*/ }
  .shop a {
    color: #000; }
  .shop__progressbar {
    counter-reset: step;
    display: flex;
    padding-top: 1.8rem;
    padding-bottom: 1.2rem; }
    @media (min-width: 900px) {
      .shop__progressbar {
        padding-top: 2.3rem;
        padding-bottom: 1.3rem; } }
    .shop__progressbar li {
      list-style-type: none;
      flex: 1;
      float: left;
      font-size: 1.6rem;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      color: #000;
      background-color: transparent; }
      .shop__progressbar li:before {
        position: relative;
        width: 4rem;
        height: 4rem;
        content: counter(step);
        counter-increment: step;
        line-height: 3.732rem;
        border: 1px solid #000;
        display: block;
        text-align: center;
        margin: 0 auto 1rem auto;
        border-radius: 50%;
        background-color: #e6e6e6;
        z-index: 2;
        font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-style: normal;
        font-size: 2rem; }
        @media (min-width: 900px) {
          .shop__progressbar li:before {
            width: 4rem;
            height: 4rem;
            line-height: 3.732rem; } }
      .shop__progressbar li:after {
        display: none;
        width: 100%;
        height: 2px;
        content: '';
        position: absolute;
        background-color: #999999;
        top: 2rem;
        left: -50%;
        z-index: 1; }
        @media (min-width: 900px) {
          .shop__progressbar li:after {
            top: 2rem; } }
      .shop__progressbar li:first-child:after {
        content: none; }
      .shop__progressbar li.active {
        color: #000; }
        .shop__progressbar li.active:before {
          color: #fff;
          background-color: #000;
          border-color: #000; }
        .shop__progressbar li.active:after {
          display: block; }
      .shop__progressbar li.current {
        color: #a4091e; }
        .shop__progressbar li.current:before {
          color: #fff;
          background-color: #a4091e;
          border-color: #a4091e;
          width: 5rem;
          height: 5rem;
          line-height: 4.665rem;
          margin-top: -0.5rem;
          font-size: 2.7rem; }
        .shop__progressbar li.current:after {
          display: block; }
        .shop__progressbar li.current .shop__progressbar_steptext {
          margin-top: 0.5rem;
          font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
          font-weight: normal;
          font-style: normal; }
      .shop__progressbar li.clickable {
        cursor: pointer; }
    .shop__progressbar_steptext {
      display: none; }
      @media (min-width: 900px) {
        .shop__progressbar_steptext {
          display: block;
          margin-top: 1.4rem;
          line-height: 3rem; } }
  .shop__fieldgroup {
    border: none;
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
    border-bottom: 1px solid #000; }
    @media (min-width: 900px) {
      .shop__fieldgroup {
        padding-left: 10%;
        padding-right: 10%; } }
    @media (min-width: 1260px) {
      .shop__fieldgroup {
        padding-left: 16.6667%;
        padding-right: 16.6667%; } }
    .shop__fieldgroup--no-separator {
      border-bottom: none; }
    .shop__fieldgroup--no-padding-bottom {
      padding-bottom: 0; }
    .shop__fieldgroup--no-padding-top {
      padding-top: 0; }
    .shop__fieldgroup-title {
      font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      color: #a4091e;
      font-size: 2.4rem;
      margin-bottom: 2.7rem; }
      .shop__fieldgroup-title--big {
        font-size: 3rem; }
  .shop__unloadingpoint {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2.8rem; }
  .shop__unloadingpoint-warenempfaenger {
    width: 100%; }
    @media (min-width: 900px) {
      .shop__unloadingpoint-warenempfaenger {
        width: 69%; } }
  .shop__unloadingpoint-amount {
    width: calc(100% - 4.3rem);
    margin-top: 1.5rem; }
    @media (min-width: 900px) {
      .shop__unloadingpoint-amount {
        width: calc(26.4% - 2.9rem);
        margin-top: 0; } }
  .shop__removeunloadingpoint {
    width: 2.9rem;
    margin-top: 1.5rem; }
    @media (min-width: 900px) {
      .shop__removeunloadingpoint {
        margin-top: 0; } }
    .shop__removeunloadingpoint-button {
      border: none;
      margin: 0;
      padding: 0;
      width: auto;
      overflow: visible;
      background: transparent;
      text-align: inherit;
      /* inherit font & color from ancestor */
      color: inherit;
      font: inherit;
      /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
      line-height: normal;
      /* Corrects font smoothing for webkit */
      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;
      /* Corrects inability to style clickable `input` types in iOS */
      -webkit-appearance: none;
      cursor: pointer;
      outline: none;
      /* Remove excess padding and border in Firefox 4+ */
      background: none;
      height: 2.9rem;
      width: 2.9rem;
      cursor: pointer; }
      .shop__removeunloadingpoint-button::-moz-focus-inner {
        border: 0;
        padding: 0; }
  .shop__addunloadingpoint {
    margin-top: 3rem; }
    .shop__addunloadingpoint-button {
      border: none;
      margin: 0;
      padding: 0;
      width: auto;
      overflow: visible;
      background: transparent;
      text-align: inherit;
      /* inherit font & color from ancestor */
      color: inherit;
      font: inherit;
      /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
      line-height: normal;
      /* Corrects font smoothing for webkit */
      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;
      /* Corrects inability to style clickable `input` types in iOS */
      -webkit-appearance: none;
      cursor: pointer;
      outline: none;
      /* Remove excess padding and border in Firefox 4+ */
      background: none;
      font-size: 1.6rem;
      line-height: 3rem;
      font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      cursor: pointer; }
      .shop__addunloadingpoint-button::-moz-focus-inner {
        border: 0;
        padding: 0; }
      .shop__addunloadingpoint-button:hover .shop__addunloadingpoint-icon {
        background-color: #445e2a; }
    .shop__addunloadingpoint-icon {
      display: inline-block;
      width: 3rem;
      height: 3rem;
      font-size: 2rem;
      line-height: 3rem;
      background-color: #4e6c30;
      color: #fff;
      margin-right: 1.1rem;
      font-family: none;
      text-align: center; }
  .shop__zipcodeOrWarenempfaenger-we + .shop__zipcodeOrWarenempfaenger-zip {
    margin-top: 1rem; }
  .shop__unloadingpoint-presets {
    display: flex;
    margin-top: 1.5rem;
    flex-basis: 100%; }
    .shop__unloadingpoint-presets button {
      border: none;
      margin: 0;
      padding: 0;
      width: auto;
      overflow: visible;
      background: transparent;
      text-align: inherit;
      /* inherit font & color from ancestor */
      color: inherit;
      font: inherit;
      /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
      line-height: normal;
      /* Corrects font smoothing for webkit */
      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;
      /* Corrects inability to style clickable `input` types in iOS */
      -webkit-appearance: none;
      cursor: pointer;
      outline: none;
      /* Remove excess padding and border in Firefox 4+ */
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      text-align: center;
      padding: 0.8rem 1rem;
      font-size: 1.2rem;
      flex: 1;
      background-color: #fff;
      color: #333;
      border: 1px solid #b7b7b7; }
      .shop__unloadingpoint-presets button::-moz-focus-inner {
        border: 0;
        padding: 0; }
      .shop__unloadingpoint-presets button:hover {
        background-color: #f1f1f1; }
      .shop__unloadingpoint-presets button + button {
        margin-left: 0.3rem; }
  .shop__more-unloadingpoints {
    margin-top: 3rem; }
  .shop__options-block {
    margin-bottom: 5rem;
    /* WWW-2212 */ }
    .shop__options-block:last-of-type {
      margin-bottom: 1rem; }
    @media (min-width: 900px) {
      .shop__options-block {
        display: flex;
        margin-bottom: 3rem; } }
    .shop__options-block-input {
      margin-bottom: 2rem; }
      @media (min-width: 900px) {
        .shop__options-block-input {
          margin-bottom: 0;
          width: 38%;
          margin-right: 1.5%; } }
    @media (min-width: 900px) {
      .shop__options-block-desc {
        flex: 1; } }
    .shop__options-block-desc .migrol-form__input-label--invisible {
      display: none; }
      @media (min-width: 900px) {
        .shop__options-block-desc .migrol-form__input-label--invisible {
          display: block;
          padding-bottom: 1.4rem; } }
    .shop__options-block .buy-energy-header__product-details__teaser {
      background-color: #a4091e;
      padding: 2.8rem 3.4rem;
      width: 100%;
      color: #fff; }
  .shop__discountcode .shop__error-message, .shop__discountcode .shop__success-message {
    margin-top: 0; }
  @media (min-width: 900px) {
    .shop__materials {
      display: grid;
      grid-column-gap: 1.2rem; } }
  .shop__material {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    flex: 1; }
    @media (min-width: 900px) {
      .shop__material {
        display: contents;
        margin-bottom: 0; } }
    .shop__material-block-col-1 {
      grid-column: 1/2; }
    .shop__material-block-col-2 {
      grid-column: 2/3; }
    .shop__material-block-desc {
      grid-row: 1/2;
      background-color: #e6e6e6;
      border: 2px solid #e6e6e6;
      border-width: 2px 2px 0 2px;
      padding: 2.2rem; }
      .shop__material-block-desc:last-child {
        border-width: 2px; }
      @media (min-width: 600px) {
        .shop__material-block-desc--grid {
          display: grid;
          grid-template: "icon title" auto "icon text" auto / 1fr 2fr; } }
    .shop__material-icon {
      display: flex;
      justify-content: center;
      margin-bottom: 3rem;
      grid-area: icon; }
    .shop__material-name {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      color: #a4091e;
      font-size: 2rem;
      line-height: 3rem;
      margin-bottom: 1rem;
      grid-area: title; }
    .shop__material-description {
      font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      color: #000;
      font-size: 1.6rem;
      line-height: 2.2rem;
      grid-area: text; }
    .shop__material-block-price {
      grid-row: 2/3;
      border: 2px solid #e6e6e6;
      border-width: 0 2px;
      color: #000; }
      .shop__material-block-price:last-child {
        border-width: 0 2px 2px 2px; }
    .shop__material-price {
      padding: 1.6rem 2.5rem 1rem;
      background-color: #cdcdcd;
      height: 100%; }
    .shop__material-price-total {
      font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 2.4rem;
      text-align: center;
      margin-top: 2rem; }
    .shop__material-price-per-unit {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 3rem;
      text-align: center; }
    .shop__material-price-unit {
      font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.5rem;
      text-align: center;
      display: block; }
    .shop__material-total-tax {
      font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.5rem;
      text-align: center;
      display: block; }
    .shop__material-price-unavailable {
      color: #940619; }
    .shop__material-price-loading {
      position: relative;
      background-color: #e6e6e6;
      text-align: center;
      color: #000;
      height: 100%; }
    .shop__material-price-loading-ani-container {
      height: 73px; }
    .shop__material-price-loading-ani, .shop__material-price-loading-ani::before, .shop__material-price-loading-ani::after {
      position: absolute;
      top: 50%;
      left: 50%;
      border: 1px solid #cdcdcd;
      border-left-color: #a4091e;
      border-radius: 949px; }
    .shop__material-price-loading-ani {
      margin: -43px 0 0 -24px;
      height: 47px;
      width: 47px;
      animation: price-loading-rotation-ani 1150ms linear infinite; }
    .shop__material-price-loading-ani::before {
      content: "";
      margin: -22px 0 0 -22px;
      height: 42px;
      width: 42px;
      animation: price-loading-rotation-ani 1150ms linear infinite; }
    .shop__material-price-loading-ani::after {
      content: "";
      margin: -28px 0 0 -28px;
      height: 53px;
      width: 53px;
      animation: price-loading-rotation-ani 2300ms linear infinite; }

@keyframes price-loading-rotation-ani {
  100% {
    transform: rotate(360deg); } }
    .shop__material-block-selection {
      grid-row: 3/4;
      background-color: #e6e6e6;
      border: 2px solid #e6e6e6;
      border-width: 0 2px 2px 2px;
      padding: 2rem;
      font-size: 1.6rem;
      display: flex;
      justify-content: center; }
    .shop__material--selectable {
      cursor: pointer; }
    .shop__material--selected {
      border-color: #4e6c30; }
      .shop__material--selected .shop__material-block-desc {
        border-color: #4e6c30; }
      .shop__material--selected .shop__material-block-price {
        border-color: #4e6c30; }
      .shop__material--selected .shop__material-price {
        color: #fff;
        background-color: #4e6c30; }
      .shop__material--selected .shop__material-block-selection {
        border-color: #4e6c30;
        color: #4e6c30;
        font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-style: normal; }
    @media (min-width: 900px) {
      .shop__material--single {
        margin-left: 8%; }
        .shop__material--single:last-child {
          margin-right: 8%; } }
    @media (min-width: 1260px) {
      .shop__material--single {
        margin-left: 12.5%; }
        .shop__material--single:last-child {
          margin-right: 12.5%; } }
  .shop__price-info {
    margin-top: 3rem; }
    .shop__price-info .migrol-rt-content {
      font-size: 1.4rem;
      line-height: 2rem;
      margin-bottom: 0; }
  .shop__equipment-row {
    margin-bottom: 2rem; }
    .shop__equipment-row:last-child {
      margin-bottom: 0; }
  .shop__addresses-separate-billing {
    margin-top: 3rem; }
  .shop__address-block {
    padding-top: 6rem;
    padding-bottom: 6rem; }
    @media (min-width: 900px) {
      .shop__address-block {
        padding-left: 8%;
        padding-right: 8%; } }
    @media (min-width: 1260px) {
      .shop__address-block {
        padding-left: 12.5%;
        padding-right: 12.5%; } }
    .shop__address-block-title {
      font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      color: #a4091e;
      font-size: 2.4rem;
      margin-bottom: 2.7rem; }
    .shop__address-block-spacer-big {
      margin-bottom: 7rem; }
    .shop__address-block-spacer-small {
      margin-bottom: 3rem; }
    .shop__address-block--small-padding {
      padding-top: 3rem;
      padding-bottom: 3rem; }
  .shop__paymentmode .migrol-form__radiobutton {
    margin-bottom: 1.5rem; }
    .shop__paymentmode .migrol-form__radiobutton:last-child {
      margin-bottom: 0; }
  .shop__productprice {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 2rem;
    font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    color: #000;
    border: 2px solid #4e6c30;
    background-color: #e6e6e6; }
    .shop__productprice-block-text {
      margin-top: 2rem; }
    .shop__productprice-block-myclimate {
      margin-top: 2rem; }
    .shop__productprice-title {
      font-size: 1.4rem;
      line-height: 2rem;
      color: #4e6c30;
      margin-bottom: 1.2rem; }
    .shop__productprice-product-name {
      font-size: 2rem;
      line-height: 3rem;
      color: #a4091e;
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal; }
    .shop__productprice-amount {
      font-size: 1.6rem;
      line-height: 2.2rem; }
    .shop__productprice-price {
      font-size: 2.4rem;
      margin-top: 1.7rem;
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal; }
    .shop__productprice-tax {
      font-size: 1.4rem;
      display: block;
      font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal; }
    .shop__productprice-price-unit {
      font-size: 1.4rem;
      display: block;
      font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal; }
    .shop__productprice-price-secondary {
      font-size: 2rem;
      margin-top: 2rem; }
    @media (min-width: 900px) {
      .shop__productprice--horizontal {
        flex-direction: row;
        align-items: stretch;
        padding: 2.4rem 2.9rem;
        border-width: 1px; } }
    @media (min-width: 900px) {
      .shop__productprice--horizontal .shop__productprice-block-icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 2.1rem; } }
    @media (min-width: 900px) {
      .shop__productprice--horizontal .shop__productprice-block-text {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 2.1rem; } }
    @media (min-width: 900px) {
      .shop__productprice--horizontal .shop__productprice-block-myclimate {
        margin-top: 0;
        flex: 1;
        display: flex;
        justify-content: center; } }
  .shop__position-review {
    display: flex;
    flex-direction: column; }
    @media (min-width: 900px) {
      .shop__position-review {
        flex-direction: row; } }
    .shop__position-review-address {
      flex: 2; }
      @media (min-width: 900px) {
        .shop__position-review-address-details {
          display: flex;
          padding-right: 5rem;
          justify-content: space-between; } }
    .shop__position-review-product {
      flex: 1;
      margin-top: 4rem; }
      @media (min-width: 900px) {
        .shop__position-review-product {
          margin-top: 0; } }
      .shop__position-review-product small {
        font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-style: normal;
        font-size: 1.4rem; }
    .shop__position-review-filltank {
      margin-top: 3rem; }
      @media (min-width: 900px) {
        .shop__position-review-filltank {
          margin-top: 4rem; } }
    .shop__position-review-total {
      background-color: #e6e6e6;
      padding: 2.3rem 0;
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 2rem;
      color: #000; }
  .shop__acceptance-checkbox {
    margin-bottom: 3rem; }
    .shop__acceptance-checkbox:last-child {
      margin-bottom: 0; }
  .shop__error-message-block {
    padding: 2rem;
    border: 2px solid #a4091e;
    color: #a4091e;
    background-color: #fff;
    margin-bottom: 3rem; }
    @media (min-width: 900px) {
      .shop__error-message-block {
        width: 50%;
        margin-left: auto;
        margin-right: auto; } }
    .shop__error-message-block li {
      list-style: none; }
  .shop__error-message {
    color: #a4091e;
    margin-top: 1rem; }
  .shop__success-message {
    color: #4e6c30;
    margin-top: 1rem; }
    .shop__success-message--inverted {
      background-color: #4e6c30;
      color: #fff;
      padding: 2rem; }
  .shop__warning-message {
    margin-top: 3rem;
    padding: 2rem;
    background-color: #f7d800; }
    .shop__warning-message-title {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.1em;
      margin-bottom: 0.3rem; }
  .shop__partner-text {
    margin-bottom: 2rem; }
    .shop__partner-text:last-child {
      margin-bottom: 0; }
  .shop__two-col-block {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem; }
    @media (min-width: 900px) {
      .shop__two-col-block {
        grid-template-columns: 1fr 1fr; } }
  .shop-teaser__background--tankrevision {
    background-image: url("/assets/MigrolShop/img/teasers/teaser-tankrevision-aec36681dc.jpg"); }

/* temporary to hide the debug panel on mobile: */
.shop.migrol-form pre {
  display: none; }
  @media (min-width: 900px) {
    .shop.migrol-form pre {
      display: block; } }

.has-error .shop__material {
  border-color: #a4091e; }
  .has-error .shop__material .shop__material-block-selection {
    color: #a4091e;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal; }

html, body {
  overscroll-behavior-y: none; }

/*#region desktop styles*/
.ts-finder {
  /*
//////////////////////////////
// 2. Infowindow Shadow
//////////////////////////////

// Shadow wrapper
[class*='si-shadow-wrapper'] {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: $si-shadow-opacity;
    z-index: 1;
}

// Shadow position
.si-shadow-wrapper-top,
.si-shadow-wrapper-bottom {
    flex-direction: column;
}

.si-shadow-pointer-bottom,
.si-shadow-pointer-right {
    order: -1;
}

// Box shadow
.si-shadow-frame {
    box-shadow: $si-shadow-h $si-shadow-v $si-shadow-blur $si-shadow-spread $si-shadow-color;
}

// Pointer shadow
[class*='si-shadow-pointer'] {
    position: relative;
    width: $si-pointer-length;
    height: $si-pointer-length;
    margin: auto;
}

[class*='si-shadow-inner-pointer'] {
    position: absolute;
    width: 141%;
    height: 141%;
    box-shadow: $si-rotated-shadow-h $si-rotated-shadow-v $si-shadow-blur $si-shadow-spread $si-shadow-color;
}


.si-shadow-inner-pointer-top {
    left: 50%;
    transform: translate(-50%, -50%) rotate($si-rotation);
}

.si-shadow-inner-pointer-bottom {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%) rotate($si-rotation);
}

.si-shadow-inner-pointer-left {
    top: 50%;
    transform: translate(-50%, -50%) rotate($si-rotation);
}

.si-shadow-inner-pointer-right {
    top: 50%;
    right: 0;
    transform: translate(50%, -50%) rotate($si-rotation);
}
*/
  /*
//////////////////////////////
// 4. Close Button
//////////////////////////////

.si-close-button {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    outline: none;
    background-color: transparent;
    color: inherit;
    font-family: Arial, Baskerville, monospace;
    font-size: $si-close-font-size;
    cursor: pointer;
    opacity: $si-close-opacity;
    appearance: none;

    &:hover,
    &:focus {
        opacity: $si-close-hover-opacity;
    }
}



//////////////////////////////
// 5. Pointer Styles
//////////////////////////////

[class*='si-pointer-border'] {
    position: absolute;
    border: $si-pointer-length solid transparent;
    z-index: 3;
}

$si-triangle-difference: min(round($si-border-width * ($si-root-2 - 1)), $si-pointer-length);

[class*='si-pointer-bg'] {
    position: relative;
    border: $si-pointer-length solid transparent;
    z-index: 4;

    .si-has-border & {
        border-width: $si-pointer-length - $si-triangle-difference;
    }
}

.si-pointer-border-top,
.si-pointer-border-bottom {
    left: 50%;
    transform: translate(-50%, 0);
}

.si-pointer-border-left,
.si-pointer-border-right {
    top: 50%;
    transform: translate(0, -50%);
}

// Top pointer poisitioning
.si-pointer-top {
    border-bottom: 0;
}

.si-pointer-border-top {
    bottom: 0;
    border-top-color: $si-border-color;
}

.si-pointer-bg-top {
    border-top-color: $si-content-bg;

    .si-has-border & {
        top: -$si-border-width;
        margin-bottom: $si-triangle-difference;
    }
}


// Bottom pointer positioning
.si-pointer-bottom {
    border-top: 0;
}

.si-pointer-border-bottom {
    top: 0;
    border-bottom-color: $si-border-color;
}

.si-pointer-bg-bottom {
    border-bottom-color: $si-content-bg;

    .si-has-border & {
        bottom: -$si-border-width;
        margin-top: $si-triangle-difference;
    }
}

// Left pointer positioning
.si-pointer-left {
    border-right: 0;
}

.si-pointer-border-left {
    right: 0;
    border-left-color: $si-border-color;
}

.si-pointer-bg-left {
    border-left-color: $si-content-bg;

    .si-has-border & {
        left: -$si-border-width;
        margin-right: $si-triangle-difference;
    }
}

// Right pointer positioning
.si-pointer-right {
    border-left: 0;
}

.si-pointer-border-right {
    left: 0;
    border-right-color: $si-border-color;
}

.si-pointer-bg-right {
    border-right-color: $si-content-bg;

    .si-has-border & {
        right: -$si-border-width;
        margin-left: $si-triangle-difference;
    }
}
*/
  position: relative;
  font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1.8rem; }
  .ts-finder .si-float-wrapper {
    position: absolute;
    width: 100%; }
    .ts-finder .si-float-wrapper,
    .ts-finder .si-float-wrapper * {
      box-sizing: border-box; }
  .ts-finder [class*='si-wrapper'] {
    display: flex;
    position: absolute;
    align-items: center;
    font-size: 1.4rem;
    cursor: default; }
  .ts-finder .si-wrapper-top {
    flex-direction: column;
    margin-top: -40px;
    margin-left: 0px;
    transform: translate(-50%, -100%); }
  .ts-finder .si-wrapper-bottom {
    flex-direction: column-reverse;
    margin-top: 0px;
    margin-left: 0px;
    transform: translate(-50%, 0); }
  .ts-finder .si-wrapper-left {
    margin-top: -20px;
    margin-left: -11px;
    transform: translate(-100%, -50%); }
  .ts-finder .si-wrapper-right {
    flex-direction: row-reverse;
    margin-top: -20px;
    margin-left: 11px;
    transform: translate(0, -50%); }
  .ts-finder .si-frame {
    position: relative;
    flex: 1 1 auto;
    border-radius: 0;
    overflow: hidden;
    z-index: 2; }
  .ts-finder .si-content-wrapper {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    padding: 0;
    background-color: transparent; }
    .si-has-border .ts-finder .si-content-wrapper {
      border: 2px solid #a4091e; }
  .ts-finder .si-content {
    overflow: auto; }
  .ts-finder__loading-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(238, 238, 238, 0.8);
    color: #777;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 2rem;
    z-index: 2;
    text-align: center; }
  .ts-finder__loading-logo {
    display: inline-block;
    animation-name: jump;
    animation-timing-function: ease-in;
    animation-iteration-count: infinite;
    animation-duration: 0.4s;
    animation-direction: alternate;
    margin-bottom: 0.5rem; }

@keyframes jump {
  from {
    transform: translateY(-20px); }
  to {
    transform: rotateY(0); } }
  .ts-finder__map {
    width: 100%;
    height: 80vh;
    height: 80dvh; }
  .ts-finder__layout-mobile-infowindow {
    display: none; }
  .ts-finder__infowindow {
    background-color: #fff;
    border: solid 1px #4e6c30;
    padding: 2rem;
    margin-top: 12px;
    margin-bottom: 16px;
    position: relative;
    width: 417px;
    /* Stroke */
    /* Fill */ }
    .ts-finder__infowindow:before, .ts-finder__infowindow:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent;
      border-bottom: 0; }
    .ts-finder__infowindow:before {
      bottom: -16px;
      left: calc(50% - 16px);
      border-top-color: #4e6c30;
      border-width: 16px; }
    .ts-finder__infowindow:after {
      bottom: -15px;
      left: calc(50% - 15px);
      border-top-color: #FFF;
      border-width: 15px; }
    .ts-finder__infowindow--with-promobanner {
      margin-top: 0; }
      .ts-finder__infowindow--with-promobanner .ts-finder__infowindow-station-details-container {
        margin-top: 1rem; }
      .ts-finder__infowindow--with-promobanner .ts-finder__infowindow-directions {
        top: -30px;
        right: 1rem; }
    .ts-finder__infowindow-promobanner {
      width: 100%;
      height: auto; }
    .ts-finder__infowindow-station-details-container {
      position: relative;
      display: flex; }
    .ts-finder__infowindow-directions {
      position: absolute;
      top: calc(-2rem - 12px);
      right: 0;
      text-align: center;
      font-size: 1.2rem;
      color: #b7b7b7; }
    .ts-finder__infowindow-directions-link {
      display: inline-block;
      width: 40px;
      height: 40px;
      padding: 8px;
      border-radius: 50%;
      background-color: #4e6c30;
      margin-bottom: 0.6rem;
      color: #fff;
      font-size: 2rem; }
    .ts-finder__infowindow-station-brand {
      width: 4rem;
      flex: none;
      height: auto;
      margin-right: 1.5rem;
      align-self: flex-start; }
    .ts-finder__infowindow-station-details {
      flex: 1;
      align-self: flex-start;
      margin-right: 1.5rem; }
    .ts-finder__infowindow-station-name {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      color: #000;
      font-size: 1.8rem;
      margin-bottom: 0.3rem; }
    .ts-finder__infowindow-station-address {
      color: #7f7f7f;
      font-size: 1.4rem;
      margin-bottom: 1rem; }
    .ts-finder__infowindow-station-zipcity {
      white-space: nowrap; }
    .ts-finder__infowindow-temp-closed {
      color: #a4091e;
      font-size: 1.4rem; }
    .ts-finder__infowindow-services-list {
      margin: 0;
      padding: 0; }
      .ts-finder__infowindow-services-list li {
        height: 2.5rem;
        width: 2.5rem;
        display: inline-block;
        margin-right: 0.9rem; }
        .ts-finder__infowindow-services-list li.ts-finder__icons-fastcharging {
          width: 3.75rem; }
        .ts-finder__infowindow-services-list li:last-child {
          margin-right: 0; }
    .ts-finder__infowindow-details-link {
      flex: none;
      align-self: flex-end;
      font-size: 1.6rem;
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal; }
      .ts-finder__infowindow-details-link a {
        color: #4e6c30;
        text-decoration: none; }
        .ts-finder__infowindow-details-link a:hover {
          color: #445e2a; }
  .ts-finder__details {
    /* this is */ }
    .ts-finder__details--desktop-only {
      display: block; }
    .ts-finder__details--mobile-only {
      display: none; }
    .ts-finder__details-map {
      height: 600px; }
    .ts-finder__details-content {
      margin-top: -300px; }
    .ts-finder__details-content-inner {
      background-color: #fff;
      padding-top: 3rem;
      padding-bottom: 3rem;
      position: relative; }
    .ts-finder__details-directions {
      position: absolute;
      top: -12px;
      right: 8rem;
      text-align: center;
      font-size: 1.2rem;
      color: #b7b7b7; }
    .ts-finder__details-back {
      border: 2px solid #4e6c30;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      position: absolute;
      top: -12px;
      right: 3rem;
      color: #4e6c30;
      font-size: 2.2rem;
      line-height: 3.4rem;
      background-color: #fff;
      text-align: center; }
      .ts-finder__details-back:hover {
        border-color: #445e2a;
        color: #445e2a; }
      .ts-finder__details-back .fa-times {
        margin-left: 1px; }
    .ts-finder__details-directions-link {
      display: inline-block;
      width: 40px;
      height: 40px;
      padding: 8px;
      border-radius: 50%;
      background-color: #4e6c30;
      margin-bottom: 0.6rem;
      color: #fff;
      font-size: 2rem; }
    .ts-finder__details-header {
      margin: 0 -3rem 3rem -3rem;
      padding: 0 3rem 3rem 3rem;
      border-bottom: solid 3px #a4091e; }
    .ts-finder__details-columns {
      display: flex; }
      .ts-finder__details-columns--middle {
        align-items: center; }
    .ts-finder__details-left {
      flex: 2; }
      .ts-finder__details-left--tablet-same-size {
        flex: 1; }
        @media (min-width: 1260px) {
          .ts-finder__details-left--tablet-same-size {
            flex: 2; } }
    .ts-finder__details-right {
      flex: 1; }
    .ts-finder__details-full-width {
      flex: none; }
    .ts-finder__details-logo {
      flex: none;
      margin-right: 4rem; }
    .ts-finder__details-address {
      font-size: 2.0rem;
      flex: 1; }
    .ts-finder__details-station-name {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal; }
    .ts-finder__details-station-phone a {
      color: #000;
      text-decoration: underline; }
    .ts-finder__details-services {
      font-size: 2rem;
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal; }
    .ts-finder__details-services-list {
      list-style: none;
      margin: 1rem 0 0 0;
      padding: 0; }
      .ts-finder__details-services-list li {
        display: inline-block;
        margin-right: 1rem; }
    .ts-finder__details-service {
      padding: 2rem 0;
      border-bottom: 1px solid #cdcdcd; }
    .ts-finder__details-servicetitle {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 3.2rem;
      text-transform: uppercase;
      margin-bottom: 3rem; }
    .ts-finder__details-autoservice-rte ul {
      columns: 1; }
      @media (min-width: 600px) {
        .ts-finder__details-autoservice-rte ul {
          columns: 2;
          column-gap: 7rem; } }
      @media (min-width: 1260px) {
        .ts-finder__details-autoservice-rte ul {
          columns: 3;
          column-gap: 5rem; } }
    .ts-finder__details-hours {
      font-size: 2rem;
      margin-bottom: 3rem; }
      .ts-finder__details-hours strong {
        font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-style: normal;
        display: block;
        margin-bottom: 0.5rem; }
    .ts-finder__details-migrol-shell-info {
      margin-bottom: 3rem; }
    .ts-finder__details-capabilities {
      font-size: 2rem;
      margin: 0 0 3rem 0;
      list-style-position: inside; }
      .ts-finder__details-capabilities--columns {
        display: flex; }
      .ts-finder__details-capabilities--fixed-height li {
        height: 40px;
        line-height: 40px; }
      .ts-finder__details-capabilities-left {
        flex: 1;
        margin-right: 2rem; }
      .ts-finder__details-capabilities-right {
        flex: 2; }
      .ts-finder__details-capabilities-column {
        margin-bottom: 2rem; }
        .ts-finder__details-capabilities-column:not(:last-child) {
          margin-right: 5rem; }
    .ts-finder__details-capabilities-with-icons {
      list-style-type: none;
      margin: 0;
      padding: 0; }
      .ts-finder__details-capabilities-with-icons--cols {
        height: 200px;
        column-count: 2;
        column-fill: auto; }
    .ts-finder__details-capability-icon-row {
      display: flex;
      align-items: center; }
    .ts-finder__details-capability-icon {
      width: 40px;
      height: 40px;
      background-size: 80%;
      background-position: center center;
      background-repeat: no-repeat;
      flex: none;
      margin-right: 10px;
      image-rendering: pixelated;
      image-rendering: -moz-crisp-edges;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges; }
      .ts-finder__details-capability-icon-adblue-can {
        background-image: url("/assets/MigrolTankstellensuche/img/ico_service_adblue_can-c241736559.png"); }
      .ts-finder__details-capability-icon-adblue-pump {
        background-image: url("/assets/MigrolTankstellensuche/img/ico_service_adblue_pump-33e39d2186.png"); }
      .ts-finder__details-capability-icon-carwash-sb {
        background-image: url("/assets/MigrolTankstellensuche/img/ico_service_carwash_sb-521fbc7c63.png"); }
      .ts-finder__details-capability-icon-carwash-sb-sauger {
        background-image: url("/assets/MigrolTankstellensuche/img/ico_service_carwash_sb_sauger-bc2f974e8b.png"); }
      .ts-finder__details-capability-icon-carwash-waschanlage {
        background-image: url("/assets/MigrolTankstellensuche/img/ico_service_carwash_waschanlage-8c9e983c81.png"); }
      .ts-finder__details-capability-icon-carwash-waschstrasse {
        background-image: url("/assets/MigrolTankstellensuche/img/ico_service_carwash_waschstrasse-d27e095860.png"); }
      .ts-finder__details-capability-icon-carwash-plus {
        background-image: url("/assets/MigrolTankstellensuche/img/ico_service_carwash_plus-ee33f4c7c2.png"); }
      .ts-finder__details-capability-icon-carwash-innenreinigung {
        background-image: url("/assets/MigrolTankstellensuche/img/ico_service_carwash_innenreinigung-7b2bbe7520.png"); }
      .ts-finder__details-capability-icon-erdgas {
        background-image: url("/assets/MigrolTankstellensuche/img/ico_service_erdgas-2e6d0abf94.png"); }
      .ts-finder__details-capability-icon-lkw130 {
        background-image: url("/assets/MigrolTankstellensuche/img/ico_service_lkw_130l-6165387fdd.png"); }
      .ts-finder__details-capability-icon-lkw70 {
        background-image: url("/assets/MigrolTankstellensuche/img/ico_service_lkw_70l-301b0f2406.png"); }
      .ts-finder__details-capability-icon-lkw {
        background-image: url("/assets/MigrolTankstellensuche/img/ico_service_lkw-18e69c335c.png"); }
      .ts-finder__details-capability-icon-lpggpl {
        background-image: url("/assets/MigrolTankstellensuche/img/ico_service_lpg-gpl-0b034bf563.png"); }
      .ts-finder__details-capability-icon-fastcharging-CCS {
        background-image: url("/assets/MigrolTankstellensuche/img/ico_service_fastcharging_CCS-d008176ef9.png"); }
      .ts-finder__details-capability-icon-fastcharging-CHAdeMO {
        background-image: url("/assets/MigrolTankstellensuche/img/ico_service_fastcharging_CHAdeMO-c24a9399c0.png"); }
      .ts-finder__details-capability-icon-fastcharging-ACTyp2 {
        background-image: url("/assets/MigrolTankstellensuche/img/ico_service_fastcharging_ACTyp2-f68b8c3d7c.png"); }
      .ts-finder__details-capability-icon-wasserstoff {
        background-image: url("/assets/MigrolTankstellensuche/img/ico_service_wasserstoff-c15baaa97c.png"); }
    .ts-finder__details-more-infos {
      margin-bottom: 1rem; }
    .ts-finder__details-more-infos-link {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      text-decoration: none;
      color: #000; }
      .ts-finder__details-more-infos-link:hover {
        color: #a4091e; }
    .ts-finder__details-promo {
      font-size: 2rem; }
      .ts-finder__details-promo img {
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
        margin-bottom: 0.5rem; }
      .ts-finder__details-promo a {
        font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-style: normal;
        color: #000;
        text-decoration: none;
        display: block; }
        .ts-finder__details-promo a:hover {
          color: #a4091e; }
      .ts-finder__details-promo .fa-angle-right {
        margin-left: .2rem;
        position: relative;
        top: 1px; }
    .ts-finder__details-description-toggle {
      border: none;
      margin: 0;
      padding: 0;
      width: auto;
      overflow: visible;
      background: transparent;
      text-align: inherit;
      /* inherit font & color from ancestor */
      color: inherit;
      font: inherit;
      /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
      line-height: normal;
      /* Corrects font smoothing for webkit */
      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;
      /* Corrects inability to style clickable `input` types in iOS */
      -webkit-appearance: none;
      cursor: pointer;
      outline: none;
      /* Remove excess padding and border in Firefox 4+ */
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      color: #000;
      font-size: 2rem; }
      .ts-finder__details-description-toggle::-moz-focus-inner {
        border: 0;
        padding: 0; }
      .ts-finder__details-description-toggle:hover {
        color: #a4091e; }
      .ts-finder__details-description-toggle .fa-angle-right {
        margin-left: .2rem;
        position: relative;
        top: 1px; }
    .ts-finder__details-warning {
      font-size: 1.6rem;
      color: #a4091e;
      margin-bottom: 3rem; }
    .ts-finder__details-paymentmethods {
      list-style-type: none;
      margin: 0;
      padding: 0;
      columns: 4;
      column-gap: 3rem; }
      .ts-finder__details-paymentmethods li {
        display: inline-block;
        width: 100%;
        margin-bottom: 10px; }
    .ts-finder__details-payment-icon-row {
      display: flex;
      align-items: center; }
    .ts-finder__details-payment-icon {
      width: 40px;
      height: 40px;
      background-size: 80%;
      background-position: center center;
      background-repeat: no-repeat;
      flex: none;
      margin-right: 10px;
      image-rendering: pixelated;
      image-rendering: -moz-crisp-edges;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges; }
      .ts-finder__details-payment-icon-migrolcard {
        background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_migrolcard-0e8aa4bc90.png); }
      .ts-finder__details-payment-icon-visa {
        background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_visa-1c181cf32b.png); }
      .ts-finder__details-payment-icon-m-card {
        background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_m-card-bb6d75829f.png); }
      .ts-finder__details-payment-icon-euroshell {
        background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_euroshell-bc94e4313b.jpg); }
      .ts-finder__details-payment-icon-amex {
        background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_amex-55a3d2e3d7.png); }
      .ts-finder__details-payment-icon-myone {
        background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_myone-1e75c8e28b.png); }
      .ts-finder__details-payment-icon-maestro {
        background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_maestro-ea1c883637.png); }
      .ts-finder__details-payment-icon-postfinance {
        background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_postfinance-fc41382e8e.png); }
      .ts-finder__details-payment-icon-migrol-geschenkkarte {
        background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_migrol-geschenkkarte-8f0f39d7ef.png); }
      .ts-finder__details-payment-icon-mastercard {
        background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_mastercard-17458235c1.png); }
      .ts-finder__details-payment-icon-v-pay {
        background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_v-pay-4099e75479.png); }
      .ts-finder__details-payment-icon-migrol-carwashcard {
        background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_carwashcard-5f854f9a4e.png); }
      .ts-finder__details-payment-icon-twint {
        background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_twint-0bb4c29e85.png); }
      .ts-finder__details-payment-icon-diners {
        background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_diners-3ebc0a6037.png); }
      .ts-finder__details-payment-icon-atm {
        background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_atm-acce5ac64f.png); }
      .ts-finder__details-payment-icon-cash {
        background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_cash-57978d40a8.png); }
      .ts-finder__details-payment-icon-autosense {
        background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_autosense-9747401e63.png); }
      .ts-finder__details-payment-icon-migrolcard-app {
        background-image: url(/assets/MigrolTankstellensuche/img/ico_payment_migrolcard-app-34070a2c48.svg); }
    .ts-finder__details-payment-icon-legend {
      flex: 1; }
    .ts-finder__details-migrolcard-info {
      display: flex;
      margin-right: 4rem; }
      .ts-finder__details-migrolcard-info-image {
        margin-right: 4rem;
        flex: 2; }
        .ts-finder__details-migrolcard-info-image img {
          max-width: 100%;
          height: auto; }
      .ts-finder__details-migrolcard-info-text {
        flex: 5;
        margin-bottom: 2rem; }
    .ts-finder__details-cumulus-info {
      display: flex; }
      .ts-finder__details-cumulus-info-image {
        margin-right: 4rem;
        flex: 1;
        align-self: center; }
        .ts-finder__details-cumulus-info-image img {
          max-width: 100%;
          height: auto; }
      .ts-finder__details-cumulus-info-text {
        flex: 5;
        align-self: center; }
    .ts-finder__details-mcharge-title {
      margin-bottom: 3rem; }
      .ts-finder__details-mcharge-title img {
        width: 400px; }
  .ts-finder__icon {
    height: 35px;
    width: 35px;
    background-size: 98%;
    background-position: center bottom;
    background-repeat: no-repeat; }
  .ts-finder__icons-tankstelle {
    background-image: url(/assets/MigrolTankstellensuche/img/ico_tankstelle-94a831afb3.svg); }
  .ts-finder__icons-fastcharging {
    background-image: url(/assets/MigrolTankstellensuche/img/ico_fastcharging-b4e80d9f12.svg); }
  .ts-finder__icons-carwash {
    background-image: url(/assets/MigrolTankstellensuche/img/ico_carwash-c8321276c4.svg); }
  .ts-finder__icons-shop {
    background-image: url(/assets/MigrolTankstellensuche/img/ico_shop-e0d88868a7.svg); }
  .ts-finder__icons-migrolino {
    background-image: url(/assets/MigrolTankstellensuche/img/ico_migrolino-f2e748ac6e.svg); }
  .ts-finder__icons-mio {
    background-image: url(/assets/MigrolTankstellensuche/img/ico_mio_by_migrolino-71f06e11e3.svg); }
  .ts-finder__icons-autoservice {
    background-image: url(/assets/MigrolTankstellensuche/img/ico_autoservice-624bc2bec7.svg); }
  .ts-finder__icons-migrolcard {
    background-image: url(/assets/MigrolTankstellensuche/img/ico_migrolcard_akzeptanzstelle-a79ed3c69c.svg); }
  .ts-finder__icons-wasserstoff {
    background-image: url(/assets/MigrolTankstellensuche/img/ico_wasserstoff-e4bcb2d8ed.svg); }
  .ts-finder__icons-hasWC {
    background-image: url(/assets/MigrolTankstellensuche/img/ico_hasWC-5ed1957357.png);
    background-size: 100%; }
  .ts-finder__icons-fastcharging {
    width: 52.5px; }
  .ts-finder__searchbox {
    position: absolute;
    top: 2rem;
    left: 2rem;
    max-height: calc(80vh - 4rem);
    max-height: calc(80dvh - 4rem);
    width: 37rem;
    background-color: #FFF;
    z-index: 1;
    font-size: 1.6rem;
    display: flex;
    flex-direction: column; }
    .ts-finder__searchbox-top {
      padding: 2rem;
      flex: none; }
    .ts-finder__searchbox-resultlist {
      flex: 1;
      overflow-y: auto;
      overflow-x: hidden; }
    .ts-finder__searchbox-button-base {
      border: none;
      margin: 0;
      padding: 0;
      width: auto;
      overflow: visible;
      background: transparent;
      text-align: inherit;
      /* inherit font & color from ancestor */
      color: inherit;
      font: inherit;
      /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
      line-height: normal;
      /* Corrects font smoothing for webkit */
      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;
      /* Corrects inability to style clickable `input` types in iOS */
      -webkit-appearance: none;
      cursor: pointer;
      outline: none;
      /* Remove excess padding and border in Firefox 4+ */
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      color: #fff;
      background-color: #4e6c30;
      text-align: center;
      height: 40px; }
      .ts-finder__searchbox-button-base::-moz-focus-inner {
        border: 0;
        padding: 0; }
      .ts-finder__searchbox-button-base:hover {
        background-color: #445e2a; }
      .ts-finder__searchbox-button-base--inverted {
        font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-style: normal;
        color: #4e6c30;
        border: 2px solid #4e6c30;
        background-color: #fff; }
        .ts-finder__searchbox-button-base--inverted:hover {
          background-color: #f1f1f1; }
    .ts-finder__searchbox-fulltext {
      display: flex;
      margin-bottom: 1rem;
      position: relative; }
    .ts-finder__searchbox-locateme {
      flex: none;
      width: 40px; }
    .ts-finder__searchbox-textfield {
      flex: 1;
      font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      line-height: 40px;
      height: 40px;
      padding: 0 .5rem;
      border: 1px solid #cdcdcd;
      outline: none;
      font-size: 1.6rem; }
    .ts-finder__searchbox-search-icon {
      position: absolute;
      top: 0.9rem;
      right: 1rem;
      font-size: 2rem;
      color: #cdcdcd; }
    .ts-finder__searchbox-close-search {
      position: absolute;
      right: 0;
      top: 0;
      height: 40px;
      width: 40px; }
    .ts-finder__searchbox-filter-reset {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 1.5rem; }
      .ts-finder__searchbox-filter-reset-button {
        border: none;
        margin: 0;
        padding: 0;
        width: auto;
        overflow: visible;
        background: transparent;
        text-align: inherit;
        /* inherit font & color from ancestor */
        color: inherit;
        font: inherit;
        /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
        line-height: normal;
        /* Corrects font smoothing for webkit */
        -webkit-font-smoothing: inherit;
        -moz-osx-font-smoothing: inherit;
        /* Corrects inability to style clickable `input` types in iOS */
        -webkit-appearance: none;
        cursor: pointer;
        outline: none;
        /* Remove excess padding and border in Firefox 4+ */
        font-size: 1.6rem; }
        .ts-finder__searchbox-filter-reset-button::-moz-focus-inner {
          border: 0;
          padding: 0; }
        .ts-finder__searchbox-filter-reset-button:hover {
          text-decoration: underline; }
    .ts-finder__searchbox-filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem; }
    .ts-finder__searchbox-toggle-filters {
      padding: 0 1rem;
      margin-left: 2rem; }
    .ts-finder__searchbox-checkbox-label {
      display: inline-block;
      background-color: #e6e6e6;
      color: #000;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      cursor: pointer; }
      .ts-finder__searchbox-checkbox-label:hover {
        background-color: #cdcdcd; }
      .ts-finder__searchbox-checkbox-label svg {
        pointer-events: none; }
    .ts-finder__searchbox-checkbox {
      display: none; }
    .ts-finder__searchbox-checkbox:checked + .ts-finder__searchbox-checkbox-label {
      background-color: #a4091e;
      color: #fff; }
    .ts-finder__searchbox-tags {
      margin-bottom: 2rem; }
    .ts-finder__searchbox-tag {
      border: none;
      margin: 0;
      padding: 0;
      width: auto;
      overflow: visible;
      background: transparent;
      text-align: inherit;
      /* inherit font & color from ancestor */
      color: inherit;
      font: inherit;
      /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
      line-height: normal;
      /* Corrects font smoothing for webkit */
      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;
      /* Corrects inability to style clickable `input` types in iOS */
      -webkit-appearance: none;
      cursor: pointer;
      outline: none;
      /* Remove excess padding and border in Firefox 4+ */
      background-color: #f1f1f1;
      height: 30px;
      line-height: 30px;
      border-radius: 12px;
      padding: 0 1rem;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem; }
      .ts-finder__searchbox-tag::-moz-focus-inner {
        border: 0;
        padding: 0; }
      .ts-finder__searchbox-tag:hover {
        background-color: #e6e6e6; }
    .ts-finder__searchbox-resultlist-toggle {
      border: none;
      margin: 0;
      padding: 0;
      width: auto;
      overflow: visible;
      background: transparent;
      text-align: inherit;
      /* inherit font & color from ancestor */
      color: inherit;
      font: inherit;
      /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
      line-height: normal;
      /* Corrects font smoothing for webkit */
      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;
      /* Corrects inability to style clickable `input` types in iOS */
      -webkit-appearance: none;
      cursor: pointer;
      outline: none;
      /* Remove excess padding and border in Firefox 4+ */
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      width: 100%;
      text-align: center;
      user-select: none;
      height: 4rem;
      background-color: #e6e6e6;
      color: #000; }
      .ts-finder__searchbox-resultlist-toggle::-moz-focus-inner {
        border: 0;
        padding: 0; }
      .ts-finder__searchbox-resultlist-toggle:hover {
        background-color: #cdcdcd; }
      .ts-finder__searchbox-resultlist-toggle .resultlist-icon {
        transform: rotate(0deg);
        transition: transform ease-in-out 0.2s; }
      .ts-finder__searchbox-resultlist-toggle.active .resultlist-icon {
        transform: rotate(-180deg);
        transition: transform ease-in-out 0.2s; }
  .ts-finder__resultlist {
    border-top: 1px solid #cdcdcd; }
    .ts-finder__resultlist .ts-finder__pdf-button {
      margin: 2rem; }
    .ts-finder__resultlist-item {
      border-bottom: 1px solid #cdcdcd;
      cursor: pointer;
      display: flex; }
      .ts-finder__resultlist-item--station {
        padding: 2rem 1rem 2rem 2rem; }
      .ts-finder__resultlist-item--station-search, .ts-finder__resultlist-item--place {
        padding: 1rem; }
      .ts-finder__resultlist-item:hover, .ts-finder__resultlist-item--selected {
        background-color: #f1f1f1; }
    .ts-finder__resultlist-show-more {
      border: none;
      margin: 0;
      padding: 0;
      width: auto;
      overflow: visible;
      background: transparent;
      text-align: inherit;
      /* inherit font & color from ancestor */
      color: inherit;
      font: inherit;
      /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
      line-height: normal;
      /* Corrects font smoothing for webkit */
      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;
      /* Corrects inability to style clickable `input` types in iOS */
      -webkit-appearance: none;
      cursor: pointer;
      outline: none;
      /* Remove excess padding and border in Firefox 4+ */
      margin: 2rem;
      width: 100%;
      text-align: center;
      font-size: 1.8rem; }
      .ts-finder__resultlist-show-more::-moz-focus-inner {
        border: 0;
        padding: 0; }
    .ts-finder__resultlist-logo {
      flex: none;
      margin-right: 1rem;
      width: 4rem;
      align-self: flex-start; }
    .ts-finder__resultlist-place-icon {
      flex: none;
      margin-right: 1rem;
      width: 4rem;
      align-self: flex-start;
      text-align: center;
      font-size: 3rem;
      color: #b7b7b7; }
    .ts-finder__resultlist-details {
      flex: 1;
      align-self: center; }
    .ts-finder__resultlist-name {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.8rem;
      margin-bottom: 0.5rem; }
    .ts-finder__resultlist-place-name {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      margin-bottom: 0.5rem; }
    .ts-finder__resultlist-address {
      color: #7f7f7f;
      font-size: 1.4rem;
      margin-bottom: 1rem; }
    .ts-finder__resultlist-services-list {
      margin: 0;
      padding: 0;
      list-style: none; }
      .ts-finder__resultlist-services-list li {
        height: 1.8rem;
        width: 1.8rem;
        margin-right: 0.9rem;
        display: inline-block; }
        .ts-finder__resultlist-services-list li.ts-finder__icons-fastcharging {
          width: 2.7rem; }
    .ts-finder__resultlist-directions {
      flex: none;
      margin-left: 1rem;
      text-align: center;
      font-size: 1.2rem;
      color: #b7b7b7;
      width: 5rem; }
    .ts-finder__resultlist-directions-link {
      display: inline-block;
      width: 40px;
      height: 40px;
      padding: 8px;
      border-radius: 50%;
      background-color: #4e6c30;
      margin-bottom: .6rem;
      color: #fff;
      font-size: 2rem; }
  .ts-finder__filterpanel {
    position: absolute;
    top: 2rem;
    left: 2rem;
    max-height: calc(80vh - 4rem);
    max-height: calc(80dvh - 4rem);
    width: 37rem;
    background-color: #FFF;
    z-index: 1;
    display: flex;
    flex-direction: column; }
    .ts-finder__filterpanel-top {
      flex: none; }
    .ts-finder__filterpanel-mid {
      flex: 1;
      overflow-y: auto; }
    .ts-finder__filterpanel-bottom {
      flex: none;
      padding: 2rem;
      margin-top: 1rem;
      border-top: solid 1px #cdcdcd; }
    .ts-finder__filterpanel-filter-count {
      font-size: 1.2rem;
      color: #7f7f7f;
      font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal; }
    .ts-finder__filterpanel-title {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      font-size: 1.8rem;
      padding: 2rem 5rem 2rem 2rem;
      text-align: center; }
    .ts-finder__filterpanel-close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      border: none;
      margin: 0;
      padding: 0;
      width: auto;
      overflow: visible;
      background: transparent;
      text-align: inherit;
      /* inherit font & color from ancestor */
      color: inherit;
      font: inherit;
      /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
      line-height: normal;
      /* Corrects font smoothing for webkit */
      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;
      /* Corrects inability to style clickable `input` types in iOS */
      -webkit-appearance: none;
      cursor: pointer;
      outline: none;
      /* Remove excess padding and border in Firefox 4+ */
      background-color: #cdcdcd;
      color: #000;
      width: 3rem;
      height: 3rem;
      text-align: center;
      line-height: 3rem;
      border-radius: 50%; }
      .ts-finder__filterpanel-close::-moz-focus-inner {
        border: 0;
        padding: 0; }
      .ts-finder__filterpanel-close:hover {
        background-color: #b7b7b7; }
    .ts-finder__filterpanel-tabs {
      border-bottom: 1px solid #cdcdcd;
      padding: 0 1rem 0 2rem;
      display: flex; }
    .ts-finder__filterpanel-tab {
      flex: 1;
      border: solid 1px #cdcdcd;
      font-size: 1.6rem;
      margin-left: 0.5rem;
      margin-bottom: -1px;
      /*so bottom border of tab and tabs collapse*/
      cursor: pointer;
      position: relative;
      padding: 0.4rem 4rem 0.4rem 0.4rem;
      user-select: none;
      background-color: #e6e6e6;
      hyphens: none;
      display: flex;
      align-items: center; }
      .ts-finder__filterpanel-tab:first-child {
        margin-left: 0; }
      .ts-finder__filterpanel-tab.active {
        border-bottom: solid 1px #fff;
        background-color: #fff;
        font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-style: normal; }
    .ts-finder__filterpanel-tab-count {
      position: absolute;
      right: 0.4rem;
      line-height: 2rem;
      top: calc(50% - 1rem); }
    .ts-finder__filterpanel-show-stations {
      border: none;
      margin: 0;
      padding: 0;
      width: auto;
      overflow: visible;
      background: transparent;
      text-align: inherit;
      /* inherit font & color from ancestor */
      color: inherit;
      font: inherit;
      /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
      line-height: normal;
      /* Corrects font smoothing for webkit */
      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;
      /* Corrects inability to style clickable `input` types in iOS */
      -webkit-appearance: none;
      cursor: pointer;
      outline: none;
      /* Remove excess padding and border in Firefox 4+ */
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      background-color: #4e6c30;
      color: #fff;
      text-align: center;
      width: 100%;
      font-size: 2rem;
      padding: 1rem 2rem;
      margin-bottom: 1rem; }
      .ts-finder__filterpanel-show-stations::-moz-focus-inner {
        border: 0;
        padding: 0; }
      .ts-finder__filterpanel-show-stations:hover {
        background-color: #445e2a; }
    .ts-finder__filterpanel .ts-finder__pdf-button {
      margin-bottom: 2rem; }
    .ts-finder__filterpanel-reset {
      border: none;
      margin: 0;
      padding: 0;
      width: auto;
      overflow: visible;
      background: transparent;
      text-align: inherit;
      /* inherit font & color from ancestor */
      color: inherit;
      font: inherit;
      /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
      line-height: normal;
      /* Corrects font smoothing for webkit */
      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;
      /* Corrects inability to style clickable `input` types in iOS */
      -webkit-appearance: none;
      cursor: pointer;
      outline: none;
      /* Remove excess padding and border in Firefox 4+ */
      width: 100%;
      font-size: 1.6rem;
      text-align: center; }
      .ts-finder__filterpanel-reset::-moz-focus-inner {
        border: 0;
        padding: 0; }
      .ts-finder__filterpanel-reset:hover {
        text-decoration: underline; }
  .ts-finder__capability-filter {
    font-size: 1.6rem; }
    .ts-finder__capability-filter-list {
      list-style: none;
      padding: 0;
      margin: 0; }
      .ts-finder__capability-filter-list--level-1 {
        margin-top: 1rem; }
      .ts-finder__capability-filter-list--level-2 {
        display: none; }
    .ts-finder__capability-filter-item {
      position: relative; }
      .ts-finder__capability-filter-item--level-1 {
        font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-style: normal;
        border-bottom: solid 1px #cdcdcd;
        padding: 1rem 2rem; }
        .ts-finder__capability-filter-item--level-1:first-child {
          border-top: none;
          margin-top: 0; }
      .ts-finder__capability-filter-item--level-2 {
        font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-style: normal;
        margin-top: 10px;
        margin-left: 25px; }
    .ts-finder__capability-filter-list-toggle {
      border: none;
      margin: 0;
      padding: 0;
      width: auto;
      overflow: visible;
      background: transparent;
      text-align: inherit;
      /* inherit font & color from ancestor */
      color: inherit;
      font: inherit;
      /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
      line-height: normal;
      /* Corrects font smoothing for webkit */
      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;
      /* Corrects inability to style clickable `input` types in iOS */
      -webkit-appearance: none;
      cursor: pointer;
      outline: none;
      /* Remove excess padding and border in Firefox 4+ */
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 2rem;
      height: 2rem;
      text-align: center; }
      .ts-finder__capability-filter-list-toggle::-moz-focus-inner {
        border: 0;
        padding: 0; }
      .ts-finder__capability-filter-list-toggle-icon {
        transform: rotate(0deg);
        transition: transform ease-in-out 0.2s; }
      .ts-finder__capability-filter-list-toggle.active .ts-finder__capability-filter-list-toggle-icon {
        transform: rotate(180deg);
        transition: transform ease-in-out 0.2s; }
  .ts-finder__canton-filter {
    font-size: 1.6rem; }
    .ts-finder__canton-filter-list {
      list-style: none;
      padding: 0;
      margin: 0; }
    .ts-finder__canton-filter-item {
      position: relative; }
      .ts-finder__canton-filter-item--level-1 {
        font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-style: normal;
        border-bottom: solid 1px #cdcdcd;
        padding: 1rem 2rem; }
        .ts-finder__canton-filter-item--level-1:first-child {
          border-top: none;
          margin-top: 0; }
      .ts-finder__canton-filter-item--level-2 {
        font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
        font-weight: normal;
        font-style: normal;
        margin-top: 10px;
        margin-left: 25px; }
    .ts-finder__canton-filter-list-toggle {
      border: none;
      margin: 0;
      padding: 0;
      width: auto;
      overflow: visible;
      background: transparent;
      text-align: inherit;
      /* inherit font & color from ancestor */
      color: inherit;
      font: inherit;
      /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
      line-height: normal;
      /* Corrects font smoothing for webkit */
      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;
      /* Corrects inability to style clickable `input` types in iOS */
      -webkit-appearance: none;
      cursor: pointer;
      outline: none;
      /* Remove excess padding and border in Firefox 4+ */
      position: relative;
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      margin-top: 2rem;
      margin-bottom: 1rem;
      padding: 0 2rem;
      width: 100%;
      user-select: none; }
      .ts-finder__canton-filter-list-toggle::-moz-focus-inner {
        border: 0;
        padding: 0; }
      .ts-finder__canton-filter-list-toggle-icon {
        text-align: center;
        width: 2rem;
        position: absolute;
        top: -2px;
        right: 1rem;
        transform: rotate(0deg);
        transition: transform ease-in-out 0.2s; }
      .ts-finder__canton-filter-list-toggle.active .ts-finder__canton-filter-list-toggle-icon {
        transform: rotate(180deg);
        transition: transform ease-in-out 0.2s; }
  .ts-finder__checkbox {
    position: relative; }
    .ts-finder__checkbox input {
      position: absolute;
      left: -10000px;
      top: 0;
      width: 1px;
      height: 1px;
      overflow: hidden; }
    .ts-finder__checkbox label {
      position: relative;
      cursor: pointer;
      user-select: none; }
      .ts-finder__checkbox label::before, .ts-finder__checkbox label::after {
        position: absolute; }
    .ts-finder__checkbox--checkbox label {
      margin-left: 25px; }
    .ts-finder__checkbox--checkbox label::before {
      content: "";
      display: block;
      height: 18px;
      width: 18px;
      border: 1px solid;
      top: 3px;
      left: -25px; }
    .ts-finder__checkbox--checkbox label::after {
      content: "";
      display: none; }
    .ts-finder__checkbox--checkbox input:checked + label::after {
      display: block;
      height: 7px;
      width: 11px;
      border-left: 3px solid #4e6c30;
      border-bottom: 3px solid #4e6c30;
      background-color: transparent;
      transform: rotate(-45deg);
      left: -21px;
      top: 7px; }
    .ts-finder__checkbox--checkbox input.semi-checked + label::after {
      display: block;
      height: 12px;
      width: 12px;
      background-color: #4e6c30;
      border-left: none;
      border-bottom: none;
      transform: rotate(0);
      left: -22px;
      top: 6px; }
    .ts-finder__checkbox--checkbox input:disabled + label {
      color: #cdcdcd; }
    .ts-finder__checkbox--checkbox input:disabled + label::before {
      background-color: #e6e6e6; }
    .ts-finder__checkbox--checkbox input:disabled + label::after {
      display: none; }
    .ts-finder__checkbox--switch label {
      display: block; }
    .ts-finder__checkbox--switch label::before {
      content: "";
      display: block;
      height: 22px;
      width: 38px;
      border-radius: 11px;
      background-color: #cdcdcd;
      top: -4px;
      right: 0; }
    .ts-finder__checkbox--switch label::after {
      content: "";
      position: absolute;
      top: -2px;
      right: 18px;
      width: 18px;
      height: 18px;
      background: #fff;
      border-radius: 9px;
      transition: .3s;
      background-color: #fff; }
    .ts-finder__checkbox--switch input:checked + label::before {
      background: #4e6c30; }
    .ts-finder__checkbox--switch input:checked + label::after {
      right: -16px;
      transform: translateX(-100%); }
    .ts-finder__checkbox--switch input:disabled + label {
      color: #cdcdcd; }
    .ts-finder__checkbox--switch input:disabled + label::after {
      background-color: #e6e6e6; }
  .ts-finder__hours-table .today {
    color: #4e6c30;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal; }
  .ts-finder__hours-table td {
    padding: 0 0.3rem 0.3rem; }
    .ts-finder__hours-table td.dayname {
      padding-left: 0;
      padding-right: 2rem; }
  .ts-finder__pdf-button {
    display: block;
    text-decoration: none;
    background-color: #fff;
    border: 2px solid #4e6c30;
    font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    color: #4e6c30;
    text-align: center;
    padding: 1rem; }
    .ts-finder__pdf-button:hover {
      background-color: #f1f1f1; }
    .ts-finder__pdf-button-active {
      display: none; }

/*#endregion*/
/*#region mobile styles*/
@media (max-width: 899px) {
  .ts-finder-body .layout__page-start, .ts-finder-body .breadcrumbs, .ts-finder-body .layout__footer {
    display: none !important; }
  .gm-bundled-control {
    display: none !important; }
  .ts-finder__searchbox {
    top: 0;
    left: 0;
    right: 0;
    width: auto;
    max-height: calc(100vh - 60px);
    max-height: calc(100dvh - 60px); }
    .ts-finder__searchbox-top {
      padding: 1rem; }
    .ts-finder__searchbox-filters {
      margin-bottom: 1rem; }
    .ts-finder__searchbox-filter-reset {
      display: none; }
  .ts-finder__resultlist-pdf {
    margin: 1rem; }
  .ts-finder__resultlist-name {
    font-size: 1.4rem; }
  .ts-finder__resultlist-address {
    font-size: 1.4rem;
    margin-bottom: 0.5rem; }
  .ts-finder__resultlist-item--station, .ts-finder__resultlist-item--station-search {
    padding: 1rem 1rem 0.5rem 1rem; }
  .ts-finder__filterpanel {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: unset;
    width: unset; }
    .ts-finder__filterpanel-reset {
      display: none; }
  .ts-finder__map {
    height: calc(100vh - 60px);
    height: calc(100dvh - 60px); }
  .ts-finder__map .ts-finder__infowindow {
    display: none !important; }
  .ts-finder__layout-mobile-infowindow {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0; }
  .ts-finder__layout-mobile-infowindow .ts-finder__infowindow {
    padding: 2rem;
    width: 100%;
    margin: 0;
    border: 0; }
    .ts-finder__layout-mobile-infowindow .ts-finder__infowindow::before, .ts-finder__layout-mobile-infowindow .ts-finder__infowindow::after {
      display: none; }
    .ts-finder__layout-mobile-infowindow .ts-finder__infowindow-station-name {
      font-size: 1.4rem; }
  .ts-finder__infowindow--with-promobanner .ts-finder__infowindow-station-details-container {
    margin-top: 1rem; }
  .ts-finder__icon {
    width: 25px;
    height: 25px; }
  .ts-finder__details--desktop-only {
    display: none; }
  .ts-finder__details--mobile-only {
    display: block; }
  .ts-finder__details-columns {
    display: block; }
  .ts-finder__details-left {
    margin-bottom: 2rem; }
  .ts-finder__details-map {
    height: 150px; }
  .ts-finder__details-header {
    margin: 0 -1rem;
    padding: 0 1rem 2rem; }
  .ts-finder__details-content {
    margin-top: 0; }
  .ts-finder__details-content-inner {
    padding: 1rem; }
  .ts-finder__details-directions {
    right: 1rem;
    top: 1rem; }
  .ts-finder__details-back {
    top: -140px;
    right: 1rem; }
  .ts-finder__details-logo {
    display: none; }
  .ts-finder__details-address {
    font-size: 1.6rem;
    margin-right: 5.5rem; }
  .ts-finder__details-services {
    font-size: 1.6rem; }
  .ts-finder__details-services-list li {
    width: 1.8rem; }
    .ts-finder__details-services-list li.ts-finder__icons-fastcharging {
      width: 2.7rem; }
  .ts-finder__details-hours {
    font-size: 1.6rem; }
  .ts-finder__details-capabilities {
    font-size: 1.6rem; }
    .ts-finder__details-capabilities--fixed-height li {
      height: unset;
      line-height: unset; }
    .ts-finder__details-capabilities--columns {
      display: block; }
  .ts-finder__details-capabilities-with-icons--cols {
    height: auto;
    column-count: unset;
    column-fill: unset; }
  .ts-finder__details-promo {
    font-size: 1.6rem; }
  .ts-finder__details-servicetitle {
    font-size: 2.4rem;
    margin-bottom: 1.2rem; }
    .ts-finder__details-servicetitle img {
      max-width: 90%; }
  .ts-finder__details-paymentmethods {
    columns: unset; }
  .ts-finder__details-migrolcard-info {
    display: block; }
    .ts-finder__details-migrolcard-info-image {
      margin: 2rem;
      flex: unset; }
    .ts-finder__details-migrolcard-info-text {
      flex: unset; } }

/*#endregion*/
.ts-finder-teaser {
  background: url(/assets/MigrolTankstellensuche/img/bg-ts-finder-teaser-f856b14592.jpg) no-repeat right top;
  background-size: cover;
  min-height: 30rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 100%; }
  @media (min-width: 900px) {
    .ts-finder-teaser {
      min-height: 40rem;
      padding: 3rem; } }
  .ts-finder-teaser__content {
    max-width: 60rem; }
  .ts-finder-teaser__title-1 {
    font-size: 2.2rem;
    color: #a4091e;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    margin-bottom: 2rem; }
  .ts-finder-teaser__title-2 {
    font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    display: block; }
  .ts-finder-teaser__form {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    padding: 0 1.5rem 1.5rem; }
  .ts-finder-teaser__radios {
    white-space: nowrap;
    height: 4rem;
    overflow: hidden;
    margin: 1.5rem 0.5rem 0 0; }
    @media (min-width: 900px) {
      .ts-finder-teaser__radios {
        margin-right: 2rem; } }
  .ts-finder-teaser__radio-label {
    display: inline-block;
    background-color: #e6e6e6;
    color: #000;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    cursor: pointer;
    margin-right: 0.5rem; }
    .ts-finder-teaser__radio-label svg {
      pointer-events: none; }
  .ts-finder-teaser__radio {
    display: none; }
  .ts-finder-teaser__radio:checked + .ts-finder-teaser__radio-label {
    background-color: #a4091e;
    color: #fff; }
  .ts-finder-teaser__inputs {
    display: flex;
    flex: 1;
    white-space: nowrap;
    margin: 1.5rem 0 0;
    min-width: 20rem; }
  .ts-finder-teaser__textbox {
    flex: 1;
    font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    line-height: 3.8rem;
    padding: 0 0.5rem;
    border: solid 1px #b7b7b7;
    outline: none;
    font-size: 1.6rem; }
    @media (max-width: 320px) {
      .ts-finder-teaser__textbox {
        /*iphone 5 special*/
        font-size: 1.3rem;
        flex: none; } }
    .ts-finder-teaser__textbox::placeholder {
      color: #7f7f7f; }
  .ts-finder-teaser__button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    text-align: inherit;
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;
    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;
    /* Remove excess padding and border in Firefox 4+ */
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    line-height: 4rem;
    color: #fff;
    background-color: #4e6c30;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    font-size: 1.6rem; }
    .ts-finder-teaser__button::-moz-focus-inner {
      border: 0;
      padding: 0; }
    .ts-finder-teaser__button:hover {
      background-color: #445e2a; }
  .ts-finder-teaser__button-text {
    display: none; }

.hero-wrapper .ts-finder-teaser {
  background: url(/assets/MigrolTankstellensuche/img/bg-ts-finder-teaser-hero-a3b59fa036.jpg) no-repeat right top;
  background-size: cover; }
  .hero-wrapper .ts-finder-teaser__content {
    width: 100%;
    margin: 0 auto; }
    @media (min-width: 1260px) {
      .hero-wrapper .ts-finder-teaser__content {
        max-width: 1260px; } }
  .hero-wrapper .ts-finder-teaser__form {
    max-width: 60rem; }

@media (min-width: 900px) {
  .col--large .ts-finder-teaser, .col--x-large .ts-finder-teaser {
    padding: 3rem 9rem; }
    .col--large .ts-finder-teaser__title-1, .col--x-large .ts-finder-teaser__title-1 {
      font-size: 2.8rem; }
    .col--large .ts-finder-teaser__button-text, .col--x-large .ts-finder-teaser__button-text {
      display: inline; }
    .col--large .ts-finder-teaser__button-icon, .col--x-large .ts-finder-teaser__button-icon {
      display: none; }
  .col--x-large .ts-finder-teaser__title-1 {
    font-size: 3.4rem; } }

.contentblock--gray .ts-finder-teaser {
  background-image: url(/assets/MigrolTankstellensuche/img/bg-ts-finder-teaser-light-ab1072d55a.jpg); }

.layout__mobile-only .ts-finder-teaser {
  margin-bottom: 3rem;
  margin-left: 1.1rem;
  margin-right: 1.1rem; }

.tickdown {
  hyphens: manual;
  /*#region Running */
  /*#endregion*/
  /*#region Order Step 1*/
  /*#endregion*/
  /*#region Errors*/
  /*#endregion*/ }
  .tickdown a {
    color: #000; }
  .tickdown__timer-block {
    margin-bottom: 4.5rem; }
    @media (min-width: 900px) {
      .tickdown__timer-block {
        display: flex;
        justify-content: space-between; } }
  .tickdown__timer-time {
    width: 18rem;
    padding: 1rem;
    background-color: #a4091e;
    color: #fff;
    margin-top: 0.4rem; }
  @media (min-width: 900px) {
    .tickdown__columns {
      display: flex;
      margin-bottom: 0; } }
  @media (min-width: 900px) {
    .tickdown__columns--center-vertical {
      align-items: center; } }
  .tickdown__column {
    margin-bottom: 2.5rem; }
    .tickdown__column:last-of-type {
      margin-bottom: 0; }
    @media (min-width: 900px) {
      .tickdown__column {
        margin-bottom: 0;
        margin-right: 3.5rem;
        flex: 1; }
        .tickdown__column:last-of-type {
          margin-right: 0; } }
  .tickdown__spacer-bottom {
    margin-bottom: 2.5rem; }
  .tickdown__message-box {
    background-color: #e6e6e6;
    padding: 3rem 1.5rem 2.5rem 1.5rem;
    margin-bottom: 3rem; }
    .tickdown__message-box-title {
      font-size: 1.8rem;
      color: #a4091e;
      margin-bottom: 1.5rem; }
  .tickdown__loading {
    display: flex;
    justify-content: center; }
    .tickdown__loading-icon {
      font-size: 2rem;
      display: inline-block;
      animation: tickdown-loading 800ms linear infinite; }
  .tickdown__price-table-wrapper {
    border: 2px solid #4e6c30;
    background-color: #e6e6e6;
    margin-bottom: 2.5rem; }
  .tickdown__price-table-productinfo {
    padding: 2.8rem 1.5rem 1.8rem; }
    @media (min-width: 900px) {
      .tickdown__price-table-productinfo {
        padding: 3rem 3.8rem;
        display: flex; } }
    .tickdown__price-table-productinfo-icon {
      margin: 0 auto; }
      @media (min-width: 900px) {
        .tickdown__price-table-productinfo-icon {
          margin: 0 3rem 0 0; } }
    .tickdown__price-table-productinfo-name {
      font-size: 2rem;
      line-height: 3rem;
      color: #a4091e;
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      margin-bottom: 0.3rem; }
  .tickdown__price-table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    display: none; }
    @media (min-width: 900px) {
      .tickdown__price-table {
        display: table; } }
    .tickdown__price-table tr {
      border-bottom: 2px solid #cdcdcd; }
      .tickdown__price-table tr:last-child {
        border-bottom: none; }
    .tickdown__price-table th, .tickdown__price-table td {
      padding: 1rem 1.8rem;
      text-align: right;
      font-weight: normal; }
      .tickdown__price-table th.tickdown__price-table-col-text, .tickdown__price-table td.tickdown__price-table-col-text {
        text-align: left; }
    .tickdown__price-table-header-title {
      font-size: 2rem;
      line-height: 2rem;
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal; }
    .tickdown__price-table-header-subtitle {
      font-size: 1.4rem;
      line-height: 2rem; }
    .tickdown__price-table-row--important {
      font-size: 2rem;
      line-height: 2rem;
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal; }
    .tickdown__price-table-row--red {
      color: #a4091e; }
    .tickdown__price-table-row--green {
      color: #4e6c30; }
  @media (min-width: 900px) {
    .tickdown__price-table-mobile {
      display: none; } }
  .tickdown__price-table-mobile-tabs {
    border-collapse: collapse;
    display: flex; }
  .tickdown__price-table-mobile-tab {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    text-align: inherit;
    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;
    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;
    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
    cursor: pointer;
    outline: none;
    /* Remove excess padding and border in Firefox 4+ */
    border-top: solid 1px #b7b7b7;
    border-right: solid 1px #b7b7b7;
    border-bottom: solid 1px #b7b7b7;
    background-color: #cdcdcd;
    text-decoration: none;
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #000;
    flex: 1;
    text-align: center;
    padding: 1rem 0 0.75rem; }
    .tickdown__price-table-mobile-tab::-moz-focus-inner {
      border: 0;
      padding: 0; }
    .tickdown__price-table-mobile-tab:last-of-type {
      border-right: none; }
    .tickdown__price-table-mobile-tab--active {
      background-color: #fff;
      color: #a4091e; }
  .tickdown__price-table-mobile-tab-content {
    display: none; }
    .tickdown__price-table-mobile-tab-content--active {
      display: block; }
  .tickdown__price-table-mobile-row {
    display: flex;
    justify-content: space-between;
    border-bottom: solid 2px #b7b7b7;
    font-size: 1.6rem;
    color: #000;
    padding: 1.4rem 1.1rem; }
    .tickdown__price-table-mobile-row:last-of-type {
      border-bottom: none; }
    .tickdown__price-table-mobile-row--important {
      background-color: #fff;
      font-size: 1.8rem;
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal; }
    .tickdown__price-table-mobile-row--red {
      color: #a4091e; }
    .tickdown__price-table-mobile-row--green {
      color: #4e6c30; }
    .tickdown__price-table-mobile-row--centered {
      justify-content: center; }
  .tickdown__price-table-mobile-price {
    text-align: right; }
  .tickdown__price-table-mobile-smalltext {
    font-family: 'DIN W05 Regular', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.2rem;
    display: block; }
  .tickdown__starting-price-info {
    margin-top: 2.5rem;
    font-size: 1.4rem; }
  .tickdown__delivery-period-info {
    background-color: #e6e6e6;
    padding: 3rem 1rem 2.5rem 1rem;
    margin-top: 3rem; }
  .tickdown__delivery-period-dates {
    font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    color: #a4091e;
    margin-bottom: 2.5rem; }
  .tickdown__order-button-step1 {
    display: none;
    text-align: right; }
    @media (min-width: 900px) {
      .tickdown__order-button-step1 {
        display: block; } }
  .tickdown__price-table-productinfo-price {
    font-size: 2rem; }
    .tickdown__price-table-productinfo-price-bold {
      font-family: 'DIN W05 Bold', Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal; }
    .tickdown__price-table-productinfo-price-small {
      font-size: 1.4rem;
      line-height: 2rem; }
  .tickdown__error-message-block {
    padding: 2rem;
    border: 2px solid #a4091e;
    color: #a4091e;
    background-color: #fff;
    margin-bottom: 3rem;
    margin-top: 3rem; }
    @media (min-width: 900px) {
      .tickdown__error-message-block {
        width: 50%;
        margin-left: auto;
        margin-right: auto; } }
    .tickdown__error-message-block li {
      list-style: none; }

@keyframes tickdown-loading {
  100% {
    transform: rotate(360deg); } }
