﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

//---------------------------------------------------------------------------------------------------
// This file is included in the Sitecore RTE
//---------------------------------------------------------------------------------------------------

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
}

h2 {
    @include font-bold;
    margin-top: 1.75em;
    margin-bottom: 0.5em;
    font-size: 1.1em;

    &.red {
        color: $migrol-red;
    }

    &:first-child {
        margin-top: 0;
    }

    & + h3, & + h4 {
        margin-top: 0;
    }
}

h3 {
    @include font-bold;
    margin-top: 1.75em;
    margin-bottom: 0.5em;
    font-size: 1.1em;

    &.red {
        color: $migrol-red;
    }

    &:first-child {
        margin-top: 0;
    }

    & + h4 {
        margin-top: 0;
    }
}

h4, h5, h6 {
    @include font-bold;
    margin-bottom: 0.5em;

    &.red {
        color: $migrol-red;
    }
}

h4 {
    font-size: 1.0em;
    margin-top: 1.75em;

    &:first-child {
        margin-top: 0;
    }
}


strong, b, em {
    @include font-bold;
}

p {
    margin-bottom: 1em;

    &:last-child {
        margin-bottom: 0;
    }
}

p.small {
    font-size: 0.8em;
    line-height: 1.3;
}

.red {
    color: $migrol-red;
}

span.red {
    color: $migrol-red;
}

ul {
    margin: 0 0 1em 2.6rem;
    padding: 0;
    list-style: none;

    ul {
        margin-top: 0;
        margin-bottom: 0;
    }

    li {
        margin: 0;
        position: relative;

        &::before {
            @include font-bold;
            color: $migrol-red;
            content: '–';
            position: absolute;
            left: -2.6rem;
        }
    }

    &.small {
        font-size: 0.8em;
        line-height: 1.3;
    }

    &.migrol-rt-content__download-list {
        margin: 0 0 1em 2.6rem;
        padding: 0;
        list-style: none;

        li {
            margin: 0;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: -2.6rem;
                display: block;
                color: $migrol-red;
                background: transparent url(/assets/MigrolContent/img/icon-download.svg) center center;
                background-size: cover;
                width: 1.7rem;
                height: 1.7rem;
                margin-right: 0.6rem;

                @include for-tablet-landscape-up {
                    top: 0.5rem;
                }
            }
        }
    }
}

img {
    max-width: 100%;
    height: auto !important;
}

ol {
    margin: 0 0 1em 2.6rem;
    padding: 0;
    list-style: none;
    counter-reset: item;

    &.small {
        font-size: 0.8em;
        line-height: 1.3;
    }

    ol {
        margin-top: 0;
        margin-bottom: 0;
    }

    li {
        margin: 0;
        position: relative;
        counter-increment: item;

        &::before {
            color: $migrol-red;
            content: counter(item) ".";
            left: -2.6rem;
            position: absolute;
        }
    }
}

p + ul, p + ol {
    margin-top: -1.5rem !important;
}

a {
    text-decoration: underline;
    color: #000;

    &:hover {
        color: $migrol-red;
    }

    &.call-to-action-button {
        position: relative;
        display: inline-block;
        left: 50%;
        transform: translateX(-50%);
        padding: 0.5rem 1rem;
        max-width: 100%;
        color: #FFF;
        background-color: $migrol-green;
        font-size: 0.8em;
        text-align: center;
        @include font-bold;
        text-decoration: none;

        @include for-tablet-portrait-up {
            padding: 0.5rem 8rem;
        }

        &:hover {
            color: #FFF;
            background-color: $migrol-green-dark;
        }
    }

    &.zoom-image {
        cursor: zoom-in;
    }
}

div.migrol-rt-content__download {
    position: relative;

    a {
        color: #000;
        text-decoration: underline;
        display: inline-block;
        margin-left: 2.3rem;

        &:hover {
            color: $migrol-red;
        }
    }

    div.migrol-rt-content__download-icon {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        color: $migrol-red;
        background: transparent url(/assets/MigrolContent/img/icon-download.svg) center center;
        background-size: cover;
        width: 1.7rem;
        height: 1.7rem;
        margin-right: 0.6rem;

        @include for-tablet-landscape-up {
            top: 0.5rem;
        }
    }

    div.migrol-rt-content__download-size {
        font-size: 1.2rem;
        line-height: 1.2rem;
        display: block;
        margin-left: 2.3rem;
    }
}

div.migrol-rt-content__hotline {
    display: inline-flex;
    background-color: $migrol-red;
    color: #fff;
    @include font-bold;
    align-items: center;
    padding: 1rem 1rem 0.5rem 1rem;
    line-height: 1.15;

    &-icon {
        font-size: 3rem;
        margin-right: 1rem;
        flex: none;
    }

    &-details {
    }

    &-title {
        font-size: 1.6rem;
        white-space: nowrap;
    }

    &-number {
        font-size: 2.0rem;
        white-space: nowrap;
    }
}

blockquote {
    @include font-normal;
    font-size: 1.2em;
    line-height: 1.1em;
    color: $migrol-green;
    margin: 1em 0;

    @include for-tablet-landscape-up {
        font-size: 1.3em;
        line-height: 1.2em;
    }

    @include for-desktop-up {
        padding: 0 2.5em;
    }

    footer {
        text-align: right;
        font-size: 0.9em;

        cite {
            font-style: normal;
        }
    }
}

td {
    vertical-align: top;
}
