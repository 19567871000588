﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.breadcrumbs {
    overflow-x: auto;
    width: 100vw;

    @include for-tablet-landscape-up {
        overflow: hidden;
        width: auto;
        margin-top: 2.5rem;
    }

    &__list {
        font-size: 1.2rem;
        list-style: none;
        margin: 0;
        padding: 0;
        white-space: nowrap;

        @include for-tablet-landscape-up {
            padding: 0 $content-padding-desktop;

            @include for-desktop-up {
                max-width: $desktop-min-width;
                margin: 0 auto;
            }
        }
    }

    &__item {
        display: inline-block;

        &:first-child {
            padding-left: 2.5rem;

            @include for-tablet-landscape-up {
                padding-left: 0;
            }
        }

        &:last-child {
            padding-right: 2.5rem;

            @include for-tablet-landscape-up {
                padding-right: 0;
            }
        }

        .svg-inline--fa {
            color: $migrol-red;
            font-size: 1rem;
            margin: 0 0.5rem;
        }
    }

    &__link {
        color: #000;
        text-decoration: none;
        display: inline-block;
        line-height: 5rem;
        height: 5rem;

        @include for-tablet-landscape-up {
            line-height: normal;
            height: auto;
        }
    }

    &__item:last-child &__link {
        @include font-bold;
    }
}
