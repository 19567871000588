﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.contentblock {
    margin-bottom: 3.75rem;

    @include for-tablet-landscape-up {
        margin-bottom: 7.5rem;

        &--small-margin-bottom {
            margin-bottom: 3.75rem;
        }
    }

    &--gray {
        background-color: $grey-230;
        padding: 3.75rem 0;

        @include for-tablet-landscape-up {
            padding: 6rem 0 7.5rem;
        }
    }

    &--no-margin-and-padding {
        margin: 0;
        padding: 0;
    }

    &__content {
        padding: 0 $content-padding-mobile;
        position: relative;

        @include for-tablet-landscape-up {
            padding: 0 $content-padding-desktop;

            @include for-desktop-up {
                max-width: $desktop-min-width;
                margin: 0 auto;
            }
        }

        &--narrow {
            padding: 0 2.6rem;

            @include for-tablet-landscape-up {
                padding: 0 $content-padding-desktop + 20;
            }
        }

        & > *:last-child {
            margin-bottom: 0; //this removes the bottom-margin of the last element which otherwise would have the element-margin at the bottom
        }
    }
}
