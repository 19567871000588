﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.top-news {
    background-color: $grey-241;
    padding: 3rem 2.6rem;

    @include for-tablet-landscape-up {
        padding: 4rem 0;
    }

    &__content {
        position: relative;
        background-color: #fff;
        padding: 0.7rem 1rem;

        @include for-tablet-landscape-up {
            max-width: 80rem;
            margin: 0 auto;
            padding: 2rem 2rem;
        }
    }

    &__title-1 {
        @include font-bold;
        font-size: 2rem;
        color: $migrol-red;
        margin-bottom: 0.5rem;
        margin-right: 2rem;

        @include for-tablet-landscape-up {
            font-size: 2.2rem;
            margin-bottom: 1rem;
        }
    }

    &__title-2 {
        display: block;
        @include font-normal;
    }

    &__copy {
        font-size: 1.6rem;
        line-height: 2.2rem;
        margin-bottom: 0.5rem;

        @include for-tablet-landscape-up {
            margin-bottom: 1rem;
        }
    }

    &__link {
        @include font-bold;
        font-size: 1.6rem;
        color: #000;
        text-decoration: none;
    }

    &__close-button {
        @include button-reset;
        position: absolute;
        top: 0.7rem;
        right: 1rem;
        font-size: 1.6rem;

        @include for-tablet-landscape-up {
            top: 2rem;
            right: 2rem;
        }
    }
}
