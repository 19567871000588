﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.timeline {
    height: 100%;
    min-height: 30rem;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;

    @include for-tablet-landscape-up {
        min-height: 40rem;
        flex-direction: row;
    }

    &__content {
        position: relative;
        padding: 4rem 5rem;
        flex: 1;
        background-color: $grey-241;
        font-size: 1.6rem;
        display: flex;
        flex-direction: column;

        @include for-tablet-landscape-up {
            flex: 0 0 50%;
            font-size: 1.8rem;
        }

        &-slide {
            display: none;
            margin-bottom: auto;
        }
    }

    &__title {
        @include font-bold;
        font-size: 3.5rem;
        color: $migrol-red;
        margin-bottom: 2rem;

        @include for-tablet-landscape-up {
            font-size: 4.0rem;
        }
    }

    &__subtitle {
        @include font-bold;
        font-size: 1.8rem;
        color: $grey-127;
        margin-bottom: 2rem;

        @include for-tablet-landscape-up {
            font-size: 2rem;
        }
    }

    &__text {        
        color: $grey-127;
    }


    &__image-wrapper {
        position: relative;
        overflow: hidden;
        flex: 1;
        min-height: 30rem;

        @include for-tablet-landscape-up {
            flex: 0 0 50%;
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center center;
        transform: scale(1.0, 1.0);
        transition: transform 0.5s ease-out;
        display: none;
    }

    &:hover &__image {
        transition: transform 0.5s ease-out;
        transform: scale(1.03, 1.03);
    }

    &__controls {
        display: flex;
        margin-top: 6rem;
        flex-direction: column;

        @include for-tablet-landscape-up {
            flex-direction: row;
        }

        &-slider {
            flex: 1;
            margin-right: 3rem;

            &-years {
                display: flex;
                justify-content: space-between;
                color: $grey-183;
                margin-top: 1.3rem;
            }

            &-firstyear {
                margin-left: -1.8rem;
            }

            &-lastyear {
                margin-right: -1.8rem;
            }

            &-bar {
                background-color: $grey-183;
                height: 0.3rem;
                width: 100%;
                border-radius: 0.3rem;
                position: relative;

                &-inner {
                    background-color: $migrol-red;
                    height: 100%;
                    width: 0;
                    border-radius: 0.3rem;
                }

                &-position {
                    position: absolute;
                    left: 0%;
                    top: -0.5rem;
                    width: 1.3rem;
                    height: 1.3rem;
                    margin-left: -0.65rem;
                    background-color: $migrol-red;
                    border-radius: 50%;
                    box-shadow: 0 0 0 0.6rem rgba(164,9,30,0.2);

                    &-currentyear {
                        position: absolute;
                        top: -3.5rem;
                        left: -100%;
                        @include font-bold;
                        color: $migrol-red;
                        font-size: 2rem;
                    }
                }
            }
        }

        &-buttons {
            padding-top: 2.5rem;
        }

        &-button {
            color: $migrol-red;
            cursor: pointer;
            background: none;
            border: none;
            font-size: 1.8rem;

            &--disabled {
                cursor: default;
                opacity: 0.4;
            }
        }
    }
}
