﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

$image-bullets-gutter: 1.2rem;

.image-bullets {
    @include element-margin;
    position: relative;
    display: flex;
    justify-content: space-between;
    @include element-margin;
    //extra margin, when there are paging-dots below the bullets
    &.slick-dotted {
        margin-bottom: (4rem + $element-margin-bottom-mobile);

        @include for-tablet-landscape-up {
            margin-bottom: (4rem + $element-margin-bottom-desktop);
        }
    }

    .slick-slide {
        height: 100%;
    }

    &--for-3 {
        @include for-tablet-landscape-up {
            width: 75%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &--for-2 {
        @include for-tablet-landscape-up {
            width: 50%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &--for-1 {
        @include for-tablet-landscape-up {
            width: 25%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__item {
        margin: 0;
        padding: 0 ($image-bullets-gutter / 2);
        height: 100%;
        position: relative;
        visibility: hidden;
    }

    &.slick-initialized .image-bullets__item {
        visibility: visible !important;
    }

    &__content {
        background-color: #fff;
        margin: 2rem 2rem 0 2rem;
        height: 100%;

        &--centered {
            text-align: center;
        }
    }

    &__title {
        @include font-bold;
        font-size: 2rem;
        margin-bottom: 1.8rem;
    }

    &__copy.migrol-rt-content {
        font-size: 1.8rem;
        line-height: 2.2rem;
        margin-bottom: 0;
    }

    &__button {
        //height is set via javascript event
        position: absolute;
        color: $migrol-red;
        font-weight: bold;
        text-align: center;
        font-size: 24px;
        background: none;
        border: none;
        z-index: 2;
        outline: none;
        padding: 0 ($image-bullets-gutter / 2);

        &--previous {
            top: 0;
            left: (-1 * $content-padding-mobile);

            @include for-tablet-landscape-up {
                left: (-1 * $content-padding-desktop);
            }
        }

        &--next {
            top: 0;
            right: (-1 * $content-padding-mobile);

            @include for-tablet-landscape-up {
                right: (-1 * $content-padding-desktop);
            }
        }

        &.slick-disabled {
            display: none !important;
        }
    }

    &__pager {
        list-style: none;
        display: block;
        text-align: center;
        padding: 0;
        margin: 0;
        position: absolute;
        bottom: -60px;
        width: 100%;

        li {
            position: relative;
            display: inline-block;
            height: 30px;
            width: 20px;
            margin: 0 5px;
            padding: 0;
            cursor: pointer;

            button {
                border: 0;
                background: #ccc;
                display: block;
                height: 10px;
                width: 10px;
                border-radius: 50%;
                outline: none;
                line-height: 0px;
                font-size: 0px;
                color: transparent;
                padding: 5px;
                cursor: pointer;

                @media(hover:hover) {
                    &:hover, &:focus {
                        outline: none;
                        background-color: #999 !important;
                    }
                }
            }

            &.slick-active button {
                background-color: #999 !important;
            }
        }
    }
}

//special styles, when in a gray contentblock
.contentblock--gray {
    .image-bullets {
        &__content {
            padding: 1.5rem 1rem;
            margin-left: 0;
            margin-right: 0;
        }

        &__pager li button {
            background: $grey-205;
        }
    }
}

.image-bullets--round {
    .image-bullets__image {
        width: 70%;
        padding-top: 70%;
        margin: 0 auto;
        border-radius: 50%;
        background-size: cover;
        background-position: center center;
    }

    .image-bullets__image-load-helper {
        width: 1000px;
        height: 1px;
        margin: 0 auto;
        object-fit: cover;
        display: block;
    }
}

.image-bullets--square {
    .image-bullets__image {
        width: 100%;
        padding-top: 70%;
        background-size: cover;
        background-position: center center;
    }

    .image-bullets__image-load-helper {
        width: 1000px;
        height: 1px;
        object-fit: cover;
        display: block;
    }
}

.image-bullets--text-red {
    .image-bullets__title {
        color: $migrol-red;
    }
}

.image-bullets--text-black {
}
