﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.oil-finder {

    &__filters {
        background-color: $grey-230;
        padding: 3rem;
        margin-bottom: 3rem;


        @include for-tablet-portrait-up {
            display: flex;
            column-gap: 3rem;
        }

        .migrol-form__input-label {
            @include font-bold;
            font-size: 1.6rem;

            @include for-tablet-landscape-up {
                font-size: 2rem;
            }
        }

        .v-select {
            background-color: #fff;
        }
    }

    &__filter {
        @include for-tablet-portrait-down {
            margin-bottom: 1rem;
        }

        @include for-tablet-portrait-up {
            flex: 1;
        }
    }

    &__no-results {
        background-color: $grey-230;
        width: 100%;
        font-size: 1.6rem;
        line-height: 2.2rem;
        padding: 3rem;
        margin: 3rem 0;
    }

    &__oil {
        background-color: $grey-230;
        width: 100%;
        font-size: 1.6rem;
        line-height: 2.2rem;
        padding: 3rem;
        margin: 3rem 0;

        @include for-tablet-portrait-up {
            font-size: 2rem;
            line-height: 3rem;
        }

        &-columns {
            display: grid;
            grid-template-columns: 100%;
            grid-template-rows: auto auto;
            grid-gap: 3rem;

            @include for-tablet-portrait-up {
                display: grid;
                grid-gap: 3rem;
                grid-template-areas: "left right right";
                grid-template-rows: auto;
                grid-template-columns: repeat(3, calc((100% - 6rem) / 3));

                & > :first-child {
                    grid-area: left;
                }

                & > :last-child {
                    grid-area: right;
                }
            }
        }

        &-image {
            max-width: 100%;
            width: 100%;
            height: auto;
        }

        &-title {
            color: $migrol-red;
            @include font-bold;
            font-size: 1.6rem;
            margin-bottom: 2rem;

            @include for-tablet-portrait-up {
                font-size: 2rem;
                margin-bottom: 3rem;
            }
        }

        &-subtitle {
            @include font-bold;
        }

        &-text {
            margin-bottom: 2rem;

            ul {
                margin-left: 25px;
            }
        }
    }
}
