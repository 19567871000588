﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.video {
    position: relative;
    @include element-margin;

    &__wrapper {
        position: relative;
        padding-bottom: 56.25%; /*16:9*/
        height: 0;
        overflow: hidden;
    }

    &__iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__placeholder {
        display: block;
        font-size: 4rem;
        color: #FFF;
        text-shadow: 0 0 1rem #000;
        transform: rotate(-5deg);
        text-decoration: none;
        position: absolute;
        top: 33%;
        left: 0;
        right: 0;
        text-align: center;
    }
}
