﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.marktnews {
    position: relative;

    &__title-1 {
        font-size: 2.8rem;
        @include font-bold;
        margin-bottom: 1rem;
        color: $migrol-red;

        @include for-tablet-landscape-up {
            font-size: 3.8rem;
        }
    }

    &__title-2 {
        @include font-normal;
    }

    &__date {
        display: block;
        font-size: 1.6rem;
        margin-bottom: 3rem;

        @include for-tablet-landscape-up {
            margin-bottom: 5rem;
        }
    }

    &__lead {
        font-size: 2rem;
        line-height: 2.8rem;
        margin-bottom: 3rem;

        @include for-tablet-landscape-up {
            font-size: 2.4rem;
            line-height: 3.4rem;
            margin-bottom: 5rem;
        }
    }

    &__text {
        font-size: 1.6rem;
        line-height: 2.2rem;
        margin-bottom: 3rem;

        @include for-tablet-landscape-up {
            font-size: 2rem;
            line-height: 3rem;
            margin-bottom: 5rem;
        }
    }

    &__aside {
        display: block;
        font-size: 1.6rem;
        line-height: 2.2rem;
        margin-bottom: 3rem;

        @include for-tablet-landscape-up {
            font-size: 2rem;
            line-height: 3rem;
            margin-bottom: 5rem;
        }

        a {
            color: #000;
            @include font-bold;
            text-decoration: underline;

            &:hover {
                color: $migrol-red;
            }
        }
    }

    &__footertext {
        display: block;
        font-size: 1.6rem;
        line-height: 2.2rem;
        margin-bottom: 2rem;

        @include for-tablet-landscape-up {
            font-size: 2rem;
            line-height: 3rem;
            margin-bottom: 4rem;
        }

        &--small {
            font-size: 1.4rem;
            line-height: normal;
        }
    }
}
