﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

//---------------------------------------------------------------------------------------------------
// This file is used separately in the Sitecore RTE (magically creates table styles in a dropdown)
//---------------------------------------------------------------------------------------------------

@mixin tablestyles( $classname, $cell-bg, $cell-color, $header-bg, $header-color, $border-color ) {

    .#{$classname} {
        width: 100% !important;
        height: auto !important;
        border: none;
        color: $cell-color;
        border-collapse: collapse;
        @include font-normal;
        font-size: 1.6rem;
        vertical-align: top;

        @include for-tablet-landscape-up {
            font-size: 1.8rem;
        }

        td, th {
            border: solid 1px $border-color;
            padding: 0.5rem 1.3rem;
            font-weight: normal;
        }

        tr.#{$classname}HeaderRow {
            @include font-bold;
            font-size: 1.6rem;
            color: $header-color;
            background-color: $header-bg;
            vertical-align: middle;

            @include for-tablet-landscape-up {
                font-size: 2rem;
            }
        }

        td.#{$classname}HeaderFirstCol {
            @include font-bold;
            font-size: 1.6rem;
            color: $header-color;
            background-color: $header-bg;
            vertical-align: middle;

            @include for-tablet-landscape-up {
                font-size: 2rem;
            }
        }

        td.#{$classname}HeaderLastCol {
            @include font-bold;
            font-size: 1.6rem;
            color: $header-color;
            background-color: $header-bg;
            vertical-align: middle;

            @include for-tablet-landscape-up {
                font-size: 2rem;
            }
        }

        td.#{$classname}HeaderOddCol {
        }

        td.#{$classname}HeaderEvenCol {
        }

        tr.#{$classname}OddRow {
            vertical-align: top;
        }

        tr.#{$classname}EvenRow {
            vertical-align: top;
        }

        td.#{$classname}FirstCol {
            @include font-bold;
            font-size: 1.6rem;
            color: $header-color;
            background-color: $header-bg;
            vertical-align: middle;

            @include for-tablet-landscape-up {
                font-size: 2rem;
            }
        }

        td.#{$classname}LastCol {
            @include font-bold;
            font-size: 1.6rem;
            color: $header-color;
            background-color: $header-bg;
            vertical-align: middle;

            @include for-tablet-landscape-up {
                font-size: 2rem;
            }
        }

        td.#{$classname}OddCol {
            background-color: $cell-bg;
        }

        td.#{$classname}EvenCol {
            background-color: $cell-bg;
        }

        tr.#{$classname}FooterRow {
            @include font-bold;
            font-size: 1.6rem;
            color: $header-color;
            background-color: $header-bg;
            vertical-align: middle;

            @include for-tablet-landscape-up {
                font-size: 2rem;
            }
        }

        td.#{$classname}FooterFirstCol {
            @include font-bold;
            font-size: 1.6rem;
            color: $header-color;
            background-color: $header-bg;
            vertical-align: middle;
        }

        td.#{$classname}FooterLastCol {
            @include font-bold;
            font-size: 1.6rem;
            color: $header-color;
            background-color: $header-bg;
            vertical-align: middle;

            @include for-tablet-landscape-up {
                font-size: 2rem;
            }
        }

        td.#{$classname}FooterOddCol {
        }

        td.#{$classname}FooterEvenCol {
        }
    }
}

//style-names must end with "...Table" or else the greatest of all editors won't recognize the styles properly
@include tablestyles("neutralTable", $grey-241, #000, $grey-241, #000, #fff);
@include tablestyles("redTable", $grey-241, #000, $migrol-red, #fff, #fff);
@include tablestyles("grayTable", $grey-241, #000, $grey-127, #fff, #fff);