﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.price-index {

    &__tabs {
        border-collapse: collapse;
        display: flex;
    }

    &__tab {
        @include button-reset;
        border: solid 1px $grey-183;
        background-color: $grey-205;
        text-decoration: none;
        @include font-bold;
        font-size: 1.6rem;
        color: #000;
        padding: 0 1rem;
        line-height: 3.3rem;
        text-align: center;
        flex: 1;
    }

    &__tab--active {
        background-color: transparent;
        border-bottom-color: transparent;
        color: $migrol-red;
    }

    &__tab-content {
        display: none;
        padding: 1rem;
        border-left: solid 1px $grey-183;
        border-right: solid 1px $grey-183;
        border-bottom: solid 1px $grey-183;
    }

    &__tab-content--active {
        display: block;
    }

    &__chart {
        width: 100%;
        height: 40rem;
    }
}

@include for-tablet-landscape-up {
    .price-index {
        &__chart {
            height: 60rem;
        }

        &__tab {
            display: inline-block;            
            padding: 0 3rem;
            flex: none;
        }

        &__tab-filler {
            flex: 1;
            border-bottom: solid 1px $grey-183;
        }
    }
}
