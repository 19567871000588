﻿@charset "utf-8";
@import "../../../../../../Project/MigrolWebsite/code/assets/MigrolWebsite/css/_globals";

.slider {
    //removed max-width on request of migrol
    //max-width: 1440px;
    //margin: 0 auto;
    &__item {
        position: relative;
    }

    &__image {
        position: relative;
        background-position: center center;
        background-repeat: no-repeat;
        height: 50rem;

        @include for-tablet-landscape-up {            
            padding-top: 0;
        }
    }

    &__pager {
        list-style: none;
        display: block;
        text-align: center;
        padding: 0;
        margin: 2rem 0 0;

        @include for-tablet-landscape-up {
            position: absolute;
            bottom: 2rem;
            width: 100%;
        }

        li {
            position: relative;
            display: inline-block;
            margin: 0 0.5rem;
            padding: 0;
            cursor: pointer;

            @include for-tablet-landscape-up {
                margin: 0 0.8rem;
            }

            button {
                border: 0;
                background: $grey-241;
                display: block;
                height: 1.1rem;
                width: 1.1rem;
                border-radius: 50%;
                outline: none;
                line-height: 0;
                font-size: 0;
                color: transparent;
                padding: 5px;
                cursor: pointer;

                @include for-tablet-landscape-up {
                    height: 1.4rem;
                    width: 1.4rem;
                }

                @media(hover:hover) {
                    &:hover, &:focus {
                        outline: none;
                        background-color: $grey-183;

                        @include for-tablet-landscape-up {
                            background-color: #fff;
                        }
                    }
                }
            }

            &.slick-active button {
                background-color: $grey-183;

                @include for-tablet-landscape-up {
                    background-color: #fff;
                }
            }
        }
    }
}

//this is to hide jumping/flickering before the slider is initialized
.slider__item:nth-child(n+2) {
    display: none;
}

.slick-initialized .slider__item:nth-child(n+2) {
    display: block;
}