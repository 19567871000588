﻿@font-face {
    font-family: "Helvetica Neue Condensed";
    font-weight: 700;
    font-display: fallback;
    src: url('https://cdn.migros.ch/ch.migros/static/fonts/helveticaneue-condensed-bold/helveticaneue-condensed-bold.eot?#iefix');
    src: url('https://cdn.migros.ch/ch.migros/static/fonts/helveticaneue-condensed-bold/helveticaneue-condensed-bold.eot?#iefix') format("embedded-opentype"), url('https://cdn.migros.ch/ch.migros/static/fonts/helveticaneue-condensed-bold/helveticaneue-condensed-bold.woff') format("woff"), url('https://cdn.migros.ch/ch.migros/static/fonts/helveticaneue-condensed-bold/helveticaneue-condensed-bold.ttf') format("truetype"), url('https://cdn.migros.ch/ch.migros/static/fonts/helveticaneue-condensed-bold/helveticaneue-condensed-bold.svg#9d3bb5f2-6932-419e-a56f-382131ffd4b8') format("svg");
}

/* Basic styles */
.a-service-login {
    text-decoration: none !important;
    box-sizing: border-box;
}

/* service login atom */
.a-service-login {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    color: #333 !important;
    display: inline-block;
    font-size: 12px;
    height: 22px;
    line-height: 22px;
    padding: 0 !important;
    padding-left: 35px !important;
    position: relative;
    text-align: left;
    font-family: "Helvetica Neue Condensed", Helvetica, Arial !important;
    font-weight: 600;
    text-transform: uppercase;
}

.a-service-login:before {
    background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEyLjQgMTRWNS45TDguOSAxNEg3TDMuNiA1LjlWMTRIMFYwaDQuOGwzLjMgNy45TDExLjUgMEgxNnYxNGgtMy42WiIgZmlsbD0iI0Y2MCIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+) no-repeat 8px 2px;
    background-size: 11px auto;
    border-right: 1px solid #ccc;
    content: '';
    height: 12px;
    left: 0;
    position: absolute;
    top: 4px;
    width: 27px;
}

.a-service-login:hover, .a-service-login:active {
    background-color: #f7f7f7;
    color: #333;
}

.a-service-login:focus {
    color: #333;
}

.a-service-login--big {
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    padding-left: 48px !important;
}

.a-service-login--big:before {
    background: transparent url(//www.migros.ch/.resources/m5-relaunch-core-theme/1.57.1-rb5a4f_3401/img/svg/icons/general/icon-m.svg) no-repeat 12px 3px;
    background-size: 15px auto;
    height: 22px;
    width: 38px;
    top: 10px;
}


.a-service-login--dark {
    border-color: transparent;
}

.a-service-login--disabled {
    color: #adadad;
}

.a-service-login--disabled.a-service-login:hover, .a-service-login--disabled.a-service-login:active {
    background-color: #fff;
    color: #adadad;
}

.a-service-login--lang-de {
    width: 102px;
}

.a-service-login--lang-de.a-service-login--big {
    width: 145px;
}

.a-service-login--lang-fr {
    width: 114px; /* 114px*/
}

.a-service-login--lang-fr.a-service-login--big {
    width: 148px; /* 169px; */
}

.a-service-login--lang-it {
    width: 80px;
}

.a-service-login--lang-it.a-service-login--big {
    width: 119px;
}

.mlogin__user {    
    padding-right: 10px;
    //display: none; //disabled for now
}
